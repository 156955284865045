import React, { memo, useContext, useEffect, useRef, useState } from "react";

import "./Registration.scss";

import Config from "configs";
import Swarm from "connection/Swarm";
import CountryCodes from "constants/countryCodes";
import GeoData from "constants/geoData";
import LanguageCodes from "constants/languageCodes";
import GetLocalIp from "helpers/localIp";
import ReCaptcha from "helpers/ReCaptcha";
import { removeNullValuesFromObject } from "helpers/utils";
import useForm from "hooks/useForm";
import contentManagerEventDispatcher from "dev/components/contentManager/contentManagerDispatcher";
import { TranslationContext } from "providers/TranslationProvider";
import { PartnerContextState } from "providers/PartnerProvider";
import { PreferencesStateContext } from "providers/PreferencesProvider";
import { UserContextDispatch } from "providers/UserProvider";
import { ContentManagerTargetType } from "dev/components/contentManager";
import InputTypeCheckbox from "dev/components/formControls/InputTypeCheckbox/InputTypeCheckbox";
import InputTypeDatePicker, { DatePickerType } from "dev/components/formControls/InputTypeDatePicker/InputTypeDatePicker";
import InputTypeEmail from "dev/components/formControls/InputTypeEmail/InputTypeEmail";
import InputTypeNumber from "dev/components/formControls/InputTypeNumber/InputTypeNumber";
import InputTypePassword from "dev/components/formControls/InputTypePassword/InputTypePassword";
import InputTypeTelephone from "dev/components/formControls/InputTypeTelephone/InputTypeTelephone";
import InputTypeCountry from "dev/components/formControls/InputTypeSelect/InputTypeCountry";
import InputTypeText from "dev/components/formControls/InputTypeText/InputTypeText";
import InputTypeSelect from "dev/components/formControls/InputTypeSelect/InputTypeSelect";
import StaticTypeText from "dev/components/formControls/staticTypeText/StaticTypeText";
import Button from "dev/components/button/Button";
import StepsIndicator from "dev/components/stepsIndicator/StepsIndicator";

interface IPropTypes {
  onFailure?: VoidFunction;
  onSucceed?: VoidFunction;
  contentManagerTargetType?: ContentManagerTargetType;
}

enum RecaptchaCodes {
  DISABLED,
  VERSION_2 = 2,
  VERSION_3
}

const getRegistrationData = async (formData: Dictionary<any>) => {
  const registrationData: Dictionary<any> = {};
  const ipAddress = await GetLocalIp();
  const countryData: ICountryCode = (CountryCodes as ICountryCodes)[formData.country_code];

  registrationData.phone = `00${formData.phone.phoneCode}${formData.phone.phoneNumber}`;
  registrationData.country_code = formData.country_code;
  registrationData.city = countryData.city;
  registrationData.currency_name = formData.currency_name;
  registrationData.doc_number = formData.doc_number;
  registrationData.birth_date = formData.birth_date;
  registrationData.email = formData.email;
  registrationData.first_name = formData.first_name;
  registrationData.last_name = formData.last_name;
  registrationData.ignore_username = "1";
  registrationData.password = formData.password;
  registrationData.site_id = Config.app.site_id;
  registrationData.local_ip = ipAddress;
  registrationData.promo_code = formData.promo_code;

  return removeNullValuesFromObject(registrationData);
};

const Registration: React.FunctionComponent<IPropTypes> = ({ onFailure, onSucceed, contentManagerTargetType }) => {
  const { registrationFormConfig } = Config.app;

  const [recaptchaState, setRecaptchaState] = useState({
    version: RecaptchaCodes.DISABLED,
    key: "",
    error: "",
    refresh: false
  });

  const trans = useContext(TranslationContext);
  const { login } = useContext(UserContextDispatch);
  const { language } = useContext(PreferencesStateContext);
  const { supported_currencies, currency } = useContext(PartnerContextState);

  const [formError, setFormError] = useState<string>("");
  const [formDisabled, setFormDisabled] = useState(true);

  const {
    formElements,
    formConfigForCurrentStep,
    step,
    setStep,
    totalSteps,
    formIsValid,
    handleChange,
    setData,
    getData,
    setFieldError,
    setDataProp
  } = useForm(registrationFormConfig);

  const {
    country_code: { value: countryValue }
  }: Dictionary<any> = formElements;

  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    setData("country_code", GeoData.countryCode);
  }, [setData]);

  useEffect(
    function setCurrencyOptions() {
      if (supported_currencies && currency) {
        setDataProp(
          "currency_name",
          "options",
          supported_currencies.map(currency => ({ value: currency, label: currency }))
        );

        const { currency: countryCurrency } = (CountryCodes as ICountryCodes)[GeoData.countryCode];
        const defaultCurrency = supported_currencies.find(supportedCurrency => supportedCurrency === countryCurrency) ?? currency;
        setData("currency_name", defaultCurrency);
      }
    },
    [setDataProp, setData, supported_currencies, currency]
  );

  useEffect(() => {
    Swarm.getSession().then(response => {
      if (response.recaptcha_enabled) {
        if (response.recaptcha_version === RecaptchaCodes.VERSION_3) {
          Swarm.validateRecaptchaAction("register").finally(() => {
            if (isMounted.current) {
              setFormDisabled(false);
            }
          });
        } else {
          if (isMounted.current) {
            setFormDisabled(false);
          }
        }
        setRecaptchaState(recaptchaState => ({ ...recaptchaState, version: response.recaptcha_version }));
      } else {
        if (isMounted.current) {
          setFormDisabled(false);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (countryValue) {
      setData("phone", { phoneCode: (CountryCodes as ICountryCodes)[countryValue].code });
    }
  }, [countryValue, setData]);

  const handleRegistrationSuccess = (regData: Dictionary<any>) => {
    // check configuration  to autoLogin after successful registration, but
    // the backend must provide information about the possibility of authorization immediately after registration
    // if it is not possible to log in, then we should show a pop-up window about successful registration

    const { username, email, phone, password } = regData;

    login(username || email || phone, password).then(() => {
      contentManagerEventDispatcher.dispatchEvent("toggleComponentPopup", {
        component: "FirstLoginSuggestion",
        props: {
          fullScreen: true,
          contentManagerTargetType: ContentManagerTargetType.POPUP
        }
      });
    });

    if (onSucceed) {
      onSucceed();
    }
  };

  const handleRegistrationFailure = (errorCode: string) => {
    const code = Math.abs(parseInt(errorCode, 10));

    if (onFailure) {
      onFailure();
    }

    switch (code) {
      case 1013: // password is too short
        setFieldError("password", "Password is too short");
        break;
      case 1012: // Incorrect phone number
        setFieldError("phone", "Incorrect phone number");
        break;
      case 1134: // Incorrect phone number
        setFieldError("phone", "Phone number already exists");
        break;
      case 1135: // Duplicate BankInfo
        setFieldError("bank_name", "Bank name already exists");
        break;
      case 1127: // Duplicate phone number
        setFieldError("phone", "Phone number already exists");
        break;
      case 1014: // Failed to send sms
        setFieldError("phone", "Failed to send SMS");
        break;
      case 1118:
        if (formElements.username) {
          setFieldError("username", "Username already exists");
        } else if (!formElements.username && formElements.email) {
          setFieldError("email", "Email address already exists");
        }
        break;
      case 1119: // email exists
        setFieldError("email", "Email address already exists");
        break;
      case 1010: // password same as login
        setFieldError("password", "Password same as login");
        break;
      case 1123:
        setFieldError("doc_number", "Duplicate document number");
        break;
      case 21:
        if (recaptchaState.version === RecaptchaCodes.VERSION_2) {
          setRecaptchaState(prevState => ({ ...prevState, error: "Recaptcha validation failed" }));
        }
        break;
      case 1122:
        setFieldError("personal_id_6", "duplicate");
        break;
      case 2074:
        setFieldError("password", "sameAsLogin");
        setData("password2", "");
        break;
      case 2442:
        setFormError("Your details match a self-excluded customer in our database. Please contact customer support.");
        setRecaptchaState(prevState => ({ ...prevState, refresh: !prevState.refresh }));
        break;
      case 2467:
        setFormError("We cannot proceed with your request. Contact Customer Support for further information.");
        setRecaptchaState(prevState => ({ ...prevState, refresh: !prevState.refresh }));
        break;
      case 2469:
        if (formElements.loyalty_code) {
        } // else if the loyalty_code field is absent, it is necessary to delete the data from the registration data.
        break;
      case 2474: // InvalidCode
        setFieldError("confirmation_code", "invalid");
        break;
      case 2476: // CodeExpired
      case 2481: // CodeAlreadyUsed
        setFieldError("confirmation_code", "used");
        break;
      case 2482: // PhoneNumberOrContentAreInvalid
        setFieldError("phone", "invalid");
        break;
      case 2483: // PhoneNumberIsBlackListed
        setFieldError("phone", "blacklisted");
        break;
      default:
        setFormError("Registration failed due to technical error.");
        setRecaptchaState(prevState => ({ ...prevState, refresh: !prevState.refresh }));
    }
  };

  const register = async () => {
    const formData = getData();

    const regData = {
      ...(await getRegistrationData(formData)),
      language,
      lang_code: (LanguageCodes as Dictionary<string>)[language],
      ...(recaptchaState.key && { g_recaptcha_response: recaptchaState.key })
    };

    Swarm.get({ user_info: regData }, "register_user")
      .then(({ result }) => {
        if (result === "OK") {
          alert("Registration success");
          handleRegistrationSuccess(regData);
        } else {
          handleRegistrationFailure(result);
        }
      })
      .catch(error => {
        handleRegistrationFailure(error.code);
      });
  };

  const handleReCaptchaChange = (recaptchaResponse: string) => {
    setRecaptchaState(prevState => ({ ...prevState, key: recaptchaResponse, error: "" }));
  };

  const minimumAllowedAge = 21;
  const maximumAllowedAge = 110;

  const currentDate = new Date();
  const [year, month, day] = [currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()];

  const minDate = new Date(year - maximumAllowedAge, month, day);
  const maxDate = new Date(year - minimumAllowedAge, month, day);

  return (
    <form className={`entrance-form-bc registration ${contentManagerTargetType === ContentManagerTargetType.PANEL ? "panel" : "popup"}`}>
      {/* panel / popup */}

      {step > 0 ? <div className="reg-step-title-v-bc">Registration step {step + 1}</div> : null}

      <div className={`entrance-form-content-bc ${formConfigForCurrentStep.length === 1 ? "single-side" : "double-sides"} step-${step}`}>
        {formConfigForCurrentStep.map((columnElements: IFormElement[], index: number) => {
          return (
            <div className="entrance-form-content-section-bc" key={index}>
              {columnElements.map(({ name }) => {
                const { type, ...props } = formElements[name];

                switch (type) {
                  case "text":
                    return (
                      <div key={name} className="entrance-f-item-bc">
                        <InputTypeText onChange={handleChange} {...props} />
                      </div>
                    );
                  case "phone":
                    return (
                      <div key={name} className="entrance-f-item-bc">
                        <InputTypeTelephone onChange={handleChange} {...props} />
                      </div>
                    );
                  case "number":
                    return (
                      <div key={name} className="entrance-f-item-bc">
                        <InputTypeNumber onChange={handleChange} {...props} />
                      </div>
                    );
                  case "country":
                    return (
                      <div key={name} className="entrance-f-item-bc">
                        <InputTypeCountry onChange={handleChange} {...props} />
                      </div>
                    );
                  case "email":
                    return (
                      <div key={name} className="entrance-f-item-bc">
                        <InputTypeEmail onChange={handleChange} {...props} />
                      </div>
                    );
                  case "password":
                    return (
                      <div key={name} className="entrance-f-item-bc">
                        <InputTypePassword onChange={handleChange} {...props} />
                      </div>
                    );
                  case "checkbox":
                    return (
                      <div key={name} className="entrance-f-item-bc">
                        <InputTypeCheckbox onChange={handleChange} {...props} />
                      </div>
                    );
                  case "datepicker":
                    return (
                      <div key={name} className="entrance-f-item-bc">
                        <InputTypeDatePicker
                          maxDate={maxDate}
                          minDate={minDate}
                          onChange={handleChange}
                          type={DatePickerType.CALENDAR}
                          {...props}
                        />
                      </div>
                    );
                  case "info":
                    return (
                      <div key={name} className="entrance-f-item-bc">
                        <StaticTypeText {...props} />
                      </div>
                    );
                  case "select":
                    return (
                      <div key={name} className="entrance-f-item-bc">
                        <InputTypeSelect onChange={handleChange} {...props} />
                      </div>
                    );
                  default:
                    return null;
                }
              })}
              {recaptchaState.version === RecaptchaCodes.VERSION_2 && step === totalSteps - 1 ? (
                <div key="g_recaptcha_response" className="entrance-f-item-bc reg-ext-1">
                  <ReCaptcha error={recaptchaState.error} refresh={recaptchaState.refresh} onChange={handleReCaptchaChange} />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      {formError && (
        <div className="e-p-form-error-message-holder-bc">
          <span className="entrance-f-error-message-bc">{formError}</span>
        </div>
      )}

      <StepsIndicator currentStep={step} totalSteps={totalSteps} />

      <div className="entrance-form-actions-holder-bc reg-ext-1">
        {step > 0 && (
          <div className="entrance-form-action-item-bc left">
            <Button className="entrance-form-action-bc trans-v" onClick={() => setStep(step - 1)} disabled={formDisabled}>
              {trans("Back")}
            </Button>
          </div>
        )}
        {step < totalSteps - 1 && (
          <div className="entrance-form-action-item-bc right">
            <Button className="entrance-form-action-bc" disabled={!formIsValid || formDisabled} onClick={() => setStep(step + 1)}>
              {trans("Next")}
            </Button>
          </div>
        )}
        {step === totalSteps - 1 && (
          <div className="entrance-form-action-item-bc right">
            <Button
              className="entrance-form-action-bc"
              disabled={!formIsValid || formDisabled || (recaptchaState.version === RecaptchaCodes.VERSION_2 && !recaptchaState.key)}
              onClick={register}
            >
              {trans("Register")}
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};

export default memo(Registration);
