import { isMobile } from "helpers/userAgent";
import { IntegrationTypes } from "./productTypes";

const getIntegrationType = () => {
  let type: IntegrationTypes = IntegrationTypes.ENTIRE;

  (function(a) {
    if (Object.values(IntegrationTypes).includes(a)) {
      type = a;
    }
  })(window.location.pathname.split("/")[1] as IntegrationTypes);

  return type;
};

export default {
  isMobile: isMobile(),
  integrationType: getIntegrationType()
};
