import React, { useEffect, useMemo, useState } from "react";
import { isEmpty } from "helpers/utils";

import CalendarDatePicker from "./CalendarDatePicker";
import DropDownDatePicker from "./DropDownDatePicker";

import "./InputTypeDatePicker.scss";

export const DatePickerType = {
  DROPDOWN: "DROPDOWN",
  CALENDAR: "CALENDAR"
};

const InputTypeDatePicker: React.FunctionComponent<IFormDatepickerControl> = ({
  error,
  touched,
  focused,
  label,
  placeholder,
  className,
  onChange,
  name,
  type,
  value,
  minDate,
  maxDate
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    setSelectedDate(isEmpty(value) ? null : typeof value === "string" ? new Date(value) : (value as Date));
  }, [value]);

  const minDateProp = useMemo(() => {
    return typeof minDate === "undefined" ? new Date() : typeof minDate === "string" ? new Date(minDate) : minDate;
  }, [minDate]);

  const maxDateProp = useMemo(() => {
    return typeof maxDate === "undefined" ? new Date() : typeof maxDate === "string" ? new Date(maxDate) : maxDate;
  }, [maxDate]);

  const handleChange = (eventType: string, value: string) => {
    onChange(eventType, name, value);
  };

  if (type === DatePickerType.DROPDOWN) {
    return (
      <DropDownDatePicker
        value={selectedDate}
        minDate={minDateProp}
        maxDate={maxDateProp}
        error={error}
        focused={focused}
        onChange={handleChange}
        touched={touched}
      />
    );
  } else {
    return (
      <CalendarDatePicker
        value={selectedDate}
        touched={touched}
        onChange={handleChange}
        error={error}
        placeholder={placeholder}
        className={className}
        label={label}
        maxDate={maxDateProp}
        minDate={minDateProp}
      />
    );
  }
};

export default InputTypeDatePicker;
