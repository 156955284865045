import React, { memo, useContext, useMemo, useState } from "react";
import { FormEventType } from "hooks/useForm";
import { TranslationContext } from "providers/TranslationProvider";

enum FieldNames {
  PhoneCode = "phoneCode",
  PhoneNumber = "phoneNumber"
}

const InputTypeTelephone: React.FunctionComponent<IFormControl> = ({
  error,
  touched,
  focused,
  label = "",
  customAttrs,
  className,
  onChange,
  placeholder,
  name,
  value,
  ...props
}) => {
  const trans = useContext(TranslationContext);

  const [focusedState, setFocusedState] = useState({
    [FieldNames.PhoneCode]: false,
    [FieldNames.PhoneNumber]: false
  });

  const invalid = touched && error;
  const classNames: Dictionary<string[]> = {
    [FieldNames.PhoneCode]: [],
    [FieldNames.PhoneNumber]: []
  };

  const { phoneCode, phoneNumber } = useMemo(() => {
    const { phoneCode, phoneNumber } = value as Dictionary<string>;
    return {
      phoneCode: phoneCode || "",
      phoneNumber: phoneNumber || ""
    };
  }, [value]);

  classNames[FieldNames.PhoneCode].push(focusedState[FieldNames.PhoneCode] ? "focused" : "");
  classNames[FieldNames.PhoneNumber].push(focusedState[FieldNames.PhoneNumber] ? "focused" : "");

  if (touched) {
    classNames[FieldNames.PhoneCode].push(invalid ? "invalid" : "valid");
    classNames[FieldNames.PhoneNumber].push(invalid ? "invalid" : "valid");

    if (phoneCode.length > 0) {
      classNames[FieldNames.PhoneCode].push("filled");
    }
    if (phoneNumber.length > 0) {
      classNames[FieldNames.PhoneNumber].push("filled");
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { type, target } = event;

    if (target.name === FieldNames.PhoneNumber && target.value && !/^\d+$/.test(target.value)) {
      return false;
    }

    const result = {
      phoneCode,
      phoneNumber,
      [target.name]: target.value
    };
    onChange(type, name, result);

    if (type === FormEventType.FOCUS) {
      setFocusedState({
        ...focusedState,
        [target.name]: true
      });
    } else if (type === FormEventType.BLUR) {
      setFocusedState({
        ...focusedState,
        [target.name]: false
      });
    }
  };

  return (
    <div className="form-controls-group-bc telephone">
      <div className="form-controls-fields-bc">
        <div className="form-controls-field-bc country-code">
          <div className={`form-control-bc default ${classNames[FieldNames.PhoneCode].join(" ")}`}>
            {/*focused, filled, valid, invalid*/}
            <label className="form-control-label-bc inputs">
              <input
                type="number"
                name={FieldNames.PhoneCode}
                className="form-control-input-bc"
                onChange={handleChange}
                onBlur={handleChange}
                onFocus={handleChange}
                value={phoneCode}
              />
              <i className="form-control-input-stroke-bc" />
              <span className="form-control-title-bc">{trans("Code")}</span>
            </label>
          </div>
        </div>
        <div className="form-controls-field-bc">
          <div className={`form-control-bc default ${classNames[FieldNames.PhoneNumber].join(" ")}`}>
            {/*focused, filled, valid, invalid*/}
            <label className="form-control-label-bc inputs">
              <input
                type="tel"
                name={FieldNames.PhoneNumber}
                className="form-control-input-bc"
                onChange={handleChange}
                onBlur={handleChange}
                onFocus={handleChange}
                value={phoneNumber}
                {...props}
              />
              <i className="form-control-input-stroke-bc" />
              <span className="form-control-title-bc">{trans("Phone Number")}</span>
            </label>
          </div>
        </div>
      </div>

      {invalid ? (
        <div className="form-control-message-holder-bc">
          <span className="form-control-message-bc">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default memo(InputTypeTelephone);
