import { useContext, useEffect, useState } from "react";
import { PreferencesStateContext } from "providers/PreferencesProvider";
import CMSLoader from "./CMSLoader";

interface IWidget {
  content: any;
  custom_fields: any;
  instance: any;
}

interface IWidgetsResponse {
  sidebar_id: string;
  status: string;
  widgets: IWidget[];
}

const useCMSBanners = (id: string, addLang: boolean): [boolean, any[]] => {
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState<Dictionary<any>[]>([]);
  const { language } = useContext(PreferencesStateContext);

  useEffect(() => {
    setLoading(true);
    CMSLoader.getWidget<IWidgetsResponse>(`${id}${addLang && "-" + language}`, language).then(banners => {
      setBanners(banners.widgets.map(widget => widget.instance));
      setLoading(false);
    });
  }, [id, addLang, language]);

  return [loading, banners];
};

export { useCMSBanners };
