import times from "lodash/times";
import flow from "lodash/flow";
import uniq from "lodash/uniq";

import Storage from "services/storage";
import Config from "configs";
import {
  BetslipContentType,
  BetslipEventData,
  BetslipEventPartial,
  BetslipEventsDataMap,
  BetslipEventsRelevantData,
  BetType,
  BetTypeConfig,
  DEFAULT_EACH_WAY_PRICE,
  DEFAULT_UNKNOWN_VALUE,
  eventCountBetTypesMap,
  FreebetResponse,
  fullCoverBetTypes,
  fullCoverBetTypesStakeMultipliers,
  IBetslipEvent,
  IBetslipState,
  IBetslipStateMetadata,
  IFreebetOption,
  ISelectedFreebetOption,
  PriceChangeMode
} from "constants/betslip";
import {
  calcEachWayOdds,
  calculateSystemOptionsCount,
  generateAvailableBetTypesConfig,
  improveMarketAndEventNames
} from "helpers/gameInfo";
import EventDispatcher from "helpers/EventDispatcher";
import { toDecimal } from "../OddsConverterProvider";

/*
 * CONSTANTS
 */
export const STORAGE_KEY = "betslip_state";

const EVENT_DATA_TEMPLATE: BetslipEventData = {
  stake: "",
  stakeMultiplier: 1,
  counterOffer: "",
  eachWay: false
};

interface EventMap {
  remove: number;
  removeAll: void;
  toggle: BetslipEventPartial;
  updated: BetslipEventsRelevantData;
  mounted: void;
  setType: BetType[];
  setPriceChangeMode: PriceChangeMode;
  toggleExcluded: number;
  toggleEachWay: { betType: BetType; index: number };
  countChanged: number;
  updateEvent: { eventId: number; key: keyof IBetslipEvent; value: IBetslipEvent[keyof IBetslipEvent] };
  updateEventData: {
    betType: BetType;
    index: number;
    eventDataProp: keyof BetslipEventData;
    value: string | number | boolean;
  };
  setSysOption: number;
  setFreebetOption: ISelectedFreebetOption;
  repeatStakeForSingleEvents: void;
  setContentType: BetslipContentType;
}

export const BetslipDispatcher = new EventDispatcher<EventMap>();

/*
 * HELPER FUNCTIONS
 * These functions are state-agnostic
 */
const areEventsConflicting = (
  firstEvent: Pick<IBetslipEvent, "gameId" | "expressId">,
  secondEvent: Pick<IBetslipEvent, "gameId" | "expressId">
) => firstEvent.gameId === secondEvent.gameId && firstEvent.expressId === secondEvent.expressId;

const isEachWayAllowedOnAllEvents = (events: IBetslipEvent[]) => events.length > 0 && events.every(event => event.eachWayAllowed);

/* Bet types related helpers */
const changeSelectedTypesIfNecessary = (
  prevSelectedTypes: BetType[],
  availableBetTypes: BetTypeConfig[],
  fullCoverTypesEnabled: boolean,
  prevEventsCount: number = 0
) => {
  const enabledBetTypes = availableBetTypes.reduce<BetType[]>((acc, betType) => {
    if (!betType.disabled) {
      acc.push(betType.value);
    }
    return acc;
  }, []);

  if (!enabledBetTypes.length) {
    return [];
  }

  if (fullCoverTypesEnabled) {
    /* If full cover types are enabled the enabled bet types might include several of the same type
     (e.g. 3 folds and 4 folds are both system), so we filter the duplicates */
    return uniq(enabledBetTypes);
  } else {
    const multipleAvailable = enabledBetTypes.includes(BetType.Multiple);

    // If prevEventsCount is 1 then currently there are 2 events in the betslip and if possible we want to change bet type to Multiple
    if (prevEventsCount === 1 && multipleAvailable) {
      return [BetType.Multiple];
    }

    if (prevSelectedTypes.length === 1) {
      const [type] = prevSelectedTypes;
      if (enabledBetTypes.includes(type)) {
        return prevSelectedTypes;
      }
    }

    return multipleAvailable ? [BetType.Multiple] : [enabledBetTypes[0]];
  }
};
/* */

/* Events data map related helpers */
const stakeMultiplierMapFn = (eventData: BetslipEventData, i: number, newEventsData: BetslipEventData[]) => {
  const stakeMultiplier = calculateSystemOptionsCount(newEventsData.length + 2, i + 2);

  return {
    ...eventData,
    stakeMultiplier: eventData.eachWay ? stakeMultiplier * 2 : stakeMultiplier
  };
};

const createEventsData = (type: BetType, eventsCount: number) => {
  switch (type) {
    case BetType.Single:
      return times(eventsCount, () => ({ ...EVENT_DATA_TEMPLATE }));
    case BetType.System:
      return times(eventsCount - 2, () => ({ ...EVENT_DATA_TEMPLATE })).map(stakeMultiplierMapFn);
    case BetType.Multiple:
    case BetType.Chain:
      return [{ ...EVENT_DATA_TEMPLATE }];
    default: {
      return [
        {
          ...EVENT_DATA_TEMPLATE,
          stakeMultiplier: fullCoverBetTypesStakeMultipliers[type]
        }
      ];
    }
  }
};
const getInitialEventsData = (availableBetTypes: BetTypeConfig[], eventsCount: number) => {
  const eventsData: BetslipEventsDataMap = new Map();
  const availableTypes = eventCountBetTypesMap[eventsCount] || eventCountBetTypesMap.default;

  availableBetTypes.forEach(betTypeConfig => {
    const betType = betTypeConfig.value;
    eventsData.set(betType, availableTypes.includes(betType) ? createEventsData(betType, eventsCount) : []);
  });

  return eventsData;
};

const add = (type: BetType, prevEventsData: BetslipEventData[]) => {
  switch (type) {
    case BetType.Single:
      return [...prevEventsData, { ...EVENT_DATA_TEMPLATE }];
    case BetType.System:
      return [...prevEventsData, { ...EVENT_DATA_TEMPLATE }].map(stakeMultiplierMapFn);
    case BetType.Multiple:
    case BetType.Chain:
      return prevEventsData.length ? prevEventsData : [{ ...EVENT_DATA_TEMPLATE }];
    default:
      return [{ ...EVENT_DATA_TEMPLATE, stakeMultiplier: fullCoverBetTypesStakeMultipliers[type] }];
  }
};
const addEventData = (prevEventsDataMap: BetslipEventsDataMap, eventsCount: number) => {
  const eventsDataMap: BetslipEventsDataMap = new Map();
  const availableTypes = eventCountBetTypesMap[eventsCount] || eventCountBetTypesMap.default;

  prevEventsDataMap.forEach((eventData, betType) => {
    eventsDataMap.set(betType, availableTypes.includes(betType) ? add(betType, eventData) : []);
  });

  return eventsDataMap;
};

const remove = (type: BetType, prevEventsData: BetslipEventData[], index: number) => {
  switch (type) {
    case BetType.Single:
      return prevEventsData.filter((stake, i) => index !== i);
    case BetType.System:
      return prevEventsData.slice(0, prevEventsData.length - 1).map(stakeMultiplierMapFn);
    default:
      return prevEventsData.length ? prevEventsData : [{ ...EVENT_DATA_TEMPLATE }];
  }
};
const removeEventData = (eventsData: BetslipEventsDataMap, eventsCount: number, index: number) => {
  const newStakes: BetslipEventsDataMap = new Map();
  const availableTypes = eventCountBetTypesMap[eventsCount] || eventCountBetTypesMap.default;

  eventsData.forEach((eventData, betType) => {
    if (availableTypes.includes(betType)) {
      newStakes.set(betType, eventData.length ? remove(betType, eventData, index) : createEventsData(betType, eventsCount));
    } else {
      newStakes.set(betType, []);
    }
  });

  return newStakes;
};

const updateEventData = (prevData: BetslipEventData[], index: number, updatedEventData: Dictionary<string | number | boolean>) => {
  return prevData.map((data, i) => (i === index ? Object.assign({}, data, updatedEventData) : data));
};
export const updateEventsDataMap = (
  prevData: BetslipEventsDataMap,
  betslipType: BetType,
  index: number,
  updatedEventData: Dictionary<string | number | boolean>
) => {
  const newState = new Map(prevData);

  const prevEventData = newState.get(betslipType) || [];
  const newEventData = updateEventData(prevEventData, index, updatedEventData);

  newState.set(betslipType, newEventData);

  return newState;
};
/* */

export const processEventUpdates = (
  gameData: Dictionary<ISwarmGame>,
  gameId: number,
  eventId: number
): Omit<IBetslipEvent, "initialPrice" | "initialBase" | "priceType" | "gameId" | "hasConflicts"> | null => {
  const game = gameData[gameId];

  if (game) {
    // if the game exists, then the market and event must be available
    const market = Object.values(game.market)[0];
    const event = market.event[eventId];
    const formattedDecimalPrice = toDecimal(event.price, 2); // 1.00001 should be 1.00 after  convert in decimal

    const eachWayCoefficient = market.extra_info && market.extra_info.EachWayK;

    return {
      id: event.id,
      type: event.type_1,
      base: event.base || DEFAULT_UNKNOWN_VALUE,
      expressId: market.express_id || DEFAULT_UNKNOWN_VALUE,
      singleOnly: game.express_min_len === 1,
      isBlocked: Boolean(game.is_blocked) || Number(formattedDecimalPrice) === 1,
      isDeleted: false,
      isLive: game.is_live,
      sportAlias: game.sport_alias,
      regionAlias: game.region_alias,
      competitionId: game._parent_id,
      eventName: improveMarketAndEventNames(event.name, game.team1_name, game.team2_name),
      marketName: improveMarketAndEventNames(market.name, game.team1_name, game.team2_name),
      marketType: market.type,
      marketId: market.id,
      price: event.price,
      team1Name: game.team1_name,
      team2Name: game.team2_name,
      eachWayAllowed: Boolean(event.ew_allowed),
      eachWayPrice: eachWayCoefficient ? calcEachWayOdds(event.price, eachWayCoefficient) : DEFAULT_EACH_WAY_PRICE,
      isPartial: false,
      startTime: game.start_ts
    };
  }

  return null;
};

export const broadcastUpdates = (data: BetslipEventsRelevantData) => BetslipDispatcher.dispatchEvent("updated", data);

/*
 * STATE UPDATERS
 * Perform updates the Betslip state. Apart from the "getInitialState" function, all other receive current state and return updated state.
 */
export const getInitialState = () => {
  const state: Omit<IBetslipState, "metadata" | "eventsDataMap"> = Storage.getItem(STORAGE_KEY) || {
    events: [],
    priceChangeMode: PriceChangeMode.AlwaysAsk,
    selectedBetTypes: []
  };

  const eventsCount = state.events.length;

  const fullCoverBetTypesEnabled = Config.sportsbook.availableBetTypes.some((betType: BetTypeConfig) =>
    fullCoverBetTypes.includes(betType.value)
  );

  const metadata: IBetslipStateMetadata = {
    fullCoverBetTypesEnabled,
    availableBetTypes: generateAvailableBetTypesConfig(eventsCount, fullCoverBetTypesEnabled, fullCoverBetTypesEnabled),
    eachWayAllowedOnAllEvents: isEachWayAllowedOnAllEvents(state.events),
    freebet: {
      options: {},
      selectedOption: null
    },
    selectedSysOption: 0,
    excludedSysEvents: new Set(),
    contentType: BetslipContentType.Betslip
  };

  const eventsDataMap = getInitialEventsData(Config.sportsbook.availableBetTypes, eventsCount);

  // We perform this check to ensure the selected bet types are in sync with the current Config
  const selectedBetTypes = changeSelectedTypesIfNecessary(
    state.selectedBetTypes,
    metadata.availableBetTypes,
    metadata.fullCoverBetTypesEnabled
  );

  return {
    ...state,
    selectedBetTypes,
    metadata,
    eventsDataMap
  };
};

const addEventToState = (state: IBetslipState, newEvent: BetslipEventPartial): IBetslipState => {
  const events = [
    ...state.events.map(event => (areEventsConflicting(newEvent, event) ? { ...event, hasConflicts: true } : event)),
    {
      ...newEvent,
      initialPrice: newEvent.price,
      initialBase: newEvent.base,
      isBlocked: false,
      isDeleted: false,
      isLive: 0,
      sportAlias: "",
      regionAlias: "",
      competitionId: 0,
      eachWayPrice: DEFAULT_EACH_WAY_PRICE,
      singleOnly: false,
      marketName: "",
      eachWayAllowed: false,
      hasConflicts: state.events.some(event => areEventsConflicting(newEvent, event)),
      startTime: DEFAULT_UNKNOWN_VALUE
    }
  ];

  const eventsDataMap = addEventData(state.eventsDataMap, events.length);

  return { ...state, events, eventsDataMap };
};

const removeEventFromState = (state: IBetslipState, id: number): IBetslipState => {
  let index = state.events.findIndex(event => event.id === id);

  const newEventsState = [...state.events];
  newEventsState.splice(index, 1);

  const events = newEventsState.map((event, i, filteredEvents) => {
    return event.hasConflicts
      ? {
          ...event,
          hasConflicts: filteredEvents.some(filteredEvent => filteredEvent.id !== event.id && areEventsConflicting(filteredEvent, event))
        }
      : event;
  });

  const eventsDataMap = removeEventData(state.eventsDataMap, events.length, index);

  return { ...state, events, eventsDataMap };
};

const updateEvent = (state: IBetslipState, eventId: number, eventData: Partial<IBetslipEvent> | null): IBetslipState => {
  return {
    ...state,
    events: state.events.map(event => {
      if (event.id === eventId) {
        return Object.assign({}, event, eventData || { isDeleted: true });
      }

      return event;
    })
  };
};

const setEachWayAllowedState = (state: IBetslipState): IBetslipState => ({
  ...state,
  metadata: {
    ...state.metadata,
    eachWayAllowedOnAllEvents: isEachWayAllowedOnAllEvents(state.events)
  }
});

const disableEachWayIfRequired = (state: IBetslipState): IBetslipState => {
  const getDisabledEachWayData = (eventData: BetslipEventData) => ({
    eachWay: false,
    stakeMultiplier: eventData.stakeMultiplier / 2
  });

  const eventsDataMap = new Map(state.eventsDataMap);

  for (const betType of state.selectedBetTypes) {
    const prevEventData = eventsDataMap.get(betType) || [];

    const eventData =
      betType === BetType.Single
        ? prevEventData.map((eventData, i) => {
            if (eventData.eachWay && !state.events[i].eachWayAllowed) {
              return Object.assign({}, eventData, getDisabledEachWayData(eventData));
            }

            return eventData;
          })
        : prevEventData.map(eventData => {
            if (eventData.eachWay && !state.metadata.eachWayAllowedOnAllEvents) {
              return Object.assign({}, eventData, getDisabledEachWayData(eventData));
            }

            return eventData;
          });

    eventsDataMap.set(betType, eventData);
  }

  return { ...state, eventsDataMap };
};

const resetSelectedSysOption = (state: IBetslipState): IBetslipState => ({
  ...state,
  metadata: { ...state.metadata, selectedSysOption: 0 }
});

const setAvailableBetTypesState = (state: IBetslipState) => ({
  ...state,
  metadata: {
    ...state.metadata,
    availableBetTypes: generateAvailableBetTypesConfig(
      state.events.length,
      state.metadata.fullCoverBetTypesEnabled,
      state.metadata.fullCoverBetTypesEnabled
    )
  }
});

const changeSelectedBetTypesState = (eventIsAdded: boolean) => (state: IBetslipState) => {
  // We use currying to know if this function is called when adding or when removing an event from the betslip.
  // For example, if it is called while adding an event, then the previous event count will equal to the current - 1
  const {
    events,
    selectedBetTypes,
    metadata: { availableBetTypes, fullCoverBetTypesEnabled }
  } = state;
  const prevEventsCount = eventIsAdded ? events.length - 1 : events.length + 1;

  return {
    ...state,
    selectedBetTypes: changeSelectedTypesIfNecessary(selectedBetTypes, availableBetTypes, fullCoverBetTypesEnabled, prevEventsCount)
  };
};

const resetBetslipContentType = (state: IBetslipState): IBetslipState => ({
  ...state,
  metadata: { ...state.metadata, contentType: BetslipContentType.Betslip }
});

export const setFreebetOptions = (data: FreebetResponse["Data"]) => (state: IBetslipState): IBetslipState => {
  const options: Dictionary<IFreebetOption[]> = {};

  for (const { Id, Amount, AvailableBetTypes } of data) {
    for (const betType of AvailableBetTypes) {
      if (!options[betType]) {
        options[betType] = [];
      }

      options[betType].push({ id: Id, amount: Amount });
    }
  }

  return {
    ...state,
    metadata: { ...state.metadata, freebet: { options, selectedOption: null } }
  };
};

/*
 * COMPOSED FUNCTIONS
 * State updaters grouped by their functionality.
 * N.B. The order is important, be careful when adding/removing functions
 */
const adjustBetTypes = (eventIsAdded: boolean) => flow(setAvailableBetTypesState, changeSelectedBetTypesState(eventIsAdded));

const updateEachWay = flow(setEachWayAllowedState, disableEachWayIfRequired);

export const addEventToBetslip = flow(
  addEventToState,
  adjustBetTypes(true),
  updateEachWay,
  resetSelectedSysOption,
  resetBetslipContentType
);

export const removeEventFromBetslip = flow(removeEventFromState, adjustBetTypes(false), updateEachWay, resetSelectedSysOption);

export const processSwarmUpdate = flow(updateEvent, updateEachWay);
