interface BaseEventMap {
  [k: string]: any;
}

type Listener<T> = {
  (data: T): void;
};

interface IEventDispatcher<T extends BaseEventMap> {
  addEventListener<K extends keyof T>(type: K, listener: Listener<T[K]>): VoidFunction;
  removeEventListener<K extends keyof T>(type: K, listener: Listener<T[K]>): void;
  removeEventListeners<K extends keyof T>(type: K): void;
  dispatchEvent<K extends keyof T>(type: K, data: T[K]): void;
}

class EventDispatcher<T extends BaseEventMap> implements IEventDispatcher<T> {
  private listeners: Map<any, Listener<any>[]> = new Map<any, Listener<any>[]>();

  addEventListener<K extends keyof T>(type: K, listener: Listener<T[K]>): VoidFunction {
    if (!this.listeners.has(type)) {
      this.listeners.set(type, []);
    }
    const listeners = this.listeners.get(type);
    if (listeners) {
      listeners.push(listener);
    }

    return () => {
      this.removeEventListener(type, listener);
    };
  }

  dispatchEvent<K extends keyof T>(type: K, data?: T[K]): void {
    const listeners = this.listeners.get(type);
    if (listeners) {
      listeners.forEach(listener => listener(data));
    }
  }

  removeEventListener<K extends keyof T>(type: K, listener: Listener<T[K]>): void {
    const listeners = this.listeners.get(type);
    if (listeners) {
      this.listeners.set(
        type,
        listeners.filter(l => l !== listener)
      );
    }
  }

  removeEventListeners<K extends keyof T>(type: K): void {
    this.listeners.delete(type);
  }
}

export default EventDispatcher;

interface RootEventMap {
  [K: string]: any;
}
export const RootEventDispatcher = new EventDispatcher<RootEventMap>();
