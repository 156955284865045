import debounce from "lodash/debounce";

export function debounceReduce(func: CallbackFunction, wait: number, combine: (acc: any, args: any) => any) {
  let allArgs: any; // accumulator for args across calls

  // normally-debounced fn that we will call later with the accumulated args
  const wrapper = debounce(() => {
    func(allArgs);
    allArgs = undefined;
  }, wait);

  // what we actually return is this function which will really just add the new args to
  // allArgs using the combine fn
  return (...args: any) => {
    allArgs = combine(allArgs, [...args]);
    wrapper();
  };
}

export function delay(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}
