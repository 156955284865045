/**
 * Provider that provides two method:
 * updateTranslation - to set the language and translation if provided, or fetch according to the language provided, and then sets the translation.
 * trans - finds translation with options for provided key and returns it
 */

import React, { useCallback, useContext } from "react";
import axios from "axios";

import Log from "../helpers/Log";
import { getCurrentTimeRoundedByMinutes } from "../helpers/utils";
import { PreferencesStateContext } from "./PreferencesProvider";

const translations: Dictionary<Dictionary<string>> = {};

interface IValues extends Dictionary<string | number> {}

interface ITranslationContext {
  (id: string, values?: IValues): string;
}

export const getTranslation = (lang: string) =>
  new Promise((resolve, reject) => {
    if (translations[lang]) {
      resolve();
    } else {
      axios
        .get(`/translations/${lang}.json?v=${getCurrentTimeRoundedByMinutes(5)}`)
        .then(response => {
          translations[lang] = response.data[lang];
          resolve();
        })
        .catch(error => {
          Log.error(`failed to load external translation, error: ${error} , processing will be done using locale translation`);
          reject();
        });
    }
  });

export const TranslationContext = React.createContext<ITranslationContext>({} as ITranslationContext);

const TranslationProvider: React.FunctionComponent = ({ children }) => {
  const { language } = useContext(PreferencesStateContext);

  const trans = useCallback(
    (id: string, values: IValues = {}): string => {
      let translation = id;

      if (translations[language] && translations[language][id]) {
        translation = translations[language][id];
      }

      if (!translation) {
        return translation;
      }

      return translation.replace(/{([^}]*)}/g, (match: string, key: string) => {
        return values[key] !== undefined ? values[key].toString() : match;
      });
    },
    [language]
  );

  return <TranslationContext.Provider value={trans}>{children}</TranslationContext.Provider>;
};

export default TranslationProvider;
