import React, { createContext, useCallback, useContext } from "react";

import Config from "configs";
import { PreferencesStateContext } from "../PreferencesProvider";
import { PartnerContextState } from "../PartnerProvider";
import toFractional from "./toFractional";
import toAmerican from "./toAmerican";
import toHongKong from "./toHongKong";
import toMalay from "./toMalay";
import toIndo from "./toIndo";
import toDecimal from "./toDecimal";

interface IOddsConverterContext {
  (odds: number): string;
}

export enum OddsFormat {
  Decimal,
  Fractional,
  American,
  HongKong,
  Malay,
  Indo
}

export const DEFAULT_DECIMAL_ROUNDING = 3;

export const OddsConverterContext = createContext<IOddsConverterContext>({} as IOddsConverterContext);

const OddsConverterProvider: React.FC = ({ children }) => {
  const { minDecimalPlaces } = Config.sportsbook;
  const { oddFormat } = useContext(PreferencesStateContext);
  const { price_decimals: decimalRounding = DEFAULT_DECIMAL_ROUNDING } = useContext(PartnerContextState);

  const oddsConverter = useCallback(
    (odds: number) => {
      switch (oddFormat) {
        case OddsFormat.Fractional:
          return toFractional(odds);
        case OddsFormat.American:
          return toAmerican(odds);
        case OddsFormat.HongKong:
          return toHongKong(odds, decimalRounding, minDecimalPlaces);
        case OddsFormat.Malay:
          return toMalay(odds, decimalRounding);
        case OddsFormat.Indo:
          return toIndo(odds, decimalRounding);
        case OddsFormat.Decimal:
        default:
          return toDecimal(odds, minDecimalPlaces);
      }
    },
    [oddFormat, decimalRounding, minDecimalPlaces]
  );

  return <OddsConverterContext.Provider value={oddsConverter}>{children}</OddsConverterContext.Provider>;
};

export default OddsConverterProvider;
