import AuthPopupTemplate from "dev/components/auth/AuthPopupTemplate";
import React, { memo, useCallback, useState } from "react";

export enum AuthPopupContentTypeEnum {
  REGISTRATION = "REGISTRATION",
  LOGIN = "LOGIN"
}

type AuthPopupContentType = AuthPopupContentTypeEnum.LOGIN | AuthPopupContentTypeEnum.REGISTRATION;

interface IPropTypes {
  popupContentType: AuthPopupContentType;
}

const AuthPopup: React.FunctionComponent<IPropTypes> = ({ popupContentType }) => {
  const [activeContentType, setActiveContentType] = useState<AuthPopupContentType>(popupContentType);
  const [formInvalid, setFormInvalid] = useState<boolean>(false);

  const changeActiveContentType = useCallback((activeContentType: AuthPopupContentType) => {
    setActiveContentType(activeContentType);
  }, []);

  const handleSetFormInvalid = useCallback((invalid: boolean) => {
    setFormInvalid(invalid);
  }, []);

  return (
    <AuthPopupTemplate
      activeContentType={activeContentType}
      setActiveContentType={changeActiveContentType}
      formInvalid={formInvalid}
      setFormInvalid={handleSetFormInvalid}
    />
  );
};

export default memo(AuthPopup);
