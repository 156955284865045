import { TranslationContext } from "providers/TranslationProvider";
import React, { memo, useContext } from "react";
import "./InputTypeCheckbox.scss";

const InputTypeCheckbox: React.FunctionComponent<IFormControl> = ({
  error,
  touched,
  focused,
  label = "",
  customAttrs,
  className,
  onChange,
  placeholder,
  value,
  ...props
}) => {
  const invalid = touched && error;

  const inputValue = value as boolean;

  const trans = useContext(TranslationContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      type,
      target: { name, checked }
    } = event;
    onChange(type, name, checked);
  };

  return (
    <div className={`checkbox-control-bc ${inputValue && "checked"}`}>
      {/*checked*/}
      <label className="checkbox-control-content-bc">
        <span className="checkbox-control-input-holder-bc">
          <input type="checkbox" className="checkbox-control-input-bc" onChange={handleChange} checked={inputValue} {...props} />
          {inputValue ? <i className="checkbox-control-icon-bc bc-i-checked" /> : <i className="checkbox-control-icon-bc" />}
        </span>
        <p className="checkbox-control-text-bc">{trans(label)}</p>
      </label>
      {invalid ? (
        <div className="form-control-message-holder-bc">
          <span className="form-control-message-bc">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default memo(InputTypeCheckbox);
