import { useContext, useEffect } from "react";
import { UserContextDispatch } from "providers/UserProvider";
import useLogout from "./useLogout";
import Log from "../helpers/Log";

const ActionTypes = {
  LOGIN: "restore_login",
  LOGOUT: "logout",
  SHOW_ALERT: "showAlert",
  OPEN_CASINO_GAME: "openGame",
  SET_PATH: "setPath"
};

export const useWindowMessages = () => {
  const { restoreLogin } = useContext(UserContextDispatch);
  const { logoutUser } = useLogout();

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.action) {
        const { action, data } = event.data;
        switch (action) {
          case ActionTypes.LOGIN:
            restoreLogin(data);
            break;
          case ActionTypes.LOGOUT:
            logoutUser().then();
            break;
          case ActionTypes.SHOW_ALERT:
            break;
          case ActionTypes.OPEN_CASINO_GAME:
            break;
          case ActionTypes.SET_PATH:
            break;
          default:
            Log.warning(`failed to handle message with action: ${action}`);
        }
      }
    };
    window.addEventListener("message", handleMessage);

    return () => window.removeEventListener("message", handleMessage);
  }, [logoutUser, restoreLogin]);

  // only need to be implemented for integrated apps: Config.integrationMode : true, or location path is widget / iframe / embed
  useEffect(() => {
    window.parent.postMessage({ action: "appReady" }, "*");
  }, []);
};
