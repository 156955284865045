import React, { MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from "react";

import { AuthPanelContentTypeEnum } from "dev/components/auth/AuthPanel";
import { AuthPopupContentTypeEnum } from "dev/components/auth/AuthPopup";
import EventType from "constants/eventType";
import ContentTemplate from "dev/components/contentContainer/ContentTemplate";
import { RootEventDispatcher } from "helpers/EventDispatcher";
import { TranslationContext } from "providers/TranslationProvider";
import { NavigationContext } from "providers/NavigationProvider";
import { ContentManagerTargetType } from "dev/components/contentManager";
import contentManagerEventDispatcher from "dev/components/contentManager/contentManagerDispatcher";

import "./Content.scss";

export type IContentTemplate = {
  hostClassNames: string;
  isSelected: boolean;
  hideContent: (event: React.MouseEvent) => void;
  contentOverlayRef: MutableRefObject<HTMLElement | null>;
  navLinks: INavLink[];
  profileNavLinks: INavLink[];
  openRegistrationForm: VoidFunction;
  openLoginForm: VoidFunction;
};

const Content = () => {
  const contentOverlayRef = useRef<HTMLElement>(null);
  const trans = useContext(TranslationContext);

  const { headerNavigation, profileNavigation } = useContext(NavigationContext);

  const openRegistrationForm = useCallback(() => {
    const isPopup = true;
    if (isPopup) {
      contentManagerEventDispatcher.dispatchEvent("toggleComponentPopup", {
        component: "AuthPopup",
        props: {
          fullScreen: true,
          popupContentType: AuthPopupContentTypeEnum.REGISTRATION,
          contentManagerTargetType: ContentManagerTargetType.POPUP
        }
      });
    } else {
      contentManagerEventDispatcher.dispatchEvent("toggleComponentPanel", {
        component: "AuthPanel",
        props: {
          defaultPanelContentType: AuthPanelContentTypeEnum.REGISTRATION,
          panelContentTypes: [
            {
              title: trans("sign in"),
              contentType: AuthPanelContentTypeEnum.LOGIN
            },
            {
              title: trans("register"),
              contentType: AuthPanelContentTypeEnum.REGISTRATION
            }
          ],
          contentManagerTargetType: ContentManagerTargetType.PANEL
        }
      });
    }
  }, [trans]);

  const openLoginForm = useCallback(() => {
    const isPopup = true;
    if (isPopup) {
      contentManagerEventDispatcher.dispatchEvent("toggleComponentPopup", {
        component: "AuthPopup",
        props: {
          fullScreen: true,
          popupContentType: AuthPopupContentTypeEnum.LOGIN,
          contentManagerTargetType: ContentManagerTargetType.POPUP
        }
      });
    } else {
      contentManagerEventDispatcher.dispatchEvent("toggleComponentPanel", {
        component: "AuthPanel",
        props: {
          defaultPanelContentType: AuthPanelContentTypeEnum.LOGIN,
          panelContentTypes: [
            {
              title: trans("sign in"),
              contentType: AuthPanelContentTypeEnum.LOGIN
            },
            {
              title: trans("register"),
              contentType: AuthPanelContentTypeEnum.REGISTRATION
            }
          ],
          contentManagerTargetType: ContentManagerTargetType.PANEL
        }
      });
    }
  }, [trans]);

  const hideContent = useCallback((event: React.MouseEvent): void => {
    if (event.target === contentOverlayRef.current) {
      contentManagerEventDispatcher.dispatchEvent("hideAll");
    }
  }, []);

  const [isSelected, setIsSelected] = useState(false);

  useEffect(
    () =>
      contentManagerEventDispatcher.addEventListener("activeComponent", data => {
        if (data.selectedComponent) {
          setIsSelected(true);
          RootEventDispatcher.dispatchEvent(EventType.ADD_ROOT_CLASS_NAME, "sp-dynamic-content-opened");
        } else {
          setIsSelected(false);
          RootEventDispatcher.dispatchEvent(EventType.REMOVE_ROOT_CLASS_NAME, "sp-dynamic-content-opened");
        }
      }),
    []
  );

  //TODO: clarify in which cases "home-page-fixed-sides" class name should be added
  const hostClassNames = `lt-content-holder-bc ${true ? "home-page-fixed-sides" : ""}`;

  return (
    <ContentTemplate
      navLinks={headerNavigation.navLinks}
      profileNavLinks={profileNavigation.navLinks}
      openRegistrationForm={openRegistrationForm}
      openLoginForm={openLoginForm}
      hideContent={hideContent}
      contentOverlayRef={contentOverlayRef}
      hostClassNames={hostClassNames}
      isSelected={isSelected}
    />
  );
};

export default Content;
