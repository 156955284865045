import { ProductTypes } from "./productTypes";

type ComponentRouteType = ProductTypes.SPORTSBOOK | ProductTypes.CASINO;

type ComponentRoute = {
  type?: ComponentRouteType;
  componentName: string;
};

const componentRouteMap: Dictionary<ComponentRoute> = {
  "/": { componentName: "Home" },

  "/sports/:gameType/:gamePageName/:sportAlias?/:regionAlias?/:competitionId?/:gameId?": {
    componentName: "SportsBook",
    type: ProductTypes.SPORTSBOOK
  },
  "/bet-on-lineups/:pageName?/:competitionId?/:gameId?": {
    componentName: "BetOnLineupsPage",
    type: ProductTypes.SPORTSBOOK
  },
  "/casino/slots/:categoryId?/:gameId?": { componentName: "CasinoSlots", type: ProductTypes.CASINO },
  "/casino/tournaments/:tournamentId?/:gameId?": { componentName: "CasinoTournaments", type: ProductTypes.CASINO },
  "/casino/live": { componentName: "CasinoLive", type: ProductTypes.CASINO },
  "/history/:pageName": { componentName: "UserBetHistoryPage" },
  "/balance/:pageName": { componentName: "UserBalancePage" },
  "/bonus/:pageName": { componentName: "UserBonusPage" },
  "/profile/:pageName": { componentName: "UserProfilePage" },
  "/messages/:pageName": { componentName: "UserMessagesPage" },
  "/promotions/:promotionId?": { componentName: "PromotionsPage" },

  "/sport/event-view": { componentName: "SportEventView", type: ProductTypes.SPORTSBOOK },
  "/home/content": { componentName: "HomeContent" },
  "/widget/simple-slider": { componentName: "SimpleSlider" },
  "/embed/simple-slider": { componentName: "SimpleSlider" },
  "/widget/featured-games": { componentName: "FeaturedGames" },
  "/embed/page-builder": { componentName: "PageBuilder" },
  "/skinning": { componentName: "Skinning", type: ProductTypes.CASINO }
};

export default componentRouteMap;
