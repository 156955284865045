class Subscription {
  id: string;

  onUpdate: CallbackFunction;
  onFailure: CallbackFunction;

  constructor(onUpdate: CallbackFunction, onFailure: CallbackFunction) {
    this.id = Subscription.generateId();

    this.onUpdate = onUpdate;
    this.onFailure = onFailure;
  }

  private static generateId() {
    return Math.round(Math.random() * Math.pow(10, 16)).toString();
  }
}

export default Subscription;
