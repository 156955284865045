import Subscription from "connection/Subscription";
import { isObject } from "helpers/utils";

class Request {
  private data: Dictionary<any> | null = null;
  private readonly mergeUpdateData: boolean;

  public rid: string;
  public subId: string | null = null;
  public body: Dictionary<any>;
  public subscribe: boolean;
  public requestHandlers: Map<string, Subscription> = new Map<string, Subscription>();

  constructor(rid: string, body: Dictionary<any>, subscribe: boolean = false, mergeUpdateData: boolean = true) {
    this.rid = rid;
    this.body = body;
    this.subscribe = subscribe;
    this.mergeUpdateData = mergeUpdateData;
  }

  addHandler(onUpdate: CallbackFunction = () => {}, onFailure: CallbackFunction = () => {}): Subscription {
    const subscription = new Subscription(onUpdate, onFailure);
    this.requestHandlers.set(subscription.id, subscription);

    if (this.data) {
      this.updateHandler(subscription, true);
    }

    return subscription;
  }

  removeHandler(id: string) {
    this.requestHandlers.delete(id);
  }

  updateHandler(handler: Subscription, initial: boolean = false) {
    handler.onUpdate(this.data, initial);
  }

  initializeHandlers() {
    this.requestHandlers.forEach(handler => this.updateHandler(handler, true));
  }

  updateHandlers() {
    this.requestHandlers.forEach(handler => this.updateHandler(handler));
  }

  failHandlers(reason: any) {
    this.requestHandlers.forEach(handler => handler.onFailure(reason));
  }

  setData(value: Dictionary<any>) {
    this.data = value;
    this.initializeHandlers();
  }

  updateData(value: any) {
    this.data = this.mergeUpdateData ? Request.merge(this.data, value) : value;
    this.updateHandlers();
  }

  private static merge(target: any, src: any) {
    const newData = { ...target };

    for (let prop in src) {
      if (src.hasOwnProperty(prop)) {
        if (newData.hasOwnProperty(prop)) {
          if (isObject(src[prop]) && isObject(newData[prop])) {
            newData[prop] = Request.merge(newData[prop], src[prop]);
          } else if (src[prop] === null) {
            delete newData[prop];
          } else {
            newData[prop] = src[prop];
          }
        } else {
          newData[prop] = src[prop];
        }
      }
    }

    return newData;
  }
}

export default Request;
