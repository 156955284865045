import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface IPropTypes extends RouteProps {}

const PrivateRoute: React.FunctionComponent<IPropTypes> = ({ path, exact }) => {
  return <Route exact={exact} path={path} render={() => <Redirect to="/" />} />;
};

export default React.memo(PrivateRoute);
