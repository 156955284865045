import React from "react";
import { useWindowMessages } from "hooks/useWindowMessages";

const OuterRelationHelper: React.FunctionComponent = ({ children }) => {
  useWindowMessages();

  return <>{children}</>;
};

export default OuterRelationHelper;
