import Login from "dev/components/login/Login";
import Registration from "dev/components/registration/Registration";
import React, { memo } from "react";
import { ContentManagerTargetType } from "dev/components/contentManager";

export enum AuthPanelContentTypeEnum {
  REGISTRATION = "REGISTRATION",
  LOGIN = "LOGIN"
}

type AuthPanelContentType = AuthPanelContentTypeEnum.LOGIN | AuthPanelContentTypeEnum.REGISTRATION;

interface IPropTypes {
  panelContentType: AuthPanelContentType;
}

const AuthPanel: React.FunctionComponent<IPropTypes> = ({ panelContentType }) => {
  return (
    <>
      {panelContentType === AuthPanelContentTypeEnum.REGISTRATION && (
        <Registration contentManagerTargetType={ContentManagerTargetType.PANEL} />
      )}
      {panelContentType === AuthPanelContentTypeEnum.LOGIN && <Login contentManagerTargetType={ContentManagerTargetType.PANEL} />}
    </>
  );
};

export default memo(AuthPanel);
