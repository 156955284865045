import { SCORE_UNAVAILABLE } from "constants/sportsBook";
import {
  BetslipEventsDataMap,
  BetslipEventsRelevantData,
  BetslipMessageType,
  BetType,
  DEFAULT_UNKNOWN_VALUE,
  DoBetRequest,
  DoBetResponse,
  DoBetResponseMessage,
  DoBetSuccessResponse,
  FreebetRequest,
  IBetslipEvent,
  IBetslipMessage,
  IBetslipState,
  PriceChangeMode,
  PriceType,
  SP_PRICE
} from "constants/betslip";
import runtimeConstants from "constants/runtimeConstants";
import { SessionSource } from "connection/Swarm";
import { OddsFormat } from "providers/OddsConverterProvider";
import { fixFloatError } from "../math";
import { getMapProp } from "../utils";

export function getEventTypes<T extends any>(matches: T[], marketType: string) {
  let eventTypes: string[] = [];

  if (marketType === "WINNER") {
    const eventsCount: number[] = [];
    matches.forEach(match => {
      if (match.market[marketType]) {
        eventsCount.push(Object.keys(match.market[marketType].event).length);
      }
    });

    if (eventsCount.some(eventCount => eventCount === 3)) {
      eventTypes = ["W1", "X", "W2"];
    } else {
      eventTypes = ["W1", "W2"];
    }
  } else if (marketType === "HANDICAP") {
    eventTypes = ["Home", "Away"];
  } else if (marketType === "TOTALS") {
    eventTypes = ["Over", "Under"];
  }

  return eventTypes;
}

export const getGameSetCount = (stats: ISwarmGameStats) => {
  if (!stats) {
    return [];
  }

  return Object.keys(stats)
    .filter(statName => statName.includes("score_set"))
    .sort();
};

export const getTeamsScores = (gameData: ISwarmGame) => {
  const scores = [];

  const { team2_name, info, stats } = gameData;

  if (info) {
    const { score2, score1, set_count } = info;

    const score = Number(set_count === 1 ? stats.score_set1.team1_value : score1);
    scores.push(!isNaN(score) ? score : SCORE_UNAVAILABLE);

    if (team2_name) {
      const score = Number(set_count === 1 ? stats.score_set1.team2_value : score2);
      scores.push(!isNaN(score) ? score : SCORE_UNAVAILABLE);
    }
  }

  return scores;
};

export const getDisplayBase = (base: number, marketType: string) => {
  if (base === DEFAULT_UNKNOWN_VALUE) {
    return "";
  }

  if (marketType.includes("Handicap") && base > 0) {
    return `(+${base})`;
  }

  return `(${base})`;
};

export const getEventPrice = (price: number, priceType: PriceType) => (priceType === PriceType.Fixed ? price : SP_PRICE);

export const calculateSystemOptionsCount = (eventsCount: number, groupCount: number) => {
  const factorialMap = [
    1,
    1,
    2,
    6,
    24,
    120,
    720,
    5040,
    40320,
    362880,
    3628800,
    39916800,
    479001600,
    6227020800,
    87178291200,
    1307674368000,
    121645100408832000
  ];

  return factorialMap[eventsCount] / (factorialMap[groupCount] * factorialMap[eventsCount - groupCount]);
};

const formatEventsForDoBetRequest = (events: IBetslipEvent[]): DoBetRequest["Events"] =>
  events.map(event => ({
    SelectionId: event.id,
    Coeficient: getEventPrice(event.price, event.priceType)
  }));

export const generateBetRequests = (betslip: IBetslipState, oddFormat: OddsFormat) => {
  const requests: DoBetRequest[] = [];

  const {
    events,
    priceChangeMode,
    metadata: { selectedSysOption, fullCoverBetTypesEnabled },
    selectedBetTypes,
    eventsDataMap
  } = betslip;

  const currentEvents = formatEventsForDoBetRequest(events);

  selectedBetTypes.forEach(betType => {
    let eventsData = getMapProp(eventsDataMap, betType, []);

    if (!fullCoverBetTypesEnabled && betType === BetType.System) {
      eventsData = eventsData.slice(selectedSysOption, selectedSysOption + 1);
    }

    eventsData.forEach((eventData, i) => {
      // Todo: Consider currency rounding instead of just fixing float error
      const totalStake = fixFloatError(Number(eventData.stake) * eventData.stakeMultiplier);

      if (totalStake > 0) {
        const request: DoBetRequest = {
          Source: runtimeConstants.isMobile ? SessionSource.Mobile : SessionSource.Html5,
          Type: betType,
          Amount: totalStake,
          AcceptTypeId: priceChangeMode,
          OddType: oddFormat,
          EachWay: eventData.eachWay,
          Events: betType === BetType.Single ? [currentEvents[i]] : currentEvents
        };

        const counterOffer = Number(eventData.counterOffer);
        if (counterOffer > 0) {
          request.AcceptTypeId = PriceChangeMode.CounterOffer;
          request.Events = request.Events.map(event => ({ ...event, Coeficient: counterOffer }));
        }

        if (betType === BetType.System) {
          request.SystemMinCount = (fullCoverBetTypesEnabled ? i : selectedSysOption) + 2;
        }

        requests.push(request);
      }
    });
  });

  return requests;
};

export const generateAvailableFreebetsRequest = (eventsRelevantData: BetslipEventsRelevantData): FreebetRequest => {
  const eventsIds = Object.keys(eventsRelevantData);

  return {
    IsLive: eventsIds.some(eventId => eventsRelevantData[eventId].isLive),
    Events: eventsIds.map(eventId => ({
      SelectionId: Number(eventId),
      Coeficient: getEventPrice(eventsRelevantData[eventId].price, eventsRelevantData[eventId].priceType)
    }))
  };
};

export const generateFreebetRequest = (betslip: IBetslipState, oddsFormat: OddsFormat): MakeRequired<DoBetRequest, "ClientBonusId">[] => {
  const {
    metadata: {
      freebet: { selectedOption }
    },
    events,
    priceChangeMode
  } = betslip;

  if (!selectedOption) throw new Error("No selected freebet");

  return [
    {
      Source: runtimeConstants.isMobile ? SessionSource.Mobile : SessionSource.Html5,
      Type: selectedOption.betType,
      OddType: oddsFormat,
      AcceptTypeId: priceChangeMode,
      EachWay: selectedOption.eachWay,
      Events: formatEventsForDoBetRequest(events),
      ClientBonusId: selectedOption.id,
      Amount: selectedOption.amount,
      ...(selectedOption.sysCount && { SystemMinCount: selectedOption.sysCount })
    }
  ];
};

export const isDoBetSuccess = (response: DoBetResponse): response is DoBetSuccessResponse => response.StatusCode === "0";

export const calculateTotalStakeAmount = (eventsDataMap: BetslipEventsDataMap, selectedBetTypes: BetType[]) => {
  return selectedBetTypes.reduce((totalStake, betType) => {
    const eventsData = eventsDataMap.get(betType);
    if (eventsData) {
      return totalStake + eventsData.reduce((total, eventData) => total + Number(eventData.stake) * eventData.stakeMultiplier, 0);
    }
    return totalStake;
  }, 0);
};

export const buildBetslipMessage = ({
  type,
  title,
  lifetime = -1,
  persistent = false,
  blocking = false
}: {
  type: BetslipMessageType;
  title: string;
  lifetime?: number;
  persistent?: boolean;
  blocking?: boolean;
}): IBetslipMessage => ({
  type,
  title,
  createdDate: Date.now(),
  lifetime,
  persistent,
  blocking
});

export const buildBetResponseMessage = (type: BetslipMessageType, title: string, eventId?: number): DoBetResponseMessage => ({
  type,
  title,
  eventId
});

export const calcEachWayOdds = (price: number, eachWayCoefficient: number) => (price - 1) / eachWayCoefficient + 1;
