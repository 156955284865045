import { useCallback, useEffect, useState } from "react";
import produce, { Draft } from "immer";

import componentPaths from "constants/componentPaths";
import lazyLoadComponent from "helpers/lazyLoadComponent";
import withPanelContent from "hocs/withPanelContent";
import contentManagerEventDispatcher from "dev/components/contentManager/contentManagerDispatcher";
import withPopupContent from "hocs/withPopupContent";

export enum ContentManagerTargetType {
  PANEL = "PANEL",
  POPUP = "POPUP"
}

const getInitialState = (): IContentComponent[] => [];

/**
 * A custom hook for using Content Manager
 */
export function useContentManager(target: ContentManagerTargetType): IContentManager {
  // Used function to get initial state in order for the 'content' constant to be documented properly
  const [components, setComponents] = useState(getInitialState);

  const selectedComponent = components.find(component => component.selected);

  useEffect(
    function dispatchSelectedComponent() {
      contentManagerEventDispatcher.dispatchEvent("activeComponent", { selectedComponent });

      if (selectedComponent) {
        // There can be only one component displayed both in panel or popup
        if (target === ContentManagerTargetType.POPUP) {
          contentManagerEventDispatcher.dispatchEvent("hidePanel");
        } else {
          contentManagerEventDispatcher.dispatchEvent("hidePopup");
        }
      }
    },
    [target, selectedComponent]
  );

  const createComponent = useCallback(
    ({ component, props = {} }, persistent = false): IContentComponent | null => {
      const Component = lazyLoadComponent(componentPaths[component].content);

      return Component
        ? {
            LazyComponent: target === ContentManagerTargetType.POPUP ? withPopupContent(Component) : withPanelContent(Component),
            name: component,
            selected: false,
            persistent,
            props
          }
        : null;
    },
    [target]
  );

  useEffect(
    function mountPersistentComponent() {
      return contentManagerEventDispatcher.addEventListener(
        target === ContentManagerTargetType.POPUP ? "mountContainerPopup" : "mountContainerPanel",
        data => {
          setComponents(
            produce((components: Draft<IContentComponent[]>) => {
              const newComponent = createComponent(data, true);
              if (newComponent) {
                components.push(newComponent);
              }
            })
          );
        }
      );
    },
    [target, createComponent]
  );

  useEffect(function unmountPersistentComponent() {
    return contentManagerEventDispatcher.addEventListener(
      target === ContentManagerTargetType.POPUP ? "unmountContainerPopup" : "unmountContainerPanel",
      data => {
        setComponents(components => {
          return components.filter(component => component.name !== data.component);
        });
      }
    );
  });

  useEffect(
    function toggleComponent() {
      return contentManagerEventDispatcher.addEventListener(
        target === ContentManagerTargetType.POPUP ? "toggleComponentPopup" : "toggleComponentPanel",
        data => {
          setComponents(
            produce((components: Draft<IContentComponent[]>) => {
              const i = components.findIndex(component => component.selected);
              const prevSelected = components[i];

              if (prevSelected) {
                prevSelected.persistent ? (prevSelected.selected = false) : components.splice(i, 1);
              }

              if (!prevSelected || prevSelected.name !== data.component) {
                const component = components.find(component => component.name === data.component);
                if (component) {
                  component.selected = true;
                } else {
                  const newComponent = createComponent(data);
                  if (newComponent) {
                    newComponent.selected = true;
                    components.push(newComponent);
                  }
                }
              }
            })
          );
        }
      );
    },
    [target, createComponent]
  );

  /**
   * 'Unselects' selected component. Removes component if it is not persistent
   */
  const hide = useCallback<VoidFunction>(() => {
    setComponents(
      produce((components: Draft<IContentComponent[]>) => {
        const index = components.findIndex(component => component.selected);
        if (index !== -1) {
          const selectedComponent = components[index];
          selectedComponent.persistent ? (selectedComponent.selected = false) : components.splice(index, 1);
        }
      })
    );
  }, []);

  useEffect(
    function hideContent() {
      const hideAllRemoveListener = contentManagerEventDispatcher.addEventListener("hideAll", hide);
      const hideTargetRemoveListener = contentManagerEventDispatcher.addEventListener(
        target === ContentManagerTargetType.POPUP ? "hidePopup" : "hidePanel",
        hide
      );

      return () => {
        hideAllRemoveListener();
        hideTargetRemoveListener();
      };
    },
    [target, hide]
  );

  return { components, hide, selectedComponent };
}
