import sportAliases from "constants/sportAliases";
import memoize from "lodash/memoize";
import sortBy from "lodash/sortBy";

const CUSTOM_CORRECT_SCORE_LOGIC = [
  sportAliases.SOCCER,
  sportAliases.TENNIS,
  sportAliases.ICE_HOCKEY,
  sportAliases.BASEBALL,
  sportAliases.TABLE_TENNIS,
  sportAliases.SNOOKER
];
const MARKETS_PREDIVIDED_BY_COLUMNS = [
  "MatchWinningMargin",
  "SetWinningMargin",
  "WinningMargin",
  "CorrectScore",
  "Firstset/match",
  "SetsEffectiveness",
  "SeriesCorrectScore",
  "SeriesCorrectScoreAfterGame3",
  "SeriesCorrectScoreAfterGame4",
  "CurrectScoreGroup",
  "MatchBettingAndTeamsToScore",
  "HalfTimeFullTime",
  "HalfTimeFullTimeDoubleChance",
  "ExtraTimeHomeTeamCorrectTotal",
  "ExtraTimeAwayTeamCorrectTotal",
  "OutcomeandBothTeamToScore",
  "DoubleChanceAndBothTeamToScore",
  "DoubleChanceAndBothTeamToScore",
  "TotalAndBothTeamsToScore",
  "FirstHalfOutcomeAndBothTeamToScore",
  "SecondHalfOutcomeAndBothTeamToScore",
  "1stHalf-2ndHalfBothToScore",
  "GameCorrectScore",
  "MatchTieBreakCorrectScore",
  "SetTieBreakCorrectScore",
  "1stSet-Match",
  "1stGame/2ndGameWinner",
  "2ndGame/3thGameWinner",
  "3thGame/4thGameWinner",
  "4thGame/5thGameWinner",
  "5thGame/6thGameWinner",
  "6thGame/7thGameWinner",
  "7thGame/8thGameWinner",
  "8thGame/9thGameWinner",
  "9thGame/10thGameWinner",
  "10thGame/11thGameWinner",
  "11thGame/12thGameWinner",
  "SetScore",
  "SetCorrectScore",
  "5thSetCorrectScore",
  "MatchTieBreakCorrectScore",
  "OutcomeAndTotal15",
  "OutcomeAndTotal25",
  "OutcomeAndTotal35",
  "OutcomeAndTotal45"
];
const CORRECT_SCORE = "CORRECT SCORE";

const LEFT_COL_KEY = "left";
const MIDDLE_COL_KEY = "middle";
const RIGHT_COL_KEY = "right";

interface IPropTypes {
  sportAlias: string;
  marketEvents: GameDetailsMarketEvent[];
  marketType: string;
  displayKey: string;
  colCount: number;
}

export default function sortMarketEvents({ sportAlias, displayKey, marketType, marketEvents, colCount }: IPropTypes) {
  if (displayKey === CORRECT_SCORE && CUSTOM_CORRECT_SCORE_LOGIC.includes(sportAlias)) {
    return customSort(marketEvents, colCount);
  }

  if (MARKETS_PREDIVIDED_BY_COLUMNS.includes(marketType)) {
    return preDividedSort(marketEvents, colCount);
  }

  return sortBy(marketEvents, ["marketOrder", "order"]);
}

function customSort(marketEvents: GameDetailsMarketEvent[], colCount: number) {
  const leftColumn = [],
    middleColumn = [],
    rightColumn = [];

  for (const event of marketEvents) {
    if (event.home_value > event.away_value) {
      leftColumn.push(event);
    } else if (event.home_value < event.away_value) {
      rightColumn.push(event);
    } else {
      middleColumn.push(event);
    }
  }

  return mergeColumns(
    [
      sortBy(leftColumn, ["home_value", "away_value"]),
      sortBy(middleColumn, "home_value"),
      sortBy(rightColumn, ["away_value", "home_value"])
    ],
    colCount
  );
}

function preDividedSort(marketEvents: GameDetailsMarketEvent[], colCount: number) {
  const leftColumn = [],
    middleColumn = [],
    rightColumn = [];

  if (colCount === 2) {
    for (const event of marketEvents) {
      if (event.display_column === 1) {
        leftColumn.push(event);
      } else {
        rightColumn.push(event);
      }
    }
  } else {
    for (const event of marketEvents) {
      switch (event.display_column) {
        case 1:
          leftColumn.push(event);
          break;
        case 2:
          middleColumn.push(event);
          break;
        case 3:
          rightColumn.push(event);
          break;
      }
    }
  }

  return mergeColumns([sortBy(leftColumn, "order"), sortBy(middleColumn, "order"), sortBy(rightColumn, "order")], colCount);
}

function mergeColumns(dividedMarketEvents: GameDetailsMarketEvent[][], colCount: number) {
  const [leftColumn, middleColumn, rightColumn] = dividedMarketEvents;
  const events: MarketEventOrEmpty[] = [];

  const length = Math.max(leftColumn.length, middleColumn.length, rightColumn.length);
  let index = -1;

  if (colCount === 3) {
    while (++index < length) {
      events.push(
        leftColumn[index] || generateEmptyEvent(LEFT_COL_KEY + index),
        middleColumn[index] || generateEmptyEvent(MIDDLE_COL_KEY + index),
        rightColumn[index] || generateEmptyEvent(RIGHT_COL_KEY + index)
      );
    }
  } else {
    while (++index < length) {
      events.push(
        leftColumn[index] || generateEmptyEvent(LEFT_COL_KEY + index),
        rightColumn[index] || generateEmptyEvent(RIGHT_COL_KEY + index)
      );
    }
  }

  return events;
}

const generateUniqueId = (str: string) => {
  let hash = 0;

  for (let i = 0, l = str.length; i < l; i++) {
    hash -= str.charCodeAt(i) * (i + 1);
  }

  return hash;
};

// We need a hashing algorithm so that the id of an empty market always is the same based on its position
const generateEmptyEvent = memoize<(key: string) => EmptyMarketEvent>(key => ({ id: generateUniqueId(key) }));
