import useLogout from "hooks/useLogout";
import React from "react";

interface IPropTypes {
  children: (f: (event: React.MouseEvent) => void) => React.ReactElement;
}

const Logout = ({ children }: IPropTypes) => {
  const { logoutUser } = useLogout();
  return <>{children(logoutUser)}</>;
};

export default Logout;
