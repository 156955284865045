import { TranslationContext } from "providers/TranslationProvider";
import React, { memo, useContext, useRef } from "react";
import "./InputTypePassword.scss";
import { isEmpty } from "helpers/utils";
import { useToggle } from "hooks/useToggle";

const InputTypePassword: React.FunctionComponent<IFormControl> = ({
  error,
  touched,
  focused,
  label = "",
  customAttrs,
  className,
  onChange,
  placeholder,
  value,
  ...props
}) => {
  const inputValue = !isEmpty(value) ? String(value) : "";
  const invalid = touched && error;
  const isValueEmpty = inputValue.trim().length === 0;
  const classNames: string[] = [];

  const trans = useContext(TranslationContext);

  const inputRef = useRef<HTMLInputElement>(null);
  const [passwordVisible, togglePasswordVisible] = useToggle(false);

  if (focused) {
    classNames.push("focused");
  }
  if (touched) {
    classNames.push(invalid ? "invalid" : "valid");
  }
  if (!isValueEmpty) {
    classNames.push("filled");
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      type,
      target: { name, value }
    } = event;
    onChange(type, name, value);
  };

  return (
    <div className={`form-control-bc default has-icon ${classNames.join(" ")}`}>
      {/*has-icon, focused, filled, valid, invalid*/}
      <label className="form-control-label-bc inputs">
        {!passwordVisible ? (
          <input
            ref={inputRef}
            type="password"
            className="form-control-input-bc"
            onChange={handleChange}
            onBlur={handleChange}
            onFocus={handleChange}
            autoComplete="current-password"
            value={inputValue}
            {...props}
          />
        ) : (
          <input
            ref={inputRef}
            type="text"
            className="form-control-input-bc"
            onChange={handleChange}
            onBlur={handleChange}
            onFocus={handleChange}
            autoComplete="current-password"
            value={inputValue}
            {...props}
          />
        )}

        <i className="form-control-input-stroke-bc" />
        <span className="form-control-title-bc">{trans(label)}</span>
        <span className="form-control-icon-holder-bc">
          {!isValueEmpty ? (
            <i className={`form-control-icon-bc ${passwordVisible ? "bc-i-eye-filled" : "bc-i-eye"}`} onClick={togglePasswordVisible} />
          ) : null}
        </span>
      </label>
      {invalid ? (
        <div className="form-control-message-holder-bc">
          <span className="form-control-message-bc">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default memo(InputTypePassword);
