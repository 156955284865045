import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";

import Storage from "services/storage";
import useFavoriteSportGames from "hooks/useFavoriteSportGames";

enum FavoritesKey {
  Games = "favorite_games",
  Competitions = "favorite_competitions",
  CasinoGames = "favorite_casino_games"
}

type FavoritesValue = Dictionary<boolean>;

interface IFavorites {
  [FavoritesKey.Games]: FavoritesValue;
  [FavoritesKey.Competitions]: FavoritesValue;
  [FavoritesKey.CasinoGames]: FavoritesValue;
}

interface IFavoritesContext {
  favoritesCount: number;
  toggleFavoriteSportGame: (gameId: number) => void;
  toggleFavoriteCompetition: (competitionId: number) => void;
  toggleFavoriteCasinoGame: (casinoGameId: number) => void;
  isSportGameFavorite: (gameId: number) => boolean;
  isCompetitionFavorite: (competitionId: number) => boolean;
  isCasinoGameFavorite: (casinoGameId: number) => boolean;
  sportGames: IFavoriteSportGame[];
}

const initialState = Object.values(FavoritesKey).reduce<IFavorites>((acc, storageKey) => {
  acc[storageKey] = Storage.getItem(storageKey) || {};
  return acc;
}, {} as IFavorites);

export const FavoritesContext = createContext<IFavoritesContext>({} as IFavoritesContext);

const toggleFavoriteItem = (favoriteItemEntity: FavoritesValue, itemId: number) => {
  let result: FavoritesValue = { ...favoriteItemEntity };
  if (itemId in favoriteItemEntity) {
    delete result[itemId];
  } else {
    result[itemId] = true;
  }
  return result;
};

const FavoritesProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = useState<IFavorites>(initialState);

  const { state: sportGames } = useFavoriteSportGames(state.favorite_games);

  useEffect(() => {
    const sportGamesFavoriteValue: FavoritesValue = sportGames.reduce((item, game) => ({ ...item, [game.gameId]: true }), {});
    Storage.setItem(FavoritesKey.Games, sportGamesFavoriteValue);
    setState(state => ({
      ...state,
      [FavoritesKey.Games]: sportGamesFavoriteValue
    }));
  }, [sportGames]);

  useEffect(() => {
    Storage.setItem(FavoritesKey.Competitions, state.favorite_competitions);
  }, [state.favorite_competitions]);

  useEffect(() => {
    Storage.setItem(FavoritesKey.CasinoGames, state.favorite_casino_games);
  }, [state.favorite_casino_games]);

  const toggleFavoriteSportGame = useCallback((gameId: number) => {
    setState(state => ({
      ...state,
      [FavoritesKey.Games]: toggleFavoriteItem(state[FavoritesKey.Games], gameId)
    }));
  }, []);

  const toggleFavoriteCompetition = useCallback((competitionId: number) => {
    setState(state => ({
      ...state,
      [FavoritesKey.Competitions]: toggleFavoriteItem(state[FavoritesKey.Competitions], competitionId)
    }));
  }, []);

  const toggleFavoriteCasinoGame = useCallback((casinoGameId: number) => {
    setState(state => ({
      ...state,
      [FavoritesKey.CasinoGames]: toggleFavoriteItem(state[FavoritesKey.CasinoGames], casinoGameId)
    }));
  }, []);

  const isSportGameFavorite = useCallback((gameId: number) => state.favorite_games[gameId], [state.favorite_games]);

  const isCompetitionFavorite = useCallback((competitionId: number) => state.favorite_competitions[competitionId], [
    state.favorite_competitions
  ]);

  const isCasinoGameFavorite = useCallback((casinoGameId: number) => state.favorite_casino_games[casinoGameId], [
    state.favorite_casino_games
  ]);

  const favoritesCount = useMemo(() => {
    return Object.keys(state.favorite_games).length + Object.keys(state.favorite_casino_games).length;
  }, [state.favorite_games, state.favorite_casino_games]);

  return (
    <FavoritesContext.Provider
      value={{
        toggleFavoriteSportGame,
        toggleFavoriteCompetition,
        toggleFavoriteCasinoGame,
        isSportGameFavorite,
        isCompetitionFavorite,
        isCasinoGameFavorite,
        favoritesCount,
        sportGames
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export default FavoritesProvider;
