const EventType = {
  LANGUAGE_SELECTED: "LANGUAGE_SELECTED",
  ADD_ROOT_CLASS_NAME: "ADD_ROOT_CLASS_NAME",
  REMOVE_ROOT_CLASS_NAME: "REMOVE_ROOT_CLASS_NAME",
  OPEN_NAVIGATION_MENU: "OPEN_NAVIGATION_MENU",
  CLOSE_NAVIGATION_MENU: "CLOSE_NAVIGATION_MENU",
  OPEN_HELP_PAGE: "OPEN_HELP_PAGE",
  OPEN_USER_INFO_PANEL: "OPEN_USER_INFO_PANEL",
  CLOSE_USER_INFO_PANEL: "CLOSE_USER_INFO_PANEL",
  SET_NOTIFICATIONS_COUNT: "SET_NOTIFICATIONS_COUNT",
  OPEN_BETSLIP: "OPEN_BETSLIP",
  CLOSE_BETSLIP: "CLOSE_BETSLIP",
  OPEN_MESSAGES: "OPEN_MESSAGES"
};

export default EventType;
