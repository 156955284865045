/**
 * This Hook allowing you to, according to the React docs, “return a memoized version
 * of the callback that only changes if one of the inputs has changed
 */

import { useCallback, useState } from "react";

export const useToggle = (initial: boolean): [boolean, VoidFunction] => {
  const [open, setOpen] = useState(initial);

  return [open, useCallback(() => setOpen(status => !status), [])];
};

/**
 * Simple usage inside react component
 *
 * const [open, toggle] = useToggle(false);
 *
 * {open && <SomeComponent />}
 * <Button onClick={toggle}>toggle something</Button>
 */
