import React, { useEffect, useState } from "react";

import "App.scss";

import Config from "configs";
import EventType from "./constants/eventType";
import LanguageCodes from "./constants/languageCodes";
import bootstrap from "./helpers/bootstrap";
import Statistics from "./helpers/Statistics";
import { RootEventDispatcher } from "./helpers/EventDispatcher";
import Loader from "dev/components/loader/Loader";

interface IPropTypes {
  children: (s: string) => React.ReactElement;
}

function App({ children }: IPropTypes): React.ReactElement {
  const [language, setLanguage] = useState<string>("");

  useEffect(() => {
    const statistics = Statistics.getInstance();

    bootstrap().then((lang: string) => {
      setLanguage(lang);
      statistics.init(LanguageCodes[lang], Config.app.statsHost);
    });

    const rootElement = document.querySelector("#root");
    RootEventDispatcher.addEventListener(EventType.ADD_ROOT_CLASS_NAME, className => rootElement && rootElement.classList.add(className));
    RootEventDispatcher.addEventListener(
      EventType.REMOVE_ROOT_CLASS_NAME,
      className => rootElement && rootElement.classList.remove(className)
    );

    return () => {
      RootEventDispatcher.removeEventListeners(EventType.ADD_ROOT_CLASS_NAME);
      RootEventDispatcher.removeEventListeners(EventType.REMOVE_ROOT_CLASS_NAME);
    };
  }, []);

  if (!language) {
    return <Loader />;
  }

  return children(language);
}

export default App;
