import times from "lodash/times";
import memoize from "lodash/memoize";

import Config from "configs";
import { BetType, BetTypeConfig, eventCountBetTypesMap } from "constants/betslip";

const getBetTypeName = (optionsCount: number) => {
  const BET_TYPES_NAMES: Dictionary<string> = {
    1: "Singles",
    2: "Doubles",
    3: "Trebles"
  };

  return BET_TYPES_NAMES[optionsCount] || `${optionsCount} folds`;
};

/**
 * If `includeSystemTypes` is `true` the function returns only currently available types.
 * If not - it returns all types enabled from Config and sets `disabled` to true on the ones that are currently unavailable.
 * Also, note that `modifyNames` is valid only when `includeSystemTypes` is also `true`.
 */
export const generateAvailableBetTypesConfig = memoize(
  (eventsCount: number, includeSystemTypes: boolean, modifyNames: boolean) => {
    const config = Config.sportsbook.availableBetTypes as BetTypeConfig[];
    const betTypesRestrictedByEventsCount = eventCountBetTypesMap[eventsCount] || eventCountBetTypesMap.default;

    if (includeSystemTypes) {
      return config.reduce<BetTypeConfig[]>((typesAcc, betTypeConfig) => {
        const { name, value: type } = betTypeConfig;

        if (betTypesRestrictedByEventsCount.includes(type)) {
          switch (type) {
            case BetType.Multiple:
              typesAcc.push({
                name: modifyNames ? getBetTypeName(eventsCount) : name,
                value: type
              });
              break;
            case BetType.System:
              times(eventsCount - 2, i => {
                const sysCount = i + 2;
                return typesAcc.push({
                  name: modifyNames ? getBetTypeName(sysCount) : name,
                  value: type,
                  sysCount
                });
              });
              break;
            default:
              typesAcc.push(betTypeConfig);
          }
        }

        return typesAcc;
      }, []);
    }

    return config.map(type => ({
      ...type,
      disabled: !betTypesRestrictedByEventsCount.includes(type.value)
    }));
  },
  (...args) => JSON.stringify(args)
);
