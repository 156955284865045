import React, { memo } from "react";
import { Link } from "react-router-dom";
import { RootEventDispatcher } from "./EventDispatcher";

interface IPropTypes {
  to?: string;
  target?: string;
  actions?: IConfigAction[];
  className?: string;
}

const SmartLink: React.FunctionComponent<React.PropsWithChildren<IPropTypes>> = ({ children, to, target, className, actions = [] }) => {
  const handleActions = () => {
    if (actions.length > 0) {
      actions.forEach(action => {
        RootEventDispatcher.dispatchEvent(action.type, action.data);
      });
    }
  };

  if (to) {
    return !/^https?:\/\//.test(to) ? (
      <Link onClick={handleActions} to={to} target={target} className={className}>
        {children}
      </Link>
    ) : (
      <a href={to} target={target} className={className}>
        {children}
      </a>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a onClick={handleActions} className={className}>
      {children}
    </a>
  );
};

export default memo(SmartLink);
