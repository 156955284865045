import React, { useCallback, useEffect, useRef, useState } from "react";

import Swarm from "connection/Swarm";
import AuthData from "services/authData";
import { toFixed } from "helpers/math";
import { CURRENCY_DEFAULT_ROUNDING } from "constants/currency";

interface IUserContextDispatch {
  setLoginProgress: (payload: any) => any;
  login: (userName: string, password: string, remember?: boolean, additionalParams?: Dictionary<any>) => Promise<ISwarmAuthData>;
  restoreLogin: (authData: ISwarmAuthData) => void;
  unsubscribeFromProfile: VoidFunction;
  setCurrencyRounding: CallbackFunction;
}

const initialState: IUser = {
  loginInProgress: false,
  isLoggedIn: false,
  profile: {} as IUserProfile
};

export const UserContextState = React.createContext<IUser>(initialState);
export const UserContextDispatch = React.createContext<IUserContextDispatch>({} as IUserContextDispatch);

export const UserProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = useState<IUser>({
    ...initialState,
    loginInProgress: !!AuthData.get()
  });
  const profileSubId = useRef("");
  const currencyRoundingRef = useRef(CURRENCY_DEFAULT_ROUNDING);

  const setLoginProgress = useCallback((loginInProgress: boolean) => setState(state => ({ ...state, loginInProgress })), []);

  const setCurrencyRounding = useCallback(currencyRounding => {
    currencyRoundingRef.current = currencyRounding;
    setState(state => ({
      ...state,
      profile: processProfileUpdateData(state.profile)
    }));
  }, []);

  const processProfileUpdateData = (updatedProfile: IUserProfile) => {
    let calculatedBalance = !updatedProfile.frozen_balance
      ? updatedProfile.balance
      : Math.max(updatedProfile.balance - updatedProfile.frozen_balance, 0);

    let calculatedBonus = updatedProfile.bonus_balance || 0; // in some cases profile.bonus_balance is undefined

    if (updatedProfile.bonus_win_balance !== undefined) {
      calculatedBonus += updatedProfile.bonus_win_balance;
    }

    if (updatedProfile.frozen_balance !== undefined) {
      calculatedBonus += updatedProfile.frozen_balance;
    }

    const calculatedBalanceRounded = toFixed(calculatedBalance, currencyRoundingRef.current);
    const calculatedBonusRounded = toFixed(calculatedBonus, currencyRoundingRef.current);
    const casinoBalanceRounded =
      updatedProfile.casino_balance !== null ? toFixed(updatedProfile.casino_balance, currencyRoundingRef.current) : null;

    return {
      ...updatedProfile,
      calculatedBalance,
      calculatedBalanceRounded,
      calculatedBonus,
      calculatedBonusRounded,
      casinoBalanceRounded
    };
  };

  const subscribeToProfile = useCallback(() => {
    profileSubId.current = Swarm.subscribe(
      { source: "user", what: { profile: [] } },
      (data: { profile: Dictionary<ISwarmUserProfile> }) => {
        const updatedProfile = Object.values(data.profile)[0];

        if (updatedProfile) {
          setState(state => {
            const processedProfile = processProfileUpdateData(updatedProfile as IUserProfile);

            return {
              ...state,
              profile: processedProfile,
              loginInProgress: false,
              isLoggedIn: true
            };
          });
        }
      },
      () => setLoginProgress(false)
    );
  }, [setLoginProgress]);

  const login = useCallback(
    (username, password, remember = false, additionalParams = {}) => {
      setLoginProgress(true);

      return Swarm.login({ username, password }, additionalParams)
        .then(userData => {
          //check two factor authentication case, email verification case and in that case return some error code instead profile subscription: see SDC-40709
          AuthData.set(userData, remember);
          subscribeToProfile();

          return userData;
        })
        .finally(() => setLoginProgress(false));
    },
    [setLoginProgress, subscribeToProfile]
  );

  const restoreLogin = useCallback(
    (authData: ISwarmAuthData) => {
      setLoginProgress(true);
      Swarm.restoreLogin(authData)
        .then(userData => {
          AuthData.set(userData);
          subscribeToProfile();
        })
        .catch(() => setLoginProgress(false));
    },
    [setLoginProgress, subscribeToProfile]
  );

  const unsubscribeFromProfile = useCallback(() => {
    // No need to unsubscribe from profile as SWARM does that automatically
    if (profileSubId.current) {
      Swarm.unsubscribe(profileSubId.current);
      setState(state => ({ ...state, profile: {} as IUserProfile, loginInProgress: false, isLoggedIn: false }));
    }
  }, []);

  useEffect(() => {
    if (state.loginInProgress) {
      const authData = AuthData.get();
      if (authData) {
        restoreLogin(authData);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <UserContextDispatch.Provider value={{ setLoginProgress, login, restoreLogin, unsubscribeFromProfile, setCurrencyRounding }}>
      <UserContextState.Provider value={state}>{children}</UserContextState.Provider>
    </UserContextDispatch.Provider>
  );
};
