/**
 * Application bootstrap
 * */
import axios from "axios";

import Config from "configs";
import Swarm, { SessionSource } from "connection/Swarm";
import GeoData from "constants/geoData";
import runtimeConstants from "constants/runtimeConstants";
import Log from "helpers/Log";
import { getTranslation } from "providers/TranslationProvider";
import AuthData from "services/authData";
import Storage from "services/storage";
import { getCurrentTimeRoundedByMinutes, getQueryParams, mergeRecursive } from "./utils";

/**
 * loads runtime configuration and merge it with app's global config*
 */
const getConfig = async () => {
  return new Promise(resolve => {
    axios
      .get(`/conf.json?v=${getCurrentTimeRoundedByMinutes(5)}`)
      .then(response => {
        if (response.data) {
          mergeRecursive(Config, response.data);
        }
      })
      .catch(error => {
        Log.warning(`failed to load external config, error: ${error} , processing will be done using locale config`);
      })
      .finally(resolve);
  });
};

/**
 * loads the payment configuration from CMS: instead, the CMS API should add it to the skin configuration
 */
const getPaymentConfig = async () => {
  return new Promise(resolve => {
    axios
      .get(`https://cmsbetconstruct.com/json/getPayments?skin_id=${Config.app.site_id}`)
      .then(response => {
        if (response.status === 200) {
          Config.payment.methods = response.data.payments;
        }
      })
      .catch(error => {
        Log.warning(`failed to load external payment configuration, error: ${error} , processing will be done using locale config`);
      })
      .finally(resolve);
  });
};

/**
 * using geoURL config's value, gets geo information from geo API and merge it with default values
 */
const getGeoData = async () => {
  return new Promise(resolve => {
    axios
      .get(Config.app.geoUrl, { timeout: 2000 })
      .then(response => {
        if (response.data) {
          Object.assign(GeoData, response.data);
        }
        resolve();
      })
      .catch(error => {
        Log.warning(`failed to load geoData, error: ${error} , processing without geoData`);
        resolve();
      });
  });
};

const checkLangAvailability = (lang: string) => (Config.app.availableLanguages[lang] ? lang : "");

const getLangFromUrl = () => {
  const { lang } = getQueryParams();
  return checkLangAvailability(lang);
};

const getLangFromStorage = () => {
  const lang = Storage.getItem("language");
  if (lang) {
    return checkLangAvailability(lang);
  }
  return "";
};

const getLangFromGeoData = () => {
  if (!Config.app.defaultLanguageByIP.enabled || !GeoData.countryCode) {
    return "";
  }
  return checkLangAvailability(Config.app.defaultLanguageByIP.regions[GeoData.countryCode]);
};

/**
 * The application will be ready after some initial preparation
 * At first should load runtime configuration and merge it with app's global config
 * After config's preparation using geoURL config's value, need to get geo information from geo API
 * After that need to determine the current language
 * Using a predefined language, need to get a translation
 *
 * After these steps the application is ready
 */
const bootstrap = async () => {
  try {
    await getConfig();
    await getPaymentConfig();
    await getGeoData();

    const language = getLangFromUrl() || getLangFromStorage() || getLangFromGeoData() || Config.app.preferences.language;

    //for two-step authentication need to add 'afec' param with fingerprint value
    Swarm.connect(Config.app.swarm.socketUrl, {
      language,
      site_id: Config.app.site_id,
      source: runtimeConstants.isMobile ? SessionSource.Mobile : SessionSource.Html5
    });

    await getTranslation(language);

    //handle location params and some other initial data
    const { AuthToken, UserId } = getQueryParams();
    if (AuthToken && AuthToken.toLowerCase() !== "anonymous") {
      AuthData.set({ auth_token: AuthToken, user_id: +UserId });
    }

    return language;
  } catch (e) {
    Log.warning(`failed to load external configuration: ${e.message}`);
  }
};

export default bootstrap;
