import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import smoothscroll from "smoothscroll-polyfill";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./sass/global.styles.scss";
import "./dev/sass/override.scss";

import Widget from "helpers/Widget";
import lazyLoadComponent from "helpers/lazyLoadComponent";
import { UserProvider } from "providers/UserProvider";
import NavigationProvider from "providers/NavigationProvider";
import TranslationProvider from "providers/TranslationProvider";
import PreferencesProvider from "providers/PreferencesProvider";
import PartnerProvider from "providers/PartnerProvider";
import CurrencyProvider from "providers/CurrencyProvider";
import VideoProvider from "providers/VideoProvider";
import FavoritesProvider from "providers/FavoritesProvider";
import BetslipProvider from "providers/BetslipProvider";
import App from "./App";
import ContentContainer from "dev/components/contentContainer/ContentContainer";
import Loader from "./skins/_global/common/components/loader/Loader";
import OddsConverterProvider from "./providers/OddsConverterProvider";
import OuterRelationHelper from "helpers/OuterRelationHelper";

declare global {
  interface Window {
    opera: any;
    grecaptcha: any;
    RTCPeerConnection: any;
    webkitRTCPeerConnection: any;
    mozRTCPeerConnection: any;
  }
}

smoothscroll.polyfill();

// if (process.env.NODE_ENV !== "production") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");
//   whyDidYouRender(React, { trackAllPureComponents: true });
// }

const BetOnLineupsComponent = lazyLoadComponent("pages/BetOnLineupsPage");

ReactDOM.render(
  <App>
    {lang => (
      <PreferencesProvider language={lang}>
        <UserProvider>
          <PartnerProvider>
            <CurrencyProvider>
              <BrowserRouter>
                <VideoProvider>
                  <FavoritesProvider>
                    <BetslipProvider>
                      <TranslationProvider>
                        <OddsConverterProvider>
                          <NavigationProvider>
                            <OuterRelationHelper>
                              <Switch>
                                <Route path="/widget" render={props => <Widget navigationEnabled={false} {...props} />} />
                                <Route path="/embed" render={props => <Widget navigationEnabled={true} {...props} />} />
                                <Route
                                  path="/iframe/bet-on-lineups/:pageName?/:competitionId?/:gameId?"
                                  render={() => {
                                    return (
                                      <Suspense fallback={<Loader size="medium" />}>
                                        {BetOnLineupsComponent ? <BetOnLineupsComponent iframe={true} /> : null}
                                      </Suspense>
                                    );
                                  }}
                                />
                                <Route component={ContentContainer} />
                              </Switch>
                            </OuterRelationHelper>
                          </NavigationProvider>
                        </OddsConverterProvider>
                      </TranslationProvider>
                    </BetslipProvider>
                  </FavoritesProvider>
                </VideoProvider>
              </BrowserRouter>
            </CurrencyProvider>
          </PartnerProvider>
        </UserProvider>
      </PreferencesProvider>
    )}
  </App>,
  document.getElementById("root")
);
