interface IComponentPaths {
  [key: string]: {
    button?: string;
    content?: string;
  };
}

const componentPaths: IComponentPaths = {
  LanguageSelector: {
    button: "components/LanguageSelector/SelectedLanguage",
    content: "components/LanguageSelector/AvailableLanguages"
  },
  ThemeSelector: {
    button: "components/ThemeSelector/ThemesButton",
    content: "components/ThemeSelector/ThemesContent"
  },
  AuthPopup: {
    content: "components/auth/AuthPopup"
  },
  CashoutPopup: {
    content: "components/cashout/Cashout"
  },
  AuthPanel: {
    content: "components/auth/AuthPanel"
  },
  Login: {
    content: "components/login/Login"
  },
  Registration: {
    content: "components/registration/Registration"
  },
  FirstLoginSuggestion: {
    content: "components/suggestions/firstLogin/FirstLoginSuggestion"
  },
  SimpleSlider: {
    content: "components/simpleSlider/SimpleSlider"
  },
  Help: {
    content: "pages/Help"
  },
  FeaturedGames: {
    content: "components/featuredGames"
  },
  UpcomingMatches: {
    content: "components/matches/UpcomingMatches"
  },
  PopularMatches: {
    content: "components/matches/PopularMatches"
  },
  PageBuilder: {
    content: "components/pageBuilder/PageBuilder"
  },
  Notifications: {
    content: "components/notifications",
    button: "components/notifications/NotificationsButton"
  },
  FavoriteGames: {
    content: "components/favoriteGames/FavoriteGamesContent",
    button: "components/favoriteGames/FavoriteGamesButton"
  },
  Messages: {
    button: "components/messages/MessagesButton"
  },
  Bonuses: {
    content: "components/bonuses/Bonuses",
    button: "components/bonuses/BonusesButton"
  },
  Settings: {
    content: "components/settings/Settings",
    button: "components/settings/SettingsButton"
  },
  BetOnLineups: {
    content: "pages/BetOnLineupsPage"
  }
};

export default componentPaths;
