import Storage, { prefix } from "./storage";
import runtimeConstants from "../constants/runtimeConstants";
import { IntegrationTypes } from "../constants/productTypes";

/** @constant {string} KEY - Storage key */
const KEY = "AUTH_DATA";
/** @constant {string} SESSION_STORAGE_KEY - sessionStorage key */
const SESSION_STORAGE_KEY = `${prefix}${KEY}`;
/** @constant {number} AUTH_SESSION_LIFETIME */
const AUTH_SESSION_LIFETIME = 864000; // 10 days in seconds
/** @constant {number} SESSION_STORAGE_LIFETIME - For emulating session storage */
const SESSION_STORAGE_LIFETIME = 3600; // 1 hour in seconds

let useSession = !!sessionStorage.getItem(SESSION_STORAGE_KEY);

const integrationMode = runtimeConstants.integrationType !== IntegrationTypes.ENTIRE;

let authData: ISwarmAuthData | null = null;

const set = (data: ISwarmAuthData, remember = false) => {
  if (integrationMode) {
    authData = data;
  } else {
    if (remember) {
      useSession = false;
      Storage.setItem(KEY, data, AUTH_SESSION_LIFETIME);
    } else {
      try {
        useSession = true;
        sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(data));
      } catch (e) {
        useSession = false;
        Storage.setItem(KEY, data, SESSION_STORAGE_LIFETIME);
      }
    }
  }
};

const get = (): ISwarmAuthData | null => {
  if (integrationMode) {
    return authData;
  } else {
    if (useSession) {
      const data = sessionStorage.getItem(SESSION_STORAGE_KEY);
      return data ? JSON.parse(data) : null;
    } else {
      return Storage.getItem(KEY);
    }
  }
};

const getAuthToken = () => {
  const data = get();
  return data ? data.auth_token : null;
};

/**
 * Clears auth data
 */
const clear = () => {
  if (integrationMode) {
    authData = null;
  } else {
    sessionStorage.removeItem(SESSION_STORAGE_KEY);
    Storage.removeItem(KEY);
  }
};

export default {
  set,
  get,
  getAuthToken,
  clear
};
