import React, { memo, useCallback, useContext, useEffect, useState } from "react";

import BasePopup from "./BasePopup";
import Button from "../button/Button";
import { TranslationContext } from "providers/TranslationProvider";
import PopupDispatcher from "./PopupDispatcher";

const ConfirmationButton: React.FunctionComponent<{ index: number; onClick: CallbackFunction }> = ({ index, onClick, children }) => {
  const handleButtonClick = () => onClick(index);

  return (
    <Button className="casino-popup-button" onClick={handleButtonClick}>
      {children}
    </Button>
  );
};

const initialData = {} as IPopupContent;

const Popup = () => {
  const [showBasePopup, setShowBasePopup] = useState(false);
  const [data, setData] = useState<IPopupContent>(initialData);

  const trans = useContext(TranslationContext);

  const { onClose = () => {}, type, title, content, buttons = [], hideCloseButton = false } = data;

  const closeBasePopup = useCallback(() => {
    setData(initialData);
    setShowBasePopup(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    return PopupDispatcher.addEventListener("open", data => {
      setData(data);
      setShowBasePopup(true);
    });
  }, []);

  useEffect(() => {
    return PopupDispatcher.addEventListener("close", closeBasePopup);
  }, [closeBasePopup]);

  if (!showBasePopup) {
    return null;
  }

  const handleButtonClick = (index: number) => {
    const callBackMethod = buttons[index].callBack;

    if (callBackMethod) {
      callBackMethod();
    }
    closeBasePopup();
  };

  return (
    <BasePopup type={type} onClose={closeBasePopup} hideCloseButton={hideCloseButton}>
      <div className="status-popup-content-w-bc">
        <div className="casino-popup-icon-wrapper">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/640px-React-icon.svg.png"
            className="casino-popup-icon"
            alt=""
          />
        </div>
        {title && <h3 className="casino-popup-title">{trans(title)}</h3>}
        {content && <p className="casino-popup-content" dangerouslySetInnerHTML={{ __html: trans(content) }} />}

        <div className="casino-popup-button-block">
          {buttons.length ? (
            buttons.map(({ label }, index) => (
              <ConfirmationButton key={index} index={index} onClick={handleButtonClick}>
                {trans(label)}
              </ConfirmationButton>
            ))
          ) : (
            <Button className="casino-popup-button" onClick={closeBasePopup}>
              {trans("Ok")}
            </Button>
          )}
        </div>
      </div>
    </BasePopup>
  );
};

export default memo(Popup);
