import React from "react";

interface IPropTypes {
  totalSteps: number;
  currentStep: number;
}

const StepsIndiecator: React.FunctionComponent<IPropTypes> = ({ totalSteps, currentStep }) => {
  return (
    <div className="steps-indicator">
      {new Array(totalSteps).fill(undefined).map((_, index) => (
        <div key={index} className={`step-indicator ${currentStep === index ? "step-indicator-active" : ""}`} />
      ))}
    </div>
  );
};

export default StepsIndiecator;
