import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";

import Swarm from "connection/Swarm";
import Storage from "services/storage";
import {
  BetslipContentType,
  BetslipEventData,
  BetslipEventPartial,
  BetslipEventsRelevantData,
  BetType,
  FreebetResponse,
  IBetslipState,
  ISelectedFreebetOption,
  PriceChangeMode,
  PriceType
} from "constants/betslip";
import { generateAvailableFreebetsRequest } from "helpers/gameInfo";
import {
  addEventToBetslip,
  BetslipDispatcher,
  broadcastUpdates,
  getInitialState,
  processEventUpdates,
  processSwarmUpdate,
  removeEventFromBetslip,
  setFreebetOptions,
  STORAGE_KEY,
  updateEventsDataMap
} from "./betslipHelpers";
import { UserContextState } from "providers/UserProvider";
import PopupDispatcher from "dev/components/popup/PopupDispatcher";

export const BetslipContextState = createContext<IBetslipState>({} as IBetslipState);

const BetslipProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = useState(getInitialState);

  const { profile, isLoggedIn } = useContext(UserContextState);

  const eventsRelevantData = useRef<BetslipEventsRelevantData>({});
  const subscriptions = useRef<Map<number, string>>(new Map());

  // Methods
  const subscribeToEvent = useCallback((eventId: number, marketId: number, gameId: number) => {
    const request = {
      source: "betting",
      what: {
        game: [
          "team1_name",
          "team2_name",
          "is_blocked",
          "is_live",
          "sport_alias",
          "region_alias",
          "express_min_len",
          "_parent_id", //parent_id - game competition,
          "start_ts"
        ],
        market: ["id", "name", "base", "express_id", "type", "extra_info"],
        event: ["id", "price", "base", "name", "type_1", "ew_allowed"]
      },
      where: {
        game: { id: gameId },
        market: { id: marketId },
        event: { id: eventId }
      }
    };

    return Swarm.subscribe(request, (data: { game: Dictionary<ISwarmGame> }) => {
      const updatedEvent = processEventUpdates(data.game, gameId, eventId);

      setState(state => processSwarmUpdate(state, eventId, updatedEvent));
    });
  }, []);

  const addSubscription = useCallback(
    (eventId: number, marketId: number, gameId: number) => {
      const unsubId = subscribeToEvent(eventId, marketId, gameId);
      subscriptions.current.set(eventId, unsubId);
    },
    [subscribeToEvent]
  );

  const addEvent = useCallback(
    (newEvent: BetslipEventPartial) => {
      setState(state => addEventToBetslip(state, newEvent));

      addSubscription(newEvent.id, newEvent.marketId, newEvent.gameId);
    },
    [addSubscription]
  );

  const removeSubscription = useCallback((eventId: number) => {
    const unsubId = subscriptions.current.get(eventId);
    if (unsubId) {
      Swarm.unsubscribe(unsubId);
      subscriptions.current.delete(eventId);
    }
  }, []);

  const removeEvent = useCallback(
    (id: number) => {
      setState(state => removeEventFromBetslip(state, id));

      removeSubscription(id);
    },
    [removeSubscription]
  );

  const removeAllEvents = useCallback(() => {
    state.events.forEach(event => removeEvent(event.id));
  }, [state.events, removeEvent]);

  const changeEventPriceType = useCallback((eventId: number, newPriceType: PriceType) => {
    setState(state => ({
      ...state,
      events: state.events.map(event => (event.id === eventId ? { ...event, priceType: newPriceType } : event))
    }));
  }, []);

  const toggleEvent = useCallback(
    (event: BetslipEventPartial) => {
      const { id, priceType } = event;
      const eventData = eventsRelevantData.current[id];
      if (!eventData) {
        addEvent(event);
      } else {
        if (eventData.priceType === priceType) {
          removeEvent(id);
        } else {
          changeEventPriceType(id, priceType);
        }
      }
    },
    [addEvent, changeEventPriceType, removeEvent]
  );

  const updateEventProp = useCallback(({ eventId, key, value }) => {
    setState(state => ({
      ...state,
      events: state.events.map(event => {
        if (event.id === eventId) {
          return {
            ...event,
            [key]: value
          };
        }
        return event;
      })
    }));
  }, []);

  const toggleExcludedSysEvent = useCallback((eventId: number) => {
    setState(state => {
      const newExcludedEvents = new Set(state.metadata.excludedSysEvents);
      if (newExcludedEvents.has(eventId)) {
        newExcludedEvents.delete(eventId);
      } else {
        newExcludedEvents.add(eventId);
      }
      return {
        ...state,
        metadata: {
          ...state.metadata,
          excludedSysEvents: newExcludedEvents
        }
      };
    });
  }, []);

  const toggleEachWay = useCallback(({ betType, index }: { betType: BetType; index: number }) => {
    setState(state => {
      const eventData = (state.eventsDataMap.get(betType) || [])[index];

      if (eventData) {
        const eachWayEnabled = !eventData.eachWay;

        const newStakeMultiplier = eachWayEnabled ? eventData.stakeMultiplier * 2 : eventData.stakeMultiplier / 2;

        const updatedEventData = {
          eachWay: eachWayEnabled,
          stakeMultiplier: newStakeMultiplier
        };

        const eventsDataMap = updateEventsDataMap(state.eventsDataMap, betType, index, updatedEventData);

        return { ...state, eventsDataMap };
      }

      return state;
    });
  }, []);

  const updateEventData = useCallback(
    ({
      betType,
      index,
      eventDataProp,
      value
    }: {
      betType: BetType;
      index: number;
      eventDataProp: keyof BetslipEventData;
      value: string | number | boolean;
    }) =>
      setState(state => ({
        ...state,
        eventsDataMap: updateEventsDataMap(state.eventsDataMap, betType, index, { [eventDataProp]: value })
      })),
    []
  );

  const setPriceChangeMode = useCallback((priceChangeMode: PriceChangeMode) => setState(state => ({ ...state, priceChangeMode })), []);

  const setSysOption = useCallback(
    (selectedSysOption: number) => setState(state => ({ ...state, metadata: { ...state.metadata, selectedSysOption } })),
    []
  );

  const setBetTypes = useCallback((types: BetType[]) => setState(state => ({ ...state, selectedBetTypes: types })), []);

  const setFreebetOption = useCallback(
    (selectedOption: ISelectedFreebetOption) =>
      setState(state => ({
        ...state,
        metadata: {
          ...state.metadata,
          freebet: {
            ...state.metadata.freebet,
            selectedOption
          }
        }
      })),
    []
  );

  const repeatStakeForSingleEvents = useCallback(
    () =>
      setState(state => {
        let eventsDataMap = state.eventsDataMap;
        const eventsData = eventsDataMap.get(BetType.Single);

        if (eventsData) {
          const [{ stake }] = eventsData;
          let i = eventsData.length;

          while (i > 0) {
            eventsDataMap = updateEventsDataMap(eventsDataMap, BetType.Single, i--, { stake });
          }

          return { ...state, eventsDataMap };
        }

        return state;
      }),
    []
  );

  const setBetslipContentType = useCallback(
    (contentType: BetslipContentType) => setState(state => ({ ...state, metadata: { ...state.metadata, contentType } })),
    []
  );

  // Effects
  useEffect(function onMount() {
    state.events.forEach(event => {
      addSubscription(event.id, event.marketId, event.gameId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    function updateEventsRelevantData() {
      eventsRelevantData.current = state.events.reduce<BetslipEventsRelevantData>((acc, event) => {
        acc[event.id] = { price: event.price, priceType: event.priceType, isLive: event.isLive };
        return acc;
      }, {});

      broadcastUpdates(eventsRelevantData.current);
    },
    [state.events]
  );

  useEffect(
    function setFreebetAvailability() {
      let effectCleanedUp = false;

      if (isLoggedIn && profile.has_free_bets) {
        // Changing state and resetting options until receive them from back-end
        setState(setFreebetOptions([]));

        if (state.events.length > 0) {
          const request = generateAvailableFreebetsRequest(eventsRelevantData.current);

          Swarm.get(request, "get_freebets_for_betslip_v2").then((response: FreebetResponse) => {
            if (!effectCleanedUp && response.StatusCode === "0") {
              setState(setFreebetOptions(response.Data));
            }
          });
        }
      } else {
        setState(setFreebetOptions([]));
      }

      return () => {
        effectCleanedUp = true;
      };
    },
    [state.events.length, isLoggedIn, profile.has_free_bets]
  );

  useEffect(
    function checkCounterOfferStatus() {
      if (profile.super_bet) {
        const latestCounterOfferId = Math.max(...Object.keys(profile.super_bet).map(Number));
        const { super_bet_status, super_bet_amount, super_bet_id, super_bet_price } = profile.super_bet[latestCounterOfferId];

        const popupContent: IPopupContent = { title: "Counter-offer" };

        switch (super_bet_status) {
          case 1:
            popupContent.type = "success";
            popupContent.content = "Your counter-offer request is accepted";
            break;
          case 0: {
            popupContent.type = "info";
            popupContent.content = `Do you wish to accept our counter-offer with the following conditions? Offered Odd: ${super_bet_price} Offered Amount: ${super_bet_amount}`;
            popupContent.hideCloseButton = true;

            const superBetAnswer = (accept: boolean) => Swarm.get({ bet_id: super_bet_id, accept }, "super_bet_answer");

            popupContent.buttons = [
              {
                label: "Accept",
                callBack() {
                  return superBetAnswer(true);
                }
              },
              {
                label: "Decline",
                callBack() {
                  return superBetAnswer(false);
                }
              }
            ];
            break;
          }
          default:
            popupContent.type = "error";
            popupContent.content = "Your counter-offer request is declined";
        }

        PopupDispatcher.dispatchEvent("open", popupContent);
      }
    },
    [profile.super_bet]
  );

  useEffect(
    function updateStorage() {
      const { metadata, eventsDataMap, ...rest } = state;
      Storage.setItem(STORAGE_KEY, rest);
    },
    [state]
  );

  useEffect(
    function broadcastBetslipCount() {
      BetslipDispatcher.dispatchEvent("countChanged", state.events.length);
    },
    [state.events.length]
  );

  useEffect(
    function resetBetslipContentType() {
      if (!isLoggedIn) {
        setBetslipContentType(BetslipContentType.Betslip);
      }
    },
    [isLoggedIn, setBetslipContentType]
  );

  // Event listeners
  useEffect(() => BetslipDispatcher.addEventListener("mounted", () => broadcastUpdates(eventsRelevantData.current)), []);
  useEffect(() => BetslipDispatcher.addEventListener("toggle", toggleEvent), [toggleEvent]);
  useEffect(() => BetslipDispatcher.addEventListener("remove", removeEvent), [removeEvent]);
  useEffect(() => BetslipDispatcher.addEventListener("updateEvent", updateEventProp), [updateEventProp]);
  useEffect(() => BetslipDispatcher.addEventListener("removeAll", removeAllEvents), [removeAllEvents]);
  useEffect(() => BetslipDispatcher.addEventListener("toggleExcluded", toggleExcludedSysEvent), [toggleExcludedSysEvent]);
  useEffect(() => BetslipDispatcher.addEventListener("toggleEachWay", toggleEachWay), [toggleEachWay]);
  useEffect(() => BetslipDispatcher.addEventListener("updateEventData", updateEventData), [updateEventData]);
  useEffect(() => BetslipDispatcher.addEventListener("setPriceChangeMode", setPriceChangeMode), [setPriceChangeMode]);
  useEffect(() => BetslipDispatcher.addEventListener("setSysOption", setSysOption), [setSysOption]);
  useEffect(() => BetslipDispatcher.addEventListener("setType", setBetTypes), [setBetTypes]);
  useEffect(() => BetslipDispatcher.addEventListener("setFreebetOption", setFreebetOption), [setFreebetOption]);
  useEffect(() => BetslipDispatcher.addEventListener("repeatStakeForSingleEvents", repeatStakeForSingleEvents), [
    repeatStakeForSingleEvents
  ]);
  useEffect(() => BetslipDispatcher.addEventListener("setContentType", setBetslipContentType), [setBetslipContentType]);

  return <BetslipContextState.Provider value={state}>{children}</BetslipContextState.Provider>;
};

export default BetslipProvider;
