import React, { memo, useState } from "react";

function withPanelContent<T>(Component: React.ComponentType<T>) {
  return memo(function(props: any) {
    const { show, title = "", panelContentTypes, defaultPanelContentType } = props;

    const [activeContentType, setActiveContentType] = useState(
      defaultPanelContentType || (panelContentTypes && panelContentTypes[0].contentType)
    );

    const handleChangeActiveContentType = (contentType: string) => () => {
      setActiveContentType(contentType);
    };

    const headingHasIcon = false;

    if (panelContentTypes) {
      return (
        <div className="sp-dy-section-bc" style={{ display: show ? "block" : "none" }}>
          <div className={`sp-dy-section-head-bc tabs ${headingHasIcon ? "has-icon" : "default"}`}>
            <div className="sp-dy-section-head-content-bc">
              {panelContentTypes.map((panelContentType: Dictionary<string>, index: number) => (
                <div className="sp-dy-section-head-ct-item-bc" key={index}>
                  <span
                    onClick={handleChangeActiveContentType(panelContentType.contentType)}
                    className={`sp-dy-section-head-ct-title-bc ${activeContentType === panelContentType.contentType ? "active" : ""}`}
                  >
                    {panelContentType.title}
                  </span>
                </div>
              ))}
            </div>
            {headingHasIcon ? (
              <div className="sp-dy-section-head-icon-holder-bc">
                <i className="sp-dy-section-head-icon-bc bc-i-close-remove" />
              </div>
            ) : null}
          </div>
          <div className="sp-dy-section-body-bc">
            <Component {...props} panelContentType={activeContentType} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="sp-dy-section-bc" style={{ display: show ? "block" : "none" }}>
          <div className={`sp-dy-section-head-bc ${headingHasIcon ? "has-icon" : "default"}`}>
            <div className="sp-dy-section-head-content-bc">
              <div className="sp-dy-section-head-ct-item-bc">
                <span className={`sp-dy-section-head-ct-title-bc`}>{title}</span>
              </div>
            </div>
            {headingHasIcon ? (
              <div className="sp-dy-section-head-icon-holder-bc">
                <i className="sp-dy-section-head-icon-bc bc-i-close-remove" />
              </div>
            ) : null}
          </div>
          <div className="sp-dy-section-body-bc">
            <Component {...props} />
          </div>
        </div>
      );
    }
  });
}

export default withPanelContent;
