import { TranslationContext } from "providers/TranslationProvider";
import React, { memo, useContext } from "react";
import "./InputTypeEmail.scss";

const InputTypeEmail: React.FunctionComponent<IFormControl> = ({
  error,
  touched,
  focused,
  label = "",
  customAttrs,
  className,
  onChange,
  placeholder,
  value,
  ...props
}) => {
  const invalid = touched && error;
  const classNames: string[] = [];
  const inputValue = value as string;

  const trans = useContext(TranslationContext);

  if (focused) {
    classNames.push("focused");
  }
  if (touched) {
    classNames.push(invalid ? "invalid" : "valid");
  }
  if (inputValue.length > 0) {
    classNames.push("filled");
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      type,
      target: { name, value }
    } = event;
    onChange(type, name, value);
  };

  return (
    <div className={`form-control-bc default ${classNames.join(" ")}`}>
      {/*focused, filled, valid, invalid*/}
      <label className="form-control-label-bc inputs">
        <input
          type="email"
          className="form-control-input-bc"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          autoComplete="username"
          value={inputValue}
          {...props}
        />
        <i className="form-control-input-stroke-bc" />
        <span className="form-control-title-bc">{trans(label)}</span>
      </label>
      {invalid ? (
        <div className="form-control-message-holder-bc">
          <span className="form-control-message-bc">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default memo(InputTypeEmail);
