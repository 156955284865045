/**
 * Callback for Array.prototype.reduce that calculates sum of all items in the array
 * @param {number} acc
 * @param {number} curr
 * @returns {number}
 */
export const sum = (acc: number, curr: number): number => acc + curr;

export const multiply = (multiplier: number, multiplicand: number) => multiplier * multiplicand;

export const fixFloatError = (num: number) => parseFloat(num.toPrecision(12));

/**
 * Returns a new number whose value is the value of num rounded to a maximum of dp decimal
 * @example
 * x = toDecimalPlaces(9876.54321, 3); // 9876.543
 */
export const toDecimalPlaces = (num: number, dp: number) => {
  const k = Math.pow(10, dp);
  return Math.round(num * k) / k;
};

/**
 * Truncates number to given decimal places without rounding
 * @example
 * x = toFixed(10.356, 2); // 10.35
 */
export const toFixed = (num: number, dp: number) => {
  const k = Math.pow(10, dp);
  return Math.trunc(num * k) / k;
};

export const getDecimalPlacesCount = (num: number) => {
  const [, decimalPlaces] = num.toString().split(".");
  return decimalPlaces ? decimalPlaces.length : 0;
};

/**
 * Finds combinations of k from from given array
 */
export const findCombinations = (array: number[], k: number) => {
  const result: number[][] = [];
  const combinations: number[] = [];

  const run = (level: number, start: number) => {
    for (let i = start, l = array.length - k + level + 1; i < l; i++) {
      combinations[level] = array[i];

      if (level < k - 1) {
        run(level + 1, i + 1);
      } else {
        result.push([...combinations]);
      }
    }
  };

  run(0, 0);

  return result;
};

export const findCombinationsSum = (array: number[], k: number) => {
  let result = 0;
  const combinations: number[] = [];

  const run = (level: number, start: number) => {
    for (let i = start, l = array.length - k + level + 1; i < l; i++) {
      combinations[level] = array[i];

      if (level < k - 1) {
        run(level + 1, i + 1);
      } else {
        result += combinations.reduce(multiply, 1);
      }
    }
  };

  run(0, 0);

  return result;
};

export const bankersRound = (num: number, decimalPlaces: number = 0) => {
  const m = Math.pow(10, decimalPlaces);
  const n = +(decimalPlaces ? num * m : num).toFixed(8); // Avoid rounding errors
  const i = Math.floor(n),
    f = n - i;
  const e = 1e-8; // Allow for rounding errors in f
  const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n);
  return decimalPlaces ? r / m : r;
};

export const factorial = (n: number, acc: number = 1): number => (n <= 1 ? acc : factorial(n - 1, n * acc));
