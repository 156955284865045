import Swarm from "connection/Swarm";
import Log from "helpers/Log";
import React, { useContext, useEffect, useState } from "react";
import { CURRENCY_DEFAULT_ROUNDING } from "constants/currency";
import { PartnerContextState } from "providers/PartnerProvider";
import { UserContextState, UserContextDispatch } from "providers/UserProvider";

export const CurrencyContextState = React.createContext<ICurrency>({} as ICurrency);

const currencyConfig: Dictionary<ICurrency> = {};

const initialState: ICurrency = {
  id: 0,
  name: "",
  rate: 0,
  rounding: CURRENCY_DEFAULT_ROUNDING
};

const CurrencyProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = useState(initialState);
  const { loginInProgress, profile } = useContext(UserContextState);
  const { setCurrencyRounding } = useContext(UserContextDispatch);
  const { currency: partnerCurrency } = useContext(PartnerContextState);

  const currency = (profile && profile.currency) || partnerCurrency;

  useEffect(() => {
    if (currency && !loginInProgress) {
      if (!currencyConfig[currency]) {
        Swarm.get({
          source: "config.currency",
          what: {
            currency: []
          },
          where: {
            currency: {
              name: currency
            }
          }
        })
          .then(({ data }) => {
            const result: ICurrency = Object.values(data.currency)[0] as ICurrency;
            currencyConfig[currency] = result;
            setState(result);
            setCurrencyRounding(result.rounding);
          })
          .catch(error => {
            Log.error(`failed to get config currency: reason: ${error}`);
          });
      } else {
        setState(currencyConfig[currency]);
      }
    }
  }, [currency, loginInProgress, setCurrencyRounding]);

  return <CurrencyContextState.Provider value={state}>{children}</CurrencyContextState.Provider>;
};

export default CurrencyProvider;
