import axios, { AxiosResponse } from "axios";

import { LogoSize } from "constants/statistics";

export default class Statistics {
  public static readonly LogoSize = LogoSize;

  private static instance: Statistics;

  private static readonly krosstatsBaseUrl = "https://krosstats.betcoapps.com/api";
  private static readonly krosstatsUrlPrefix = "900/93f428d0-6591-48da-859d-b6c326db2448";

  private cache: Map<string, Promise<AxiosResponse>> = new Map();
  private host?: string;
  private krosstatsUrl?: string;

  private constructor() {}

  private loadData<T = any>(url: string, queryParams: Dictionary<any>, cacheEnabled: boolean) {
    if (cacheEnabled) {
      const cacheKey = url + JSON.stringify(queryParams);

      return (this.cache.has(cacheKey)
        ? this.cache.get(cacheKey)
        : this.cache
            .set(
              cacheKey,
              axios.get(url, { params: queryParams }).catch(reason => {
                this.cache.delete(cacheKey);
                throw reason;
              })
            )
            .get(cacheKey)) as Promise<AxiosResponse<T>>;
    }

    return axios.get<T>(url, { params: queryParams });
  }

  public init(language: string, host: string) {
    this.host = host;
    this.krosstatsUrl = `${Statistics.krosstatsBaseUrl}/${language}/${Statistics.krosstatsUrlPrefix}`;
  }

  public static getInstance() {
    if (!Statistics.instance) {
      Statistics.instance = new Statistics();
    }

    return Statistics.instance;
  }

  public setLanguage(language: string) {
    this.krosstatsUrl = `${Statistics.krosstatsBaseUrl}/${language}/${Statistics.krosstatsUrlPrefix}`;
  }

  public getTeamLogoUrl(teamId: number, size: LogoSize) {
    return `${this.host}/images/e/${size}/${Math.floor(teamId / 2000)}/${teamId}.png`;
  }

  public getCompetitionLogoUrl(competitionId: number, size: LogoSize) {
    return `${this.host}/images/c/${size}/0/${competitionId}.png`;
  }

  public getTeamKitUrl(teamId: number, awayColors: boolean = false) {
    return `${this.host}/images/sh/${Math.floor(teamId / 500)}/${awayColors ? 2 : 1}-${teamId}.png`;
  }

  public getTeamMembersList(teamId: number, date: number) {
    const [formattedDate] = new Date(date * 1000).toISOString().match(/.+?(?=T)/gm) || [];

    return formattedDate
      ? this.loadData<IStatsTeamMember[]>(
          `${this.krosstatsUrl}/Entity/GetTeamMembersByDateAndTeamWithNumber`,
          { teamId, date: formattedDate },
          true
        )
      : Promise.reject("Invalid date format");
  }

  public getPlayerStatistics(playerId: number, competitionId?: number) {
    return this.loadData<IStatsPlayer[]>(
      `${this.krosstatsUrl}/Entity/GetPlayerStatistics`,
      { entityId: playerId, ...(competitionId && { competitionId }) },
      true
    );
  }

  public getTeamsCoaches(team1Id: number, team2Id?: number) {
    return this.loadData<IStatsCoach[]>(
      `${this.krosstatsUrl}/Entity/GetTeamCoaches`,
      { "teamIds[0]": team1Id, ...(team2Id && { "teamIds[1]": team2Id }) },
      false
    );
  }
}
