import React, { useContext } from "react";
import { UserContextState } from "providers/UserProvider";
import Loader from "dev/components/loader/Loader";

const NotFound = () => {
  const { loginInProgress } = useContext(UserContextState);

  //in some cases, when a route requires a login state and a login in progress, then we should show the loader, not the notFound
  if (loginInProgress) {
    return <Loader />;
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 200
      }}
    >
      <span role="img" aria-label="Page not found">
        ☠
      </span>
    </div>
  );
};

export default NotFound;
