export enum ProductTypes {
  SPORTSBOOK = "sportsbook",
  CASINO = "casino"
}

export enum IntegrationTypes {
  IFRAME = "iframe",
  WIDGET = "widget",
  EMBED = "embed",
  ENTIRE = "entire"
}
