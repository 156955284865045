import EventDispatcher from "helpers/EventDispatcher";

interface EventMap {
  mountContainerPanel: any;
  unmountContainerPanel: { component: string };
  toggleComponentPanel: any;
  hidePanel: any;
  mountContainerPopup: any;
  unmountContainerPopup: { component: string };
  toggleComponentPopup: any;
  hidePopup: any;
  activeComponent: any;
  hideAll: any;
}

const contentManagerEventDispatcher = new EventDispatcher<EventMap>();

export default contentManagerEventDispatcher;
