import React, { Suspense } from "react";
import { Route, RouteProps } from "react-router-dom";
import Loader from "dev/components/loader/Loader";

interface IPropTypes extends RouteProps {
  component: React.ComponentType<any>;
  actions?: IConfigAction[];
}

const PublicRoute: React.FunctionComponent<IPropTypes> = ({ component: Component, path, actions, exact }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={() => (
        <Suspense fallback={<Loader size="small" />}>
          <Component actions={actions} />
        </Suspense>
      )}
    />
  );
};

export default React.memo(PublicRoute, () => true);
