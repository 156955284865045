import Config from "configs";
import GeoData from "constants/geoData";
import CMSBase from "./CMSBase";
const { countryCode } = GeoData;

class CMSLoader extends CMSBase {
  private widgets: Dictionary<any> = {};

  public getWidget<T>(id: string = "sidebar-1", language: string, isCacheable: boolean = true): Promise<T> {
    //const url = WP_URL + '?base_host=' + wpBaseHost +  addHttpsFlag(WP_URL) + '&lang=' + Config.env.lang + '&json=widgets/get_sidebar&sidebar_id=' + sidebarId + countryQuery;

    if (this.widgets[id]) {
      return Promise.resolve(this.widgets[id]);
    }

    const urlParams: Dictionary<string> = {};
    urlParams["base_host"] = "vivarosport";
    urlParams["ssl"] = "1";
    urlParams["lang"] = language;
    urlParams["json"] = "widgets/get_sidebar";
    urlParams["sidebar_id"] = id;
    urlParams["country"] = "AM";

    return this.loadData<T>("/json", urlParams).then(response => {
      if (isCacheable) {
        this.widgets[id] = response;
      }
      return response;
    });
  }

  //https://cmsbetconstruct.com/json?base_host=www.vivarobet.am&ssl=1&lang=eng&json=get_page&slug=live-dealer-eng&children=1&exclude=author,excerpt,comments,comment_status,comment_count,tags,attachments
  public getPage<T>(id: string, language: string, withChildren: boolean = true, isCachable: boolean = true): Promise<T> {
    if (this.widgets[id]) {
      return Promise.resolve(this.widgets[id]);
    }

    const urlParams: Dictionary<string> = {};
    urlParams["base_host"] = "www.vivarobet.am";
    urlParams["ssl"] = "1";
    urlParams["lang"] = language;
    urlParams["json"] = "get_page";
    urlParams["slug"] = id;
    if (withChildren) {
      urlParams["children"] = "1";
    }
    if (countryCode) {
      urlParams["country"] = countryCode;
    }
    urlParams["exclude"] = "author,excerpt,comments,comment_status,comment_count,tags,attachments";

    return this.loadData<T>("/json", urlParams).then(response => {
      if (isCachable) {
        this.widgets[id] = response;
      }
      return response;
    });
  }

  public loadPageOptions<T>(type: string): Promise<T> {
    const urlParams: Dictionary<string> = {
      partner_id: Config.app.site_id,
      type: type
    };
    return this.loadData<T>("/json/getPageOptions", urlParams);
  }
}

export default new CMSLoader();
