export default {
  AF: { name: "Afghanistan", code: "93", lang: "tur", city: "Kabul" },
  AX: { name: "Aland Islands", code: "358 18", city: "Mariehamn" },
  AL: { name: "Albania", code: "355", city: "Tirana", currency: "EUR" },
  DZ: { name: "Algeria", code: "213", city: "Algiers", lang: "fre" },
  AS: { name: "American Samoa", code: "1 684", city: "Apia", currency: "USD" },
  AD: { name: "Andorra", code: "376", city: "Andorra la Vella", lang: "fre", currency: "EUR" },
  AO: { name: "Angola", code: "244", city: "Luanda", lang: "por" },
  AI: { name: "Anguilla", code: "1 264", city: "The Valley" },
  AQ: { name: "Antarctica", code: "672" },
  AG: { name: "Antigua and Barbuda", code: "1 268", city: "Saint Johns" },
  AR: { name: "Argentina", code: "54", city: "Buenos Aires", lang: "spa", currency: "ARS" },
  AM: { name: "Armenia", code: "374", lang: "arm", currency: "AMD", city: "Yerevan" },
  AW: { name: "Aruba", code: "297", city: "Oranjestad", currency: "AWG" },
  AU: { name: "Australia", code: "61", city: "Canberra", currency: "AUD" },
  AT: { name: "Austria", code: "43", city: "Vienna", lang: "ger", currency: "EUR" },
  AZ: { name: "Azerbaijan", code: "994", city: "Baku", lang: "tur", currency: "AZN" },
  BS: { name: "Bahamas", code: "1 242", city: "Nassau", currency: "BSD" },
  BH: { name: "Bahrain", code: "973", city: "Manama" },
  BD: { name: "Bangladesh", code: "880", city: "Dhaka" },
  BB: { name: "Barbados", code: "1 246", city: "Bridgetown", currency: "BBD" },
  BY: { name: "Belarus", code: "375", city: "Minsk", lang: "rus" },
  BE: { name: "Belgium", code: "32", city: "Brussels", lang: "fre", currency: "EUR" },
  BZ: { name: "Belize", code: "501", city: "Belmopan" },
  BJ: { name: "Benin", code: "229", city: "Porto-Novo", lang: "fre" },
  BM: { name: "Bermuda", code: "1 441", city: "Hamilton", currency: "BMD" },
  BT: { name: "Bhutan", code: "975", city: "Thimphu" },
  BO: { name: "Bolivia", code: "591", city: "La Paz", lang: "spa" },
  BQ: { name: "Bonaire", code: "599 7", city: "Kralendijk", currency: "USD" },
  BA: { name: "Bosnia and Herzegovina", code: "387", city: "Sarajevo", currency: "EUR" },
  BW: { name: "Botswana", code: "267", city: "Gaborone" },
  BV: { name: "Bouvet Island", code: "47" },
  BR: { name: "Brazil", code: "55", city: "Brasilia", lang: "pt-br", currency: "BRL" },
  IO: { name: "British Indian Ocean Territory", code: "246", city: "Camp Justice" },
  BN: { name: "Brunei Darussalam", code: "673", city: "Bandar Seri Begawan" },
  BG: { name: "Bulgaria", code: "359", city: "Sofia", currency: "EUR" },
  BF: { name: "Burkina Faso", code: "226", city: "Ouagadougou", lang: "fre" },
  BI: { name: "Burundi", code: "257", city: "Bujumbura", lang: "fre" },
  CV: { name: "Cabo Verde", code: "238", city: "Praia", lang: "por" },
  KH: { name: "Cambodia", code: "855", city: "Phnom Penh" },
  CM: { name: "Cameroon", code: "237", city: "Yaounde" },
  CA: { name: "Canada", code: "1", city: "Ottawa", currency: "CAD" },
  KY: { name: "Cayman Islands", code: "1 345", city: "George Town" },
  CF: { name: "Central African Republic", code: "236", city: "Bangui" },
  TD: { name: "Chad", code: "235", city: "N`Djamena" },
  CL: { name: "Chile", code: "56", city: "Santiago", lang: "spa", currency: "CLP" },
  CN: { name: "China", code: "86", city: "Beijing", currency: "CNY" },
  CX: { name: "Christmas Island", code: "61 89164", city: "Flying Fish Cove" },
  CC: { name: "Cocos (Keeling) Islands", code: "61 89162", city: "West Island" },
  CO: { name: "Colombia", code: "57", city: "Bogota", lang: "spa", currency: "COP" },
  KM: { name: "Comoros", code: "269", city: "Moroni" },
  CG: { name: "Congo", code: "242", city: "Brazzaville" },
  CD: { name: "Congo, Democratic Republic of the (Zaire)", code: "243", city: "Kinshasa" },
  CK: { name: "Cook Islands", code: "682", city: "Avarua" },
  CR: { name: "Costa Rica", code: "506", city: "San José", lang: "spa" },
  CI: { name: "Cote d'Ivoire", code: "225", city: "Yamoussoukro" },
  HR: { name: "Croatia", code: "385", city: "Zagreb", currency: "EUR" },
  CU: { name: "Cuba", code: "53", city: "Havana", lang: "spa" },
  CW: { name: "Curacao", code: "599 9", city: "Willemstad" },
  CY: { name: "Cyprus", code: "357", city: "Nicosia", lang: "tur", currency: "EUR" },
  CZ: { name: "Czech Republic", code: "420", city: "Prague" },
  DK: { name: "Denmark", code: "45", city: "Copenhagen", currency: "DKK" },
  DJ: { name: "Djibouti", code: "253", city: "Djibouti" },
  DM: { name: "Dominica", code: "1 767", city: "Roseau" },
  DO: { name: "Dominican Republic", code: "1", city: "Santo Domingo", lang: "spa", currency: "DOP" },
  EC: { name: "Ecuador", code: "593", city: "Quito", currency: "USD" },
  EG: { name: "Egypt", code: "20", city: "Cairo" },
  SV: { name: "El Salvador", code: "503", city: "San Salvador", lang: "spa", currency: "SVC" },
  GQ: { name: "Equatorial Guinea", code: "240", city: "Malabo" },
  ER: { name: "Eritrea", code: "291", city: "Asmara" },
  EE: { name: "Estonia", code: "372", city: "Tallin", lang: "rus", currency: "EUR" },
  ET: { name: "Ethiopia", code: "251", city: "Asmara" },
  FK: { name: "Falkland Islands", code: "500" },
  FO: { name: "Faroe Islands", code: "298" },
  FJ: { name: "Fiji", code: "679" },
  FI: { name: "Finland", code: "358", currency: "EUR" },
  FR: { name: "France", code: "33", lang: "fre", currency: "EUR" },
  GF: { name: "French Guiana", code: "594", lang: "fre" },
  PF: { name: "French Polynesia", code: "689" },
  TF: { name: "French Southern Territories", code: "596" },
  GA: { name: "Gabon", code: "241" },
  GM: { name: "Gambia", code: "220" },
  GE: { name: "Georgia", code: "995", lang: "rus", currency: "GEL" },
  DE: { name: "Germany", code: "49", lang: "ger", currency: "EUR" },
  "GE-AB": { name: "Abkhazia", code: "7 840", lang: "abk" },
  GH: { name: "Ghana", code: "233", currency: "GHS" },
  GI: { name: "Gibraltar", code: "350" },
  GR: { name: "Greece", code: "30", lang: "gre", currency: "EUR" },
  GL: { name: "Greenland", code: "299" },
  GD: { name: "Grenada", code: "1 473" },
  GP: { name: "Guadeloupe", code: "590", lang: "fre", currency: "EUR" },
  GU: { name: "Guam", code: "1 671", currency: "USD" },
  GT: { name: "Guatemala", code: "502", lang: "spa", currency: "GTQ" },
  GG: { name: "Guernsey", code: "44 1481" },
  GN: { name: "Guinea", code: "224" },
  GW: { name: "Guinea-Bissau", code: "245" },
  GY: { name: "Guyana", code: "592" },
  HT: { name: "Haiti", code: "509" },
  HM: { name: "Heard Island and McDonald Islands", code: "672", currency: "AUD" },
  VA: { name: "Holy See", code: "379", currency: "EUR" },
  HN: { name: "Honduras", code: "504", lang: "spa", currency: "HNL" },
  HK: { name: "Hong Kong", code: "852" },
  HU: { name: "Hungary", code: "36" },
  IS: { name: "Iceland", code: "354", currency: "ISK" },
  IN: { name: "India", code: "91" },
  ID: { name: "Indonesia", code: "62" },
  IR: { name: "Iran", code: "98", currency: "IRR" },
  IQ: { name: "Iraq", code: "964", currency: "IQD" },
  IE: { name: "Ireland", code: "353", currency: "EUR" },
  IM: { name: "Isle of Man", code: "44 1624" },
  IL: { name: "Israel", code: "972", lang: "heb" },
  IT: { name: "Italy", code: "39", lang: "ita", currency: "EUR" },
  JM: { name: "Jamaica", code: "1 876" },
  JP: { name: "Japan", code: "81", currency: "JPY" },
  JE: { name: "Jersey", code: "44 1534" },
  JO: { name: "Jordan", code: "962" },
  KZ: { name: "Kazakhstan", code: "7", lang: "rus", currency: "KZT" },
  KE: { name: "Kenya", code: "254", currency: "KES" },
  KI: { name: "Kiribati", code: "686" },
  KP: { name: "Korea, North", code: "850" },
  KW: { name: "Kuwait", code: "965" },
  KG: { name: "Kyrgyzstan", code: "996", lang: "rus" },
  LA: { name: "Laos", code: "856" },
  LV: { name: "Latvia", code: "371", lang: "rus", currency: "EUR" },
  LB: { name: "Lebanon", code: "961" },
  LS: { name: "Lesotho", code: "266" },
  LR: { name: "Liberia", code: "231" },
  LY: { name: "Libya", code: "218", lang: "ita" },
  LI: { name: "Liechtenstein", code: "423", currency: "EUR" },
  LT: { name: "Lithuania", code: "370", lang: "rus", currency: "EUR" },
  LU: { name: "Luxembourg", code: "352", lang: "fre", currency: "EUR" },
  MO: { name: "Macao", code: "853" },
  MK: { name: "Macedonia", code: "389", currency: "EUR" },
  MG: { name: "Madagascar", code: "261" },
  MW: { name: "Malawi", code: "265" },
  MY: { name: "Malaysia", code: "60" },
  MV: { name: "Maldives", code: "960" },
  ML: { name: "Mali", code: "223" },
  MT: { name: "Malta", code: "356", lang: "eng", currency: "EUR" },
  MH: { name: "Marshall Islands", code: "692", currency: "USD" },
  MQ: { name: "Martinique", code: "596", lang: "fre", currency: "EUR" },
  MR: { name: "Mauritania", code: "222" },
  MU: { name: "Mauritius", code: "230" },
  YT: { name: "Mayotte", code: "262", currency: "EUR" },
  MX: { name: "Mexico", code: "52", lang: "spa", currency: "MXN" },
  FM: { name: "Micronesia, Federated States of", code: "691" },
  MD: { name: "Moldova", code: "373", lang: "rus" },
  MC: { name: "Monaco", code: "377", currency: "EUR" },
  MN: { name: "Mongolia", code: "976", lang: "rus" },
  ME: { name: "Montenegro", code: "382", currency: "EUR" },
  MS: { name: "Montserrat", code: "1 664" },
  MA: { name: "Morocco", code: "212" },
  MZ: { name: "Mozambique", code: "258" },
  MM: { name: "Myanmar", code: "95" },
  NA: { name: "Namibia", code: "264" },
  NR: { name: "Nauru", code: "674" },
  NP: { name: "Nepal", code: "977" },
  NL: { name: "Netherlands", code: "31", currency: "EUR" },
  NC: { name: "New Caledonia", code: "687", currency: "XPF" },
  NZ: { name: "New Zealand", code: "64", currency: "NZD" },
  NI: { name: "Nicaragua", code: "505", lang: "spa" },
  NE: { name: "Niger", code: "227" },
  NG: { name: "Nigeria", code: "234", currency: "NGN" },
  NU: { name: "Niue", code: "683", currency: "NZD" },
  NF: { name: "Norfolk Island", code: "672 3", currency: "AUD" },
  MP: { name: "Northern Mariana Islands", code: "1 670", currency: "USD" },
  NO: { name: "Norway", code: "47", currency: "NOK", lang: "nor" },
  OM: { name: "Oman", code: "968" },
  PK: { name: "Pakistan", code: "92" },
  PW: { name: "Palau", code: "680" },
  PS: { name: "Palestine, State of", code: "970" },
  PA: { name: "Panama", code: "507", lang: "spa" },
  PG: { name: "Papua New Guinea", code: "675" },
  PY: { name: "Paraguay", code: "595", lang: "spa", currency: "PYG" },
  PE: { name: "Peru", code: "51", lang: "spa", currency: "PEN" },
  PH: { name: "Philippines", code: "63" },
  PN: { name: "Pitcairn", code: "64" },
  PL: { name: "Poland", code: "48", lang: "pol", currency: "PLN" },
  PT: { name: "Portugal", code: "351", lang: "por", currency: "EUR" },
  PR: { name: "Puerto Rico", code: "1 787", currency: "USD" },
  QA: { name: "Qatar", code: "974" },
  RE: { name: "Reunion", code: "262", lang: "fre" },
  RO: { name: "Romania", code: "40", currency: "EUR" },
  RU: { name: "Russia", code: "7", lang: "rus", currency: "RUB", city: "Moscow" },
  RW: { name: "Rwanda", code: "250" },
  BL: { name: "Saint Barthelemy", code: "590" },
  SH: { name: "Saint Helena", code: "290" },
  KN: { name: "Saint Kitts and Nevis", code: "1 869" },
  LC: { name: "Saint Lucia", code: "1 758" },
  MF: { name: "Saint Martin (France)", code: "590" },
  PM: { name: "Saint Pierre and Miquelon", code: "508" },
  VC: { name: "Saint Vincent and the Grenadines", code: "1 784" },
  WS: { name: "Samoa", code: "685" },
  SM: { name: "San Marino", code: "378", lang: "ita", currency: "EUR" },
  ST: { name: "Sao Tome and Principe", code: "239" },
  SA: { name: "Saudi Arabia", code: "966" },
  SN: { name: "Senegal", code: "221" },
  RS: { name: "Serbia", code: "381", currency: "EUR" },
  SC: { name: "Seychelles", code: "248" },
  SL: { name: "Sierra Leone", code: "232" },
  SG: { name: "Singapore", code: "65" },
  SX: { name: "Sint Maarten (Netherlands)", code: "1 721" },
  SK: { name: "Slovakia", code: "421", currency: "EUR" },
  SI: { name: "Slovenia", code: "386", currency: "EUR" },
  SB: { name: "Solomon Islands", code: "677" },
  SO: { name: "Somalia", code: "252" },
  ZA: { name: "South Africa", code: "27", currency: "ZAR" },
  GS: { name: "South Georgia and the South Sandwich Islands", code: "500" },
  SS: { name: "South Sudan", code: "211" },
  KR: { name: "South Korea", code: "82" },
  ES: { name: "Spain", code: "34", lang: "spa", currency: "EUR" },
  LK: { name: "Sri Lanka", code: "94" },
  SD: { name: "Sudan", code: "249" },
  SR: { name: "Suriname", code: "597" },
  SJ: { name: "Svalbard and Jan Mayen", code: "47" },
  SZ: { name: "Swaziland", code: "268" },
  SE: { name: "Sweden", code: "46", lang: "swe", currency: "SEK", city: "Stockholm" },
  CH: { name: "Switzerland", code: "41", lang: "fre", currency: "CHF" },
  SY: { name: "Syria", code: "963" },
  TW: { name: "Taiwan", code: "886" },
  TJ: { name: "Tajikistan", code: "992", lang: "rus" },
  TZ: { name: "Tanzania", code: "255" },
  TH: { name: "Thailand", code: "66", currency: "THB" },
  TL: { name: "Timor-Leste", code: "670" },
  TG: { name: "Togo", code: "228" },
  TK: { name: "Tokelau", code: "690", currency: "NZD" },
  TO: { name: "Tonga", code: "676" },
  TT: { name: "Trinidad and Tobago", code: "1 868" },
  TN: { name: "Tunisia", code: "216" },
  TR: { name: "Turkey", code: "90", lang: "tur", currency: "TRY" },
  TM: { name: "Turkmenistan", code: "993", lang: "tur" },
  TC: { name: "Turks and Caicos Islands", code: "1 649" },
  TV: { name: "Tuvalu", code: "688" },
  UG: { name: "Uganda", code: "256" },
  UA: { name: "Ukraine", code: "380", lang: "ukr", currency: "UAH" },
  AE: { name: "United Arab Emirates", code: "971" },
  GB: { name: "United Kingdom", code: "44", currency: "GBP", city: "London" },
  US: { name: "United States", code: "1", currency: "USD" },
  UY: { name: "Uruguay", code: "598", lang: "spa", currency: "UYU" },
  UZ: { name: "Uzbekistan", code: "998", lang: "rus" },
  VU: { name: "Vanuatu", code: "678" },
  VE: { name: "Venezuela", code: "58", lang: "spa" },
  VN: { name: "Vietnam", code: "84" },
  VG: { name: "Virgin Islands, British", code: "1" },
  VI: { name: "Virgin Islands, U.S.", code: "1", currency: "USD" },
  WF: { name: "Wallis and Futuna", code: "681" },
  EH: { name: "Western Sahara", code: "212" },
  YE: { name: "Yemen", code: "967" },
  ZM: { name: "Zambia", code: "260" },
  ZW: { name: "Zimbabwe", code: "263" }
};
