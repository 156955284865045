import { useEffect, useMemo, useState } from "react";
import Swarm from "connection/Swarm";
import { convertSetName } from "helpers/gameInfo";

const generateRequest = (gameIds: number[]): Dictionary<any> => {
  return {
    source: "betting",
    what: {
      game: [
        "id",
        "is_live",
        "start_ts",
        "markets_count",
        "team1_id",
        "team2_id",
        "team1_name",
        "team2_name",
        "info",
        "stats",
        "sport_alias",
        "region_alias"
      ],
      competition: ["id", "name"]
    },
    where: {
      game: {
        id: { "@in": gameIds }
      }
    }
  };
};

const useFavoriteSportGames = (sportGames: Dictionary<boolean>) => {
  const [state, setState] = useState<IFavoriteSportGame[]>([]);

  const favoriteSportGameIds = useMemo(() => {
    return Object.keys(sportGames).join("$");
  }, [sportGames]);

  useEffect(
    function subscribeToFavoriteSportGames() {
      if (favoriteSportGameIds.length > 0) {
        const request = generateRequest(favoriteSportGameIds.split("$").map(gameId => Number(gameId)));

        const unsubId = Swarm.subscribe(request, (response: { competition: Dictionary<ISwarmCompetition> }) => {
          const favoriteSportGames: IFavoriteSportGame[] = [];

          for (const competition of Object.values(response.competition)) {
            for (const game of Object.values(competition.game as Dictionary<ISwarmGame>)) {
              const currentSet = convertSetName(game.info?.current_game_state, game.sport_alias);

              favoriteSportGames.push({
                sportAlias: game.sport_alias,
                regionAlias: game.region_alias,
                competitionId: competition.id,
                competitionName: competition.name,
                gameId: game.id,
                isLive: game.is_live === 1,
                startTime: game.start_ts,
                marketsCount: game.markets_count,
                team1Id: game.team1_id,
                team2Id: game.team2_id,
                team1Name: game.team1_name,
                team2Name: game.team2_name,
                team1Score: Number(game.info.set_count === 1 ? game.stats.score_set1.team1_value : game.info.score1),
                team2Score: Number(game.info.set_count === 1 ? game.stats.score_set1.team2_value : game.info.score2),
                currentSet,
                currentGameTime: game.info.current_game_time
              });
            }
          }

          setState(favoriteSportGames);
        });

        return () => {
          Swarm.unsubscribe(unsubId);
        };
      } else {
        setState([]);
      }
    },
    [favoriteSportGameIds]
  );

  return {
    state
  };
};

export default useFavoriteSportGames;
