import React from "react";
import "./Loader.scss";

interface IPropTypes {
  size?: "big" | "medium" | "small";
}

function Loader({ size = "medium" }: IPropTypes) {
  return <div className={`bc-loader ${size}`} />;
}

export default Loader;
