import React, { useEffect, useState } from "react";
import Swarm from "../connection/Swarm";
import Log from "../helpers/Log";

interface IStream {
  provider_id: number;
  video_id: number | string;
}

interface IProviderProps {
  streamsMap: Dictionary<IStream[]>;
  loaded: boolean;
  init: CallbackFunction;
}

const initialState: Dictionary<IStream[]> = {} as Dictionary<IStream[]>;

export const VideoStateProviderContext = React.createContext<IProviderProps>({} as IProviderProps);

const VideoProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = useState(initialState);
  const [loaded, setLoaded] = useState(false);
  const [subscriptionEnabled, setSubscriptionEnabled] = useState(false);

  const init = () => setSubscriptionEnabled(true);

  useEffect(() => {
    if (subscriptionEnabled) {
      Swarm.subscribe(
        { source: "notifications", what: { partner_streams: [] } },
        ({ stream_configs }: { stream_configs: Dictionary<IStream[]> }, initial) => {
          setState(state => ({ ...state, ...stream_configs }));
          if (initial) {
            setLoaded(true);
          }
        },
        (error: any) => Log.error(`Failed to subscribe to partner streams: reason: ${error.msg}`)
      );
    }
  }, [subscriptionEnabled]);

  return (
    <VideoStateProviderContext.Provider
      value={{
        streamsMap: state,
        loaded,
        init
      }}
    >
      {children}
    </VideoStateProviderContext.Provider>
  );
};

export default VideoProvider;
