import { TranslationContext } from "providers/TranslationProvider";
import React, { memo, useContext } from "react";
import "./InputTypeText.scss";
import { isEmpty } from "helpers/utils";
import InputMask, { InputState, MaskOptions } from "react-input-mask";

const InputTypeText: React.FunctionComponent<IFormControl> = ({
  error,
  touched,
  focused,
  label = "",
  customAttrs,
  className,
  onChange,
  placeholder,
  value,
  mask,
  ...props
}) => {
  const invalid = touched && error;
  const classNames: string[] = [];
  const inputValue = !isEmpty(value) ? String(value) : "";
  const isValueEmpty = inputValue.trim().length === 0;

  const trans = useContext(TranslationContext);

  if (focused) {
    classNames.push("focused");
  }
  if (touched) {
    classNames.push(invalid ? "invalid" : "valid");
  }
  if (!isValueEmpty) {
    classNames.push("filled");
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.type, event.target.name, event.target.value);
  };

  const handleInputMaskChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.type, event.target.name, event.target.value);
  };

  return (
    <div className={`form-control-bc default ${classNames.join(" ")}`}>
      {/*focused, filled, valid, invalid*/}
      <label className="form-control-label-bc inputs">
        {!props.readOnly ? (
          mask ? (
            <InputMask
              mask={mask}
              onChange={handleInputMaskChange}
              className="form-control-input-bc"
              onFocus={handleInputMaskChange}
              onBlur={handleInputMaskChange}
              value={inputValue}
              {...props}
            />
          ) : (
            <input
              type="text"
              className="form-control-input-bc"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValue}
              {...props}
            />
          )
        ) : (
          <input type="text" className="form-control-input-bc" defaultValue={trans(inputValue)} {...props} />
        )}
        <i className="form-control-input-stroke-bc" />
        <span className="form-control-title-bc">{trans(label)}</span>
      </label>
      {invalid ? (
        <div className="form-control-message-holder-bc">
          <span className="form-control-message-bc">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default memo(InputTypeText);
