import React, { useContext, useEffect, useMemo, useState } from "react";
import Config from "configs";
import GeoData from "constants/geoData";

import { UserContextState } from "providers/UserProvider";
import { PreferencesStateContext } from "providers/PreferencesProvider";
import { generateRoutes } from "helpers/route";
import { calculateAge } from "helpers/utils";

enum NavigationItem {
  HEADER = "headerNavigation",
  PROFILE = "profileNavigation"
}

interface INavigationContext {
  [NavigationItem.HEADER]: IRoutes;
  [NavigationItem.PROFILE]: IRoutes;
}

export const NavigationContext = React.createContext({} as INavigationContext);

const initialState: INavigationContext = {
  [NavigationItem.HEADER]: { navLinks: [], routes: [] },
  [NavigationItem.PROFILE]: { navLinks: [], routes: [] }
};

const NavigationProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = useState(initialState);

  const {
    app: { headerNavigation },
    profile: { profileNavigation }
  } = Config;

  const { isLoggedIn, profile } = useContext(UserContextState);
  const { language } = useContext(PreferencesStateContext);
  const { countryCode } = GeoData;

  const { birth_date: birthDate = null, sportsbook_profile_id: sportsbookProfileId = null } = profile || {};

  const userPermissions = useMemo<IUserPermissions>(() => {
    return {
      language,
      age: birthDate ? calculateAge(birthDate) : 0,
      profileType: sportsbookProfileId,
      country: countryCode,
      isLoggedIn
    };
  }, [birthDate, countryCode, isLoggedIn, language, sportsbookProfileId]);

  useEffect(() => {
    const routes = generateRoutes(headerNavigation, userPermissions);
    setState(state => ({
      ...state,
      [NavigationItem.HEADER]: routes
    }));
  }, [headerNavigation, userPermissions]);

  useEffect(() => {
    let routes: IRoutes = { routes: [], navLinks: [] };
    if (userPermissions.isLoggedIn) {
      routes = generateRoutes(profileNavigation, userPermissions);
    }

    setState(state => ({
      ...state,
      [NavigationItem.PROFILE]: routes
    }));
  }, [profileNavigation, userPermissions]);

  return <NavigationContext.Provider value={state}>{children}</NavigationContext.Provider>;
};

export default NavigationProvider;
