import { AuthPopupContentTypeEnum } from "dev/components/auth/AuthPopup";
import { ContentManagerTargetType } from "dev/components/contentManager";
import Login from "dev/components/login/Login";
import Registration from "dev/components/registration/Registration";
import { useCMSBanners } from "helpers/cms/CMSManager";
import { TranslationContext } from "providers/TranslationProvider";
import React, { memo, useContext } from "react";
import contentManagerEventDispatcher from "../contentManager/contentManagerDispatcher";

type AuthPopupContentType = AuthPopupContentTypeEnum.LOGIN | AuthPopupContentTypeEnum.REGISTRATION;

interface IPropTypes {
  activeContentType: AuthPopupContentType;
  setActiveContentType: CallbackFunction;
  formInvalid: boolean;
  setFormInvalid: CallbackFunction;
}

const AuthPopupTemplate: React.FunctionComponent<IPropTypes> = ({
  activeContentType,
  setActiveContentType,
  formInvalid,
  setFormInvalid
}) => {
  const [loading, banners] = useCMSBanners("registration-banner", true);
  const trans = useContext(TranslationContext);

  const handleSetActiveContentType = (activeContentType: AuthPopupContentType) => () => {
    setActiveContentType(activeContentType);
  };

  const handleSetFormInvalid = (formInvalid: boolean) => () => {
    setFormInvalid(formInvalid);
  };

  const handleSuccess = () => {
    setFormInvalid(false);
    contentManagerEventDispatcher.dispatchEvent("hideAll");
  };

  return (
    <div className={`entrance-popup-bc ${activeContentType === AuthPopupContentTypeEnum.REGISTRATION ? "register" : "sign-in"}`}>
      <div className="e-p-banner-bc">
        <div className="e-p-b-item-bc">
          {loading ? (
            <span>Loading...</span>
          ) : (
            banners.map((banner, index) => {
              return (
                <div className="e-p-b-img-w-bc" key={index}>
                  <img className="e-p-b-img-item-bc" src={banner.imageurl} alt="" />
                </div>
              );
            })
          )}

          <div className="e-p-b-social-holder-bc">
            <div className="e-p-b-social-item-bc">
              <i className="e-p-b-social-icon-bc bc-i-apps" />
            </div>
            <div className="e-p-b-social-item-bc">
              <i className="e-p-b-social-icon-bc bc-i-apps" />
            </div>
            <div className="e-p-b-social-item-bc">
              <i className="e-p-b-social-icon-bc bc-i-apps" />
            </div>
            <div className="e-p-b-social-item-bc">
              <i className="e-p-b-social-icon-bc bc-i-apps" />
            </div>
          </div>
        </div>

        <div className="e-p-b-controls-holder-bc">
          <span className="e-p-b-control-bc backward">
            <i className="e-p-b-control-icon-bc bc-i-small-arrow-left" />
          </span>
          <span className="e-p-b-control-bc forward">
            <i className="e-p-b-control-icon-bc bc-i-small-arrow-right" />
          </span>
        </div>
      </div>
      <div className={`e-p-content-holder-bc ${formInvalid ? "invalid" : ""}`}>
        {/*invalid*/}
        <div className="e-p-content-bc">
          <div className="e-p-header-bc">
            {/*<i className="e-p-close-icon-bc bc-i-close-remove" onClick={handleCloseClick} />*/}
            <div className="e-p-sections-bc">
              <div className={`e-p-section-item-bc ${activeContentType === AuthPopupContentTypeEnum.LOGIN && "active"}`}>
                <span className="e-p-section-title-bc" onClick={handleSetActiveContentType(AuthPopupContentTypeEnum.LOGIN)}>
                  {trans("sign in")}
                </span>
              </div>
              <div className={`e-p-section-item-bc ${activeContentType === AuthPopupContentTypeEnum.REGISTRATION && "active"}`}>
                <span className="e-p-section-title-bc" onClick={handleSetActiveContentType(AuthPopupContentTypeEnum.REGISTRATION)}>
                  {trans("register")}
                </span>
              </div>
            </div>
          </div>
          <div className="e-p-body-bc">
            {activeContentType === AuthPopupContentTypeEnum.REGISTRATION && (
              <Registration
                onFailure={handleSetFormInvalid(true)}
                onSucceed={handleSuccess}
                contentManagerTargetType={ContentManagerTargetType.POPUP}
              />
            )}
            {activeContentType === AuthPopupContentTypeEnum.LOGIN && (
              <Login
                onFailure={handleSetFormInvalid(true)}
                onSucceed={handleSuccess}
                contentManagerTargetType={ContentManagerTargetType.POPUP}
              />
            )}
          </div>
          <div className="e-p-footer-bc">
            <span className="e-p-footer-title-bc">we accept</span>
            <div className="e-p-footer-payments-bc">
              <div className="e-p-footer-payment-item-bc">
                <img
                  src="https://cmsbetconstruct.com/content/images/payments/default/47.png?2019-06-05 13:16:30"
                  alt="payment system"
                  className="e-p-footer-payment-image-bc"
                />
              </div>
              <div className="e-p-footer-payment-item-bc">
                <img
                  src="https://cmsbetconstruct.com/content/images/payments/custom/1/123.png?2019-06-05 13:16:30"
                  alt="payment system"
                  className="e-p-footer-payment-image-bc"
                />
              </div>
              <div className="e-p-footer-payment-item-bc">
                <img
                  src="https://cmsbetconstruct.com/content/images/payments/custom/1/366.png?2019-06-05 13:16:30"
                  alt="payment system"
                  className="e-p-footer-payment-image-bc"
                />
              </div>
              <div className="e-p-footer-payment-item-bc">
                <img
                  src="https://cmsbetconstruct.com/content/images/payments/custom/1/46.png?2019-06-05 13:16:30"
                  alt="payment system"
                  className="e-p-footer-payment-image-bc"
                />
              </div>
            </div>
            <div className="e-p-footer-live-support-holder-bc">
              <span className="e-p-footer-live-support-bc">Need Help? Live Chat Support - 24/7"</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(AuthPopupTemplate);
