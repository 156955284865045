/**
 * Helper function for routing
 */
import { uid } from "helpers/utils";

const isForbiddenRoute = (permissions: IPermissions, userState: IUserPermissions): boolean => {
  const { loggedIn, loggedOut, excludedLanguages, excludedCountries, minimumAge, profileType } = permissions;
  const {
    language: userLanguage,
    age: userAge,
    profileType: userProfileType,
    country: userCountry,
    isLoggedIn: userIsLoggedIn
  } = userState;

  return (
    (userIsLoggedIn && !loggedIn) ||
    (!userIsLoggedIn && !loggedOut) ||
    userAge < minimumAge ||
    excludedLanguages.indexOf(userLanguage) !== -1 ||
    excludedCountries.indexOf(userCountry) !== -1 ||
    (profileType.length > 0 && userProfileType !== null && profileType.indexOf(userProfileType) === -1)
  );
};

export const generateRoutes = (menuItems: INavigationMenuItem[], userState: IUserPermissions): IRoutes => {
  const generatedResult: IRoutes = {
    navLinks: [],
    routes: []
  };

  for (let item of menuItems) {
    const menuId = uid();
    const { path, permissions, submenus } = item;

    let subNavLinks: INavLink[] = [];
    let subRoutes: IRoute[] = [];

    if (permissions.disabled) {
      continue;
    }

    const forbiddenRoute = isForbiddenRoute(permissions, userState);

    if (submenus && submenus.length > 0) {
      const subResult = generateRoutes(submenus, userState);
      subNavLinks = subResult.navLinks;
      subRoutes = subResult.routes;
    }

    if (!permissions.hideInMenu && !forbiddenRoute) {
      generatedResult.navLinks.push({ ...item, submenus: subNavLinks, id: menuId });
    }

    if (path) {
      generatedResult.routes.push({ ...item, forbiddenRoute });
    }
    if (subRoutes.length > 0) {
      generatedResult.routes.push.apply(generatedResult.routes, subRoutes);
    }
  }

  return generatedResult;
};

export const generateLinkPath = (path: string, params: Dictionary<any>) => {
  return path.replace(/\/:([\w|-]+)\??/gi, function(match, p1) {
    return params[p1] ? `/${params[p1]}` : "";
  });
};
