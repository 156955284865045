import Log from "./Log";

let ipAddress: string;

const GetLocalIp = () => {
  return new Promise((resolve, reject) => {
    if (ipAddress) {
      resolve(ipAddress);
      return;
    }

    // NOTE: window.RTCPeerConnection is "not a constructor" in FF22/23
    const RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection || false;

    const updateDisplay = (newAddr: any) => {
      const address: Dictionary<boolean> = {};
      address["0.0.0.0"] = false;

      if (newAddr in address) return;
      else address[newAddr] = true;

      console.log("updateDisplay");

      const displayAddress = Object.keys(address).filter(k => {
        return address[k];
      });

      ipAddress = displayAddress.join(" or perhaps ") || "n/a";

      resolve(ipAddress);
    };

    function grepSDP(sdp: string) {
      sdp.split("\r\n").forEach(function(line) {
        let parts;
        let addr: any;
        // c.f. http://tools.ietf.org/html/rfc4566#page-39
        if (~line.indexOf("a=candidate")) {
          // http://tools.ietf.org/html/rfc4566#section-5.13
          parts = line.split(" ");
          addr = parts[4];
          let type = parts[7];
          if (type === "host") {
            updateDisplay(addr);
          }
        } else if (~line.indexOf("c=")) {
          // http://tools.ietf.org/html/rfc4566#section-5.7
          parts = line.split(" ");
          addr = parts[2];
          updateDisplay(addr);
        }
      });
    }

    if (RTCPeerConnection) {
      const rtc = new RTCPeerConnection({ iceServers: [] });
      rtc.createDataChannel && rtc.createDataChannel("", { reliable: false });

      rtc.onicecandidate = function(evt: any) {
        // convert the candidate to SDP so we can run it through our general parser
        // see https://twitter.com/lancestout/status/525796175425720320 for details
        if (evt.candidate) {
          grepSDP("a=" + evt.candidate.candidate);
        }
      };

      rtc
        .createOffer()
        .then((offerDesc: any) => {
          grepSDP(offerDesc.sdp);
          rtc.setLocalDescription(offerDesc);
        })
        .catch((e: Error) => {
          Log.warning(`offer failed ${e.message}`);
          reject();
        });
    }
  });
};

export default GetLocalIp;
