import axios from "axios";

class CMSBase {
  protected baseUrl: string = "https://cmsbetconstruct.com";

  protected loadData<T>(url: string, urlParams: Dictionary<string | string[]>): Promise<T> {
    return axios.get(this.baseUrl + url, { params: urlParams }).then(response => {
      const { status, message, ...data } = response.data;
      return new Promise((resolve, reject) => {
        if (status !== -1) {
          resolve(data);
        } else {
          reject(message);
        }
      });
    });
  }
}

export default CMSBase;
