import React, { memo, useContext } from "react";

import "./StaticTypeText.scss";
import { TranslationContext } from "providers/TranslationProvider";
const StaticTypeText: React.FunctionComponent<IFormControl> = ({ label = "", props, className }) => {
  const trans = useContext(TranslationContext);
  if (!props) {
    return null;
  }

  return (
    <div className={`u-i-p-control-item-holder-bc ${className}`}>
      {label && <div className="form-control-title-static-bc">{label}</div>}
      {props.html ? (
        <div className="form-control-title-static-bc" dangerouslySetInnerHTML={{ __html: trans(`deposit_info_${props.content}`) }} />
      ) : (
        <div className="form-control-title-static-bc">{props.content}</div>
      )}
    </div>
  );
};

export default memo(StaticTypeText);
