import Swarm from "connection/Swarm";
import Log from "helpers/Log";
import React, { useEffect, useState } from "react";
import Config from "configs";

const initialState: IPartner = {} as IPartner;

export const PartnerContextState = React.createContext<IPartner>(initialState);

const PartnerProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const { site_id } = Config.app;
    Swarm.subscribe(
      { source: "partner.config", what: { partner: [] } },
      ({ partner }: { partner: Dictionary<IPartner> }) => {
        if (partner[site_id]) {
          setState(partner[site_id]);
        }
      },
      (error: any) => Log.error(`Failed to subscribe to partner config: reason: ${error.msg}`)
    );
  }, []);

  return <PartnerContextState.Provider value={state}>{children}</PartnerContextState.Provider>;
};

export default PartnerProvider;
