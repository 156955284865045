import EventType from "constants/eventType";
import { ContentManagerTargetType, useContentManager } from "dev/components/contentManager";
import { RootEventDispatcher } from "helpers/EventDispatcher";
import React, { Suspense, useEffect } from "react";
import Loader from "../loader/Loader";

import BasePopup from "../popup/BasePopup";

function GlobalPopup() {
  const { components, hide, selectedComponent } = useContentManager(ContentManagerTargetType.POPUP);

  let fullScreen = false;
  if (selectedComponent) {
    fullScreen = selectedComponent.props.fullScreen || false;
  }

  useEffect(() => {
    if (selectedComponent) {
      RootEventDispatcher.dispatchEvent(EventType.ADD_ROOT_CLASS_NAME, "popup-is-visible");
    } else {
      RootEventDispatcher.dispatchEvent(EventType.REMOVE_ROOT_CLASS_NAME, "popup-is-visible");
    }
  }, [selectedComponent]);

  return (
    <BasePopup onClose={hide} isHidden={!selectedComponent} fullScreen={fullScreen}>
      <Suspense fallback={<Loader size="medium" />}>
        {components.map(({ LazyComponent, name, selected, props }) => (
          <LazyComponent key={name} show={selected} {...props} />
        ))}
      </Suspense>
    </BasePopup>
  );
}

export default GlobalPopup;
