import Storage from "services/storage";

const RECAPTCHA_URL = "https://www.google.com/recaptcha/api.js?render=";
const STORAGE_KEY = "recaptcha_3_key";

// Also signifies that the script has already been attached
let RECAPTCHA_KEY: string;

enum Actions {
  session_opened = "session_opened",
  login = "login",
  register = "register"
}

function storeData(key: string) {
  Storage.setItem(STORAGE_KEY, key);
}

function getStoredData(): string | null {
  return Storage.getItem(STORAGE_KEY);
}

function deleteStoredData() {
  Storage.removeItem(STORAGE_KEY);
}

// Attaches script to the DOM
function init(key: string): Promise<void> {
  if (RECAPTCHA_KEY) return Promise.resolve();

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = RECAPTCHA_URL + key;

    script.onload = () => window.grecaptcha.ready(resolve);
    script.onerror = reject;

    const firstScript = document.getElementsByTagName("script")[0];
    if (firstScript && firstScript.parentNode) {
      firstScript.parentNode.insertBefore(script, firstScript);
    } else {
      document.head.appendChild(script);
    }

    RECAPTCHA_KEY = key;
  });
}

function getToken(action: string): Promise<string> {
  if (!RECAPTCHA_KEY) return Promise.reject("Recaptcha not initialized");

  return new Promise((resolve, reject) => {
    try {
      window.grecaptcha.execute(RECAPTCHA_KEY, { action }).then(resolve);
    } catch (e) {
      reject(e);
    }
  });
}

export default {
  Actions,
  getStoredData,
  init,
  storeData,
  deleteStoredData,
  getToken
};
