type LanguageCodes = Dictionary<string>;

const languageCodes: LanguageCodes = {
  eng: "en", // english
  ger: "de", // german
  fre: "fr", // french
  spa: "es", // spanish
  ita: "it", // italian
  por: "pt", // portuguese
  "pt-br": "pb", // portuguese (brazil)
  por_2: "pb", // portuguese (brazil)
  gre: "el", // greek
  rus: "ru", // russian
  lit: "lt", // lithuanian
  lav: "lv", // latvian
  arm: "hy", // armenian
  geo: "ka", // georgian
  arb: "ar", // arabic
  far: "fa", // persian (farsi)
  tur: "tr", // turkish
  chn: "zh", // china
  chi: "zh", // china (this case is for statistics call)
  zho: "zh", // china
  est: "et", // estonian
  ind: "id", // indonesian
  kor: "ko", // korean
  msa: "ms", // malay
  pol: "pl", // polish
  ron: "ro", // romanian
  cze: "cs", // Czech
  slo: "sk", // Slovak
  slk: "sk", // Slovak
  kur: "ku", // Kurdish
  jpn: "ja", // Japanese
  aze: "az", // Azerbaijani
  srp: "sr", // serbian
  ukr: "uk", // ukrainian
  zhh: "zs", // china simplified
  bgr: "bg", // bulgarian
  translit: "rt", // translit
  nor: "no", // norway
  nld: "nl", // dutch
  dut: "nl", // dutch
  fas: "fa", //farsi
  heb: "he", //hebrew
  swe: "sv", //swedish
  fin: "fi", //Finnish
  slv: "sl", // slovene
  tha: "th", // thai
  alb: "sq", // albanian
  "en-ca": "ce" //Canadian English
};

export default languageCodes;
