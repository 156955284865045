/* eslint-disable no-useless-escape */
/**
 * A service that provides methods for storing and retrieving data provided by applications.
 * The window.localStorage API is used to store data. but
 * if window.localStorage is not available for the browser, then a cookie is used.
 * There is a special config [useCookies] to use the cookie API instead of window.localStorage.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Local_storage
 * */
import Config from "configs";

export const prefix = `__${process.env.REACT_APP_STORAGE_PREFIX}__`;

function storageAvailable() {
  let storage;
  try {
    storage = window.localStorage;
    let x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

const Storage = (function() {
  //TODO: check for Config.app.useCookies condition
  if (!storageAvailable()) {
    //during development, only  one partner with 1 id use cookie and the reason for this is that need to save same data for all subDomains
    let domain = window.location.hostname
      .split(/\./)
      .slice(-2)
      .join(".");
    return {
      getItem: function(sKey: string) {
        if (!sKey || !this.hasOwnProperty(sKey)) {
          return null;
        }
        return unescape(
          document.cookie.replace(
            new RegExp("(?:^|.*;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"),
            "$1"
          )
        );
      },
      key: function(nKeyId: number) {
        return unescape(document.cookie.replace(/\s*\=(?:.(?!;))*$/, "").split(/\s*\=(?:[^;](?!;))*[^;]?;\s*/)[nKeyId]);
      },
      setItem: function(sKey: string, sValue: any, maxAge?: number) {
        if (!sKey) {
          return;
        }
        document.cookie =
          escape(sKey) +
          "=" +
          escape(sValue) +
          `; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/; domain=${domain}; ${maxAge ? `max-age=${maxAge}` : ""}`;
      },
      removeItem: function(sKey: string) {
        if (!sKey || !this.hasOwnProperty(sKey)) {
          return;
        }
        document.cookie = escape(sKey) + `=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain}`;
      },
      hasOwnProperty: function(sKey: string) {
        return new RegExp("(?:^|;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=").test(document.cookie);
      }
    };
  }

  return window.localStorage;
})();

const setItem = (key: string, value: any, maxAge?: number) => {
  const toStore: IStorageValue = {
    data: value,
    expiration: maxAge ? Math.round(Date.now() / 1000 + maxAge) : null
  };

  Storage.setItem(`${prefix}${key}`, JSON.stringify(toStore));
};

const getItem = (key: string, defaultValue: any = null): any | null => {
  try {
    const item = Storage.getItem(`${prefix}${key}`);
    if (item) {
      const { data, expiration }: IStorageValue = JSON.parse(item);
      if (expiration) {
        if (expiration > Date.now() / 1000) {
          return data;
        }
        removeItem(key);
        return defaultValue;
      } else {
        return data;
      }
    } else {
      return defaultValue;
    }
  } catch (e) {
    return defaultValue;
  }
};

const removeItem = (key: string) => Storage.removeItem(`${prefix}${key}`);

/**
 *
 * @param {Array<string>} keys
 * @return {*}
 */
const removeItems = (keys: string[]): void => keys.forEach(key => removeItem(key));

export default {
  setItem,
  getItem,
  removeItem,
  removeItems
};

interface IStorageValue {
  data: any;
  expiration: number | null;
}
