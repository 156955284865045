import { lazy } from "react";

/**
 * @param {string} path - No need to put 'dev'
 */
function lazyLoadComponent(path: string | undefined) {
  if (path === undefined) {
    return null;
  }
  return lazy(() => import(`dev/${path}`).catch(() => import("NotFound")));
}

export default lazyLoadComponent;
