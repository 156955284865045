import Config from "configs";
import Swarm from "connection/Swarm";
import { ContentManagerTargetType } from "dev/components/contentManager";
import InputTypeCheckbox from "dev/components/formControls/InputTypeCheckbox/InputTypeCheckbox";
import InputTypePassword from "dev/components/formControls/InputTypePassword/InputTypePassword";
import InputTypeText from "dev/components/formControls/InputTypeText/InputTypeText";
import StaticTypeText from "dev/components/formControls/staticTypeText/StaticTypeText";
import useForm from "hooks/useForm";
import { TranslationContext } from "providers/TranslationProvider";
import { UserContextDispatch, UserContextState } from "providers/UserProvider";
import React, { memo, useContext, useEffect, useState } from "react";

import Button, { ButtonType } from "../button/Button";
import Loader from "../loader/Loader";

import "./Login.scss";

type FormData = {
  username: string;
  password: string;
  remember_me: boolean;
};

interface IPropTypes {
  onFailure?: VoidFunction;
  onSucceed?: VoidFunction;
  onRegisterClick?: CallbackFunction;
  contentManagerTargetType?: ContentManagerTargetType;
}

const Login: React.FunctionComponent<IPropTypes> = ({ onFailure, onSucceed, onRegisterClick, contentManagerTargetType }) => {
  const { loginFormConfig } = Config.app;
  const { formElements, formConfigForCurrentStep, handleChange, formIsValid, getData } = useForm(loginFormConfig);
  const [formError, setFormError] = useState<string>("");
  const [formDisabled, setFormDisabled] = useState(true);
  const { login } = useContext(UserContextDispatch);
  const { loginInProgress } = useContext(UserContextState);
  const trans = useContext(TranslationContext);

  useEffect(() => {
    Swarm.validateRecaptchaAction("login").finally(() => setFormDisabled(false));
  }, []);

  const handleLoginFailure = (errorCode: string) => {
    const code = Math.abs(parseInt(errorCode, 10));

    if (onFailure) {
      onFailure();
    }

    switch (code) {
      case 1002:
        setFormError(trans("Invalid username and/or password"));
        break;
      default:
    }
  };

  const handleLoginSuccess = (data: ISwarmAuthData) => {
    if (onSucceed) {
      onSucceed();
    }
  };

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault();

    const { username, password, remember_me } = getData<FormData>();

    login(username, password, remember_me)
      .then(userData => handleLoginSuccess(userData))
      .catch(error => {
        handleLoginFailure(error.data.status);
      });
  };

  const handleRegisterClick = () => {
    if (onRegisterClick) {
      onRegisterClick();
    }
  };

  return (
    <form
      className={`entrance-form-bc login ${contentManagerTargetType === ContentManagerTargetType.PANEL ? "panel" : "popup"}`}
      onSubmit={handleLogin}
    >
      <div className="sg-n-text-row-1-bc">Already existing account?</div>
      <div className="sg-n-text-row-2-bc">Sign In we were waiting for you</div>

      {/* panel / popup */}
      {formConfigForCurrentStep.map((columnElements: IFormElement[], index: number) => (
        <React.Fragment key={index}>
          {columnElements.map(({ name }) => {
            const { type, ...props } = formElements[name];

            switch (type) {
              case "text":
                return (
                  <div key={name} className="entrance-f-item-bc">
                    <InputTypeText onChange={handleChange} {...props} />
                  </div>
                );
              case "password":
                return (
                  <div key={name} className="entrance-f-item-bc">
                    <InputTypePassword onChange={handleChange} {...props} />
                  </div>
                );
              case "checkbox":
                return (
                  <div key={name} className="entrance-f-item-bc">
                    <InputTypeCheckbox onChange={handleChange} {...props} />
                  </div>
                );
              case "info":
                return (
                  <div key={name} className="entrance-f-item-bc">
                    <StaticTypeText {...props} />
                  </div>
                );
              default:
                return null;
            }
          })}
        </React.Fragment>
      ))}

      {formError && (
        <div className="e-p-form-error-message-holder-bc">
          <span className="entrance-f-error-message-bc">{formError}</span>
        </div>
      )}
      <div className="entrance-form-actions-holder-bc login-ext-1">
        <div className="entrance-form-action-item-bc right">
          {!loginInProgress ? (
            <Button className="entrance-form-action-bc" type={ButtonType.SUBMIT} disabled={!formIsValid || formDisabled}>
              {trans("login")}
            </Button>
          ) : (
            <Loader size={"small"} />
          )}
        </div>
        <div className="entrance-form-action-item-bc">
          <Button className="entrance-form-action-bc trans-v" onClick={handleRegisterClick}>
            {trans("register")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default memo(Login);
