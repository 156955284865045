import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { padStart } from "helpers/utils";
import { FormEventType } from "hooks/useForm";
import CalendarDatePickerInput from "./CalendarDatePickerInput";

import "react-datepicker/dist/react-datepicker.min.css";

interface IProps {
  touched: boolean;
  error: string | boolean;
  className?: string;
  placeholder?: string;
  label?: string;
  onChange: (eventType: string, value: string) => void;
  value: Date | null;
  minDate: Date;
  maxDate: Date;
}

const formatDate = (date: Date) => {
  return [date.getFullYear(), padStart((date.getMonth() + 1).toString(), 2, "0"), padStart(date.getDate().toString(), 2, "0")].join("-");
};

const CalendarDatePicker: React.FunctionComponent<IProps> = ({
  onChange,
  error,
  touched,
  placeholder,
  label,
  className,
  value,
  minDate,
  maxDate
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(value);

  const handleChange = (value: Date) => {
    setSelectedDate(value);
    onChange(FormEventType.CHANGE, formatDate(value));
  };

  const handleBlur = () => {
    onChange(FormEventType.BLUR, selectedDate ? formatDate(selectedDate) : "");
  };

  return (
    <DatePicker
      customInput={
        <CalendarDatePickerInput
          label={label}
          touched={touched}
          error={error}
          className={className}
          placeholderText={placeholder}
          onInputBlur={handleBlur}
        />
      }
      showYearDropdown
      showMonthDropdown
      maxDate={maxDate}
      minDate={minDate}
      selected={value}
      dateFormat="dd/MM/yyyy"
      dropdownMode="select"
      onChange={handleChange}
    />
  );
};

export default CalendarDatePicker;
