import { useContext, useRef } from "react";
import Config from "configs";
import Swarm from "connection/Swarm";
import AuthData from "services/authData";
import Storage from "services/storage";
import { UserContextDispatch } from "providers/UserProvider";

const useLogout = () => {
  const { unsubscribeFromProfile } = useContext(UserContextDispatch);

  const timeoutId = useRef<number>();

  const handleLogout = (dontClearAllData: boolean = false) => {
    clearTimeout(timeoutId.current);

    AuthData.clear();

    if (!dontClearAllData) {
      Storage.removeItems([
        "betslip",
        "vs_favorite_market_types",
        "favouriteMarketsTypes",
        "myGames",
        "prematchMultiViewGames",
        "prematchMultiViewCompetitions",
        "timezone"
      ]);
    }

    unsubscribeFromProfile();
  };

  const logoutUser = () => {
    return new Promise(resolve => {
      Swarm.logout().finally(() => {
        handleLogout();
        resolve();
      });

      // @ts-ignore
      timeoutId.current = setTimeout(() => {
        handleLogout();
        resolve();
      }, Config.app.logoutTimeout);
    });
  };

  return {
    logoutUser
  };
};

export default useLogout;
