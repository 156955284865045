import React, { useContext, useEffect, useMemo, useState } from "react";
import { TranslationContext } from "providers/TranslationProvider";
import { padStart } from "helpers/utils";
import { FormEventType } from "hooks/useForm";

interface IProps {
  touched: boolean;
  error: string | boolean;
  focused: boolean;
  minDate: Date;
  maxDate: Date;
  onChange: (eventType: string, value: string) => void;
  value: Date | null;
}

const generateDaySelectOptions = (month: number, year: number) => {
  let daysCount = 31;
  const options = [];

  if (month && year) {
    daysCount = new Date(year, month, 0).getDate();
  }

  for (let i = 1; i <= daysCount; i++) {
    const value = padStart(i.toString(), 2, "0");
    options.push({ value, label: value });
  }
  return options;
};

const generateMonthSelectOptions = () => {
  const options = [];
  for (let i = 1; i <= 12; i++) {
    const value = padStart(i.toString(), 2, "0");
    options.push({ value, label: value });
  }
  return options;
};

const generateYearSelectOptions = (minDate: Date, maxDate: Date) => {
  const options = [];
  for (let i = maxDate.getFullYear(), length = minDate.getFullYear(); i >= length; i--) {
    options.push({ value: i, label: i });
  }
  return options;
};

const DropDownDatePicker: React.FunctionComponent<IProps> = ({ touched, error, focused, maxDate, minDate, onChange, value }) => {
  const classNames: string[] = [];
  const invalid = touched && error;

  const trans = useContext(TranslationContext);

  if (focused) {
    classNames.push("focused");
  }
  if (touched) {
    classNames.push(invalid ? "invalid" : "valid");
  }

  const [dayTouched, setDayTouched] = useState<boolean>(false);
  const [monthTouched, setMonthTouched] = useState<boolean>(false);
  const [yearTouched, setYearTouched] = useState<boolean>(false);

  const [day, setDay] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");

  const dayOptions = useMemo(() => generateDaySelectOptions(Number(month), Number(year)), [month, year]);
  const monthOptions = useMemo(() => generateMonthSelectOptions(), []);
  const yearOptions = useMemo(() => generateYearSelectOptions(minDate, maxDate), [maxDate, minDate]);

  useEffect(() => {
    setDay(value ? padStart(value.getDate().toString(), 2, "0") : "");
    setMonth(value ? padStart((value.getMonth() + 1).toString(), 2, "0") : "");
    setYear(value ? padStart(value.getFullYear().toString(), 2, "0") : "");
  }, [value]);

  useEffect(() => {
    if (day || month || year) {
      const value = getValue(day, month, year);
      onChange(FormEventType.CHANGE, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, month, year]);

  useEffect(() => {
    if (dayTouched && monthTouched && yearTouched) {
      const value = getValue(day, month, year);
      handleBlur(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, dayTouched, month, monthTouched, year, yearTouched]);

  const handleBlur = (value: string) => {
    onChange(FormEventType.BLUR, value);
  };

  const getValue = (day: string, month: string, year: string) => {
    if (day && month && year) {
      return [year, month, day].join("-");
    }
    return "";
  };

  const handleDayChange = (event: React.ChangeEvent<HTMLSelectElement>) => setDay(event.target.value);
  const handleDayFocus = () => setDayTouched(true);

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => setMonth(event.target.value);
  const handleMonthFocus = () => setMonthTouched(true);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => setYear(event.target.value);
  const handleYearFocus = () => setYearTouched(true);

  return (
    <div className="form-controls-group-bc date-picker">
      <div className="form-controls-fields-bc">
        <div className="form-controls-field-bc">
          <div className={`form-control-bc select has-icon ${day ? "filled" : ""} ${classNames.join(" ")}`}>
            {/*has-icon, focused, filled, valid, invalid*/}
            <label className="form-control-label-bc inputs">
              <span className="form-control-title-bc">{trans("Day")}</span>
              <select
                className="form-control-select-bc"
                onFocus={handleDayFocus}
                onChange={handleDayChange}
                onBlur={handleDayChange}
                value={day}
              >
                <option value="" />
                {dayOptions.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <i className="form-control-input-stroke-bc" />
              <span className="form-control-icon-holder-bc">
                <i className="form-control-icon-bc bc-i-arrow-drop-down-bottom" />
              </span>
            </label>
          </div>
        </div>
        <div className="form-controls-field-bc">
          <div className={`form-control-bc select has-icon ${month ? "filled" : ""} ${classNames.join(" ")}`}>
            <label className="form-control-label-bc inputs">
              <span className="form-control-title-bc">{trans("Month")}</span>
              <select
                className="form-control-select-bc"
                onFocus={handleMonthFocus}
                onChange={handleMonthChange}
                onBlur={handleMonthChange}
                value={month}
              >
                <option value="" />
                {monthOptions.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <i className="form-control-input-stroke-bc" />
              <span className="form-control-icon-holder-bc">
                <i className="form-control-icon-bc bc-i-arrow-drop-down-bottom" />
              </span>
            </label>
          </div>
        </div>
        <div className="form-controls-field-bc">
          <div className={`form-control-bc select has-icon ${year ? "filled" : ""} ${classNames.join(" ")}`}>
            <label className="form-control-label-bc inputs">
              <span className="form-control-title-bc">{trans("Year")}</span>
              <select
                className="form-control-select-bc"
                onFocus={handleYearFocus}
                onChange={handleYearChange}
                onBlur={handleYearChange}
                value={year}
              >
                <option value="" />
                {yearOptions.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <i className="form-control-input-stroke-bc" />
              <span className="form-control-icon-holder-bc">
                <i className="form-control-icon-bc bc-i-arrow-drop-down-bottom" />
              </span>
            </label>
          </div>
        </div>
      </div>
      {invalid ? (
        <div className="form-control-message-holder-bc">
          <span className="form-control-message-bc">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default DropDownDatePicker;
