import React from "react";
import { isEmpty } from "helpers/utils";

interface IProps {
  onClick?: VoidFunction;
  error?: boolean | string;
  touched?: boolean;
  label?: string;
  placeholderText?: string;
  className?: string;
  value?: string;
  onInputBlur: CallbackFunction;
}

interface IState {
  focused: boolean;
}

class CalendarDatePickerInput extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      focused: false
    };
  }

  handleFocus() {
    this.setState({
      focused: true
    });
  }

  handleBlur() {
    this.setState({
      focused: false
    });
    this.props.onInputBlur();
  }

  render() {
    const { error, touched, label, placeholderText, className, value, onClick } = this.props;
    const { focused } = this.state;
    const invalid = touched && error;
    const classNames: string[] = [];
    const inputValue = !isEmpty(value) ? String(value) : "";
    const isValueEmpty = inputValue.trim().length === 0;

    if (focused) {
      classNames.push("focused");
    }
    if (touched) {
      classNames.push(invalid ? "invalid" : "valid");
    }
    if (!isValueEmpty) {
      classNames.push("filled");
    }

    return (
      <div className={`form-control-bc default filled ${classNames.join(" ")}`} onClick={onClick}>
        <label className="form-control-label-bc inputs">
          <input
            className={`form-control-input-bc ${className}`}
            type="text"
            defaultValue={value}
            placeholder={placeholderText}
            onFocus={() => this.handleFocus()}
            onBlur={() => this.handleBlur()}
            readOnly={true}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc">{label}</span>
        </label>

        {invalid ? (
          <div className="form-control-message-holder-bc">
            <span className="form-control-message-bc">{error}</span>
          </div>
        ) : null}
      </div>
    );
  }
}

export default CalendarDatePickerInput;
