import React, { useRef } from "react";

import "./Popup.scss";

interface IProps {
  children: React.ReactNode;
  onClose: VoidFunction;
  isHidden?: boolean;
  hideCloseButton?: boolean;
  type?: PopupType;
  fullScreen?: boolean;
  className?: string;
}

const BasePopup: React.FunctionComponent<IProps> = ({
  type = "info",
  children,
  onClose,
  isHidden,
  hideCloseButton = false,
  fullScreen = false,
  className = ""
}) => {
  const popupOverlayRef = useRef(null);

  const hidePopup = (event: React.MouseEvent) => {
    if (event.target === popupOverlayRef.current) {
      onClose();
    }
  };

  return (
    <div className={`popup-holder-bc ${isHidden ? "hidden" : ""} ${type} ${className}`}>
      <div className="popup-middleware-holder-bc" onClick={hidePopup}>
        <div className="popup-middleware-bc" ref={popupOverlayRef}>
          <div className={`popup-inner-bc ${fullScreen ? "full-screen" : null}`}>
            {!hideCloseButton && <i onClick={onClose} className="e-p-close-icon-bc bc-i-close-remove" />}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasePopup;
