/** Creates a new Websocket connection */

class WS {
  private static errorCodes: Dictionary<string> = {
    1000: "Normal closure; the connection successfully completed whatever purpose for which it was created.",
    1001: "The endpoint is going away, either because of a server failure or because the browser is navigating away from the page that opened the connection.",
    1002: "The endpoint is terminating the connection due to a protocol error.",
    1003: "The connection is being terminated because the endpoint received data of a type it cannot accept (for example, a text-only endpoint received binary data).",
    1004: "A meaning might be defined in the future.",
    1005: "No status code was provided even though one was expected.",
    1006: "The connection was closed abnormally (that is, with no close frame being sent) when a status code is expected.",
    1007: "The endpoint is terminating the connection because a message was received that contained inconsistent data (e.g., non-UTF-8 data within a text message).",
    1008: "The endpoint is terminating the connection because it received a message that violates its policy. This is a generic status code, used when codes 1003 and 1009 are not suitable.",
    1009: "The endpoint is terminating the connection because a data frame was received that is too large.",
    1010: "The client is terminating the connection because it expected the server to negotiate one or more extension, but the server didn't.",
    1011: "The server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.",
    1012: "The server is terminating the connection because it is restarting.",
    1013: "The server is terminating the connection due to a temporary condition, e.g. it is overloaded and is casting off some of its clients.",
    1014: "The server was acting as a gateway or proxy and received an invalid response from the upstream server.",
    1015: "The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).",
    4000: "Closed by the application."
  };

  private instance: WebSocket | undefined;
  private onopenQueue: any[] = [];
  private callbackIdCounter: number = 0;

  private readonly onOpenCallback: CallbackFunction;
  private readonly onMessageCallback: CallbackFunction;
  private readonly onCloseCallback: CallbackFunction;
  private readonly onErrorCallback: CallbackFunction;

  /**
   * Create a websocket instance
   * @param url - Websocket url
   * @param onmessage - Callback to be executed when receiving messages
   * @param onopen - Callback to be executed when the socket connection is established
   * @param onclose - Callback to be executed when the socket connection is closed
   * @param onerror - Callback to be executed when an error occurs
   */
  constructor(
    url: string,
    onmessage: CallbackFunction,
    onopen: CallbackFunction = () => {},
    onclose: CallbackFunction = () => {},
    onerror: CallbackFunction = () => {}
  ) {
    if (!url || !onmessage) throw Error("No url or onmessage callback provided");

    this.onOpenCallback = onopen;
    this.onCloseCallback = onclose;
    this.onErrorCallback = onerror;
    this.onMessageCallback = onmessage;

    this.create(url);
  }

  private create(url: string) {
    this.instance = new WebSocket(url);
    this.instance.addEventListener("open", () => this.openEventHandler());
    this.instance.addEventListener("close", event => this.closeEventHandler(event));
    this.instance.addEventListener("message", event => this.messageEventHandler(event));
    this.instance.addEventListener("error", event => this.errorEventHandler(event));
  }

  private openEventHandler() {
    this.onOpenCallback();

    while (this.onopenQueue.length > 0) {
      this.onopenQueue.shift()();
    }
  }

  private messageEventHandler(event: MessageEvent) {
    this.onMessageCallback(event.data);
  }

  private closeEventHandler(event: CloseEvent) {
    const code = event.code;
    let reason = event.reason;

    if (!reason) {
      reason = WS.errorCodes[code] || "Unknown";
    }
    console.warn(`Websocket connection closed.\nCode: ${code}\nReason: ${reason}`);
    this.onCloseCallback(code);

    if (code !== 4000) {
    }
  }

  private errorEventHandler(event: Event) {
    this.onErrorCallback(event);
  }

  /**
   * Send 'stringified' data to the server
   * @param {*} data
   */
  send(data: any): void {
    if (this.instance) {
      switch (this.instance.readyState) {
        case WebSocket.CONNECTING:
          this.onopenQueue.push(() => this.send(data));
          break;
        case WebSocket.OPEN:
          this.instance.send(JSON.stringify(data));
          break;
        case WebSocket.CLOSING:
        case WebSocket.CLOSED:
          throw Error("Web socket connection not available");
        default:
      }
    }
  }

  /**
   * Closes web socket connection
   */
  close(code = 4000, reason: string): void {
    if (this.instance) {
      switch (this.instance.readyState) {
        case WebSocket.CONNECTING:
          this.onopenQueue.push(() => this.close(code, reason));
          break;
        case WebSocket.OPEN:
          this.instance.close(code, reason);
          break;
        case WebSocket.CLOSING:
        case WebSocket.CLOSED:
          console.warn("Socket closing or already closed");
          break;
        default:
      }
    }
  }

  /**
   * Generates a unique callback id
   * @returns {string} Unique callback id
   */
  generateRid() {
    this.callbackIdCounter += 1;
    if (this.callbackIdCounter > 100000) {
      this.callbackIdCounter = 0;
    }
    return `${Date.now()}${this.callbackIdCounter}`;
  }
}

export default WS;
