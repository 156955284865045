/**
 * Provider that provides two Context:
 * PreferencesStateContext - to store preferences data
 * PreferencesDispatchContext - provide a method: setPreference which is used to modify and store the preference store
 */
import React, { useCallback, useEffect, useState } from "react";
import noop from "lodash/noop";

import Config from "configs";
import Storage from "services/storage";
import { OddsFormat } from "providers/OddsConverterProvider";

interface IPreferencesState {
  language: string;
  languageRtl: boolean;
  timeZone: string;
  timeFormat: string;
  sound: number;
  oddFormat: OddsFormat;
  lang: string;
  theme: string;
  productType: string;
  hideBalance: boolean;
}

const getInitialState = (): IPreferencesState => {
  const { preferences: configPreferences }: { preferences: IPreferencesState } = Config.app;
  const storagePreferences: Dictionary<string> = {};
  for (const key in configPreferences) {
    const storageValue = Storage.getItem(key);
    if (configPreferences.hasOwnProperty(key) && storageValue) {
      storagePreferences[key] = storageValue;
    }
  }

  return { ...configPreferences, ...storagePreferences };
};
export const PreferencesStateContext = React.createContext<IPreferencesState>({} as IPreferencesState);
export const PreferencesDispatchContext = React.createContext<IPreferencesContextDispatch>(noop);

interface IPropTypes {
  language: string;
}

const PreferencesProvider: React.FC<IPropTypes> = ({ language, children }) => {
  const [state, setState] = useState(() => ({ ...getInitialState(), language }));

  const setPreference: IPreferencesContextDispatch = useCallback((key, value, unCacheable) => {
    setState(state => ({ ...state, [key]: value }));

    if (!unCacheable) {
      Storage.setItem(key, value);
    }
  }, []);

  useEffect(() => {
    const { rtl } = Config.app.availableLanguages[state.language];
    document.documentElement.dir = rtl ? "rtl" : "ltr";

    setState(state => ({ ...state, languageRtl: rtl }));
  }, [state.language]);

  return (
    <PreferencesStateContext.Provider value={state}>
      <PreferencesDispatchContext.Provider value={setPreference}>{children}</PreferencesDispatchContext.Provider>
    </PreferencesStateContext.Provider>
  );
};

export default PreferencesProvider;
