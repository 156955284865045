import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import produce from "immer";
import find from "lodash/find";

import "./Help.scss";

import EventType from "constants/eventType";
import SmartLink from "helpers/SmartLink";
import { getQueryParams } from "helpers/utils";
import { useFetch } from "hooks/useFetch";
import { PreferencesStateContext } from "providers/PreferencesProvider";
import Loader from "../components/loader/Loader";

interface IPropTypes extends RouteComponentProps {
  section: string;
  content: string;
}

const Help = ({ location, history, section, content }: IPropTypes) => {
  const { language } = useContext(PreferencesStateContext);
  const { data, loading } = useFetch<IHelpPage[]>(`/footerHelpWithContentTemp.json?lang=${language}`);
  const [navigation, setNavigation] = useState<IHelpPage[]>([]);
  const [contentHTML, setContentHTML] = useState<string>("");

  useEffect(() => {
    if (data !== null) {
      const params = getQueryParams();

      const normalizedNavigation = produce(data, draft => {
        draft.forEach(section => {
          section.children.forEach(content => {
            if (!content.link) {
              if (params.page) {
                content.actions = [
                  {
                    type: EventType.OPEN_HELP_PAGE,
                    data: {
                      page: "help",
                      section: section.id,
                      content: content.id
                    }
                  }
                ];
              } else {
                content.target = "_self";
                content.link = `/help/${section.id}/${content.id}`;
              }
            }
          });
        });
      });

      setNavigation(normalizedNavigation);
    }
  }, [data]);

  useEffect(() => {
    try {
      const params = getQueryParams();
      const sectionId = params.section || section;
      const contentId = params.content || content;
      const sectionData = sectionId ? find(navigation, { id: sectionId }) : navigation[0];

      if (sectionData) {
        const contentData = contentId
          ? find(sectionData.children, { id: contentId })
          : find(sectionData.children, child => {
              return child.target === "_self";
            });

        if (contentData) {
          setContentHTML(contentData.content || "");
        }
      }
    } catch (e) {
      setContentHTML("");
    }
  }, [navigation, location, content, section]);

  useEffect(() => {
    return () => {
      history.push({
        search: ``
      });
    };
  }, [history]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="help-popup">
      <ul className="navigation">
        {navigation.map(({ id, title, children }) => {
          return (
            <li key={id}>
              <div className="sp-dy-section-head-bc default">
                <div className="sp-dy-section-head-content-bc">
                  <div className="sp-dy-section-head-ct-item-bc">
                    <span className="sp-dy-section-head-ct-title-bc">Our Products</span>
                  </div>
                </div>
              </div>
              <ul>
                {children.map(({ id, title, target, link, actions }) => {
                  return (
                    <li key={id} className="tr-nav-link-holder-bc">
                      <SmartLink to={link} target={target} actions={actions} className="tr-nav-link-bc">
                        {title}
                      </SmartLink>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
      <div className="content" dangerouslySetInnerHTML={{ __html: contentHTML }} />
    </div>
  );
};

export default withRouter(Help);
