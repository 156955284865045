import { OddsFormat } from "providers/OddsConverterProvider";
import { SessionSource } from "connection/Swarm";

export const DEFAULT_UNKNOWN_VALUE = -1000000;
export const DEFAULT_EACH_WAY_PRICE = 1;
export const SP_PRICE = -1;
export const MAX_STAKE_LENGTH = 11;

export enum BetType {
  Single = 1,
  Multiple,
  System,
  Chain,
  Trixie,
  Yankee,
  SuperYankee = 8,
  Heinz,
  SuperHeinz,
  Goliath,
  Patent,
  Lucky15 = 14,
  Lucky31,
  Lucky63
}

export const eventCountBetTypesMap: Dictionary<BetType[]> = {
  0: [],
  1: [BetType.Single],
  2: [BetType.Single, BetType.Multiple, BetType.Chain],
  3: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain, BetType.Trixie, BetType.Patent],
  4: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain, BetType.Yankee, BetType.Lucky15],
  5: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain, BetType.SuperYankee, BetType.Lucky31],
  6: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain, BetType.Heinz, BetType.Lucky63],
  7: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain, BetType.SuperHeinz],
  8: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain, BetType.Goliath],
  9: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain],
  10: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain],
  11: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain],
  12: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain],
  13: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain],
  14: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain],
  15: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain],
  16: [BetType.Single, BetType.Multiple, BetType.System, BetType.Chain],
  default: [BetType.Single, BetType.Multiple, BetType.Chain]
};

export const fullCoverBetTypes = [
  BetType.Trixie,
  BetType.Yankee,
  BetType.SuperYankee,
  BetType.Heinz,
  BetType.SuperHeinz,
  BetType.Goliath,
  BetType.Patent,
  BetType.Lucky15,
  BetType.Lucky31,
  BetType.Lucky63
];

export const fullCoverBetTypesStakeMultipliers = {
  [BetType.Trixie]: 4,
  [BetType.Patent]: 7,
  [BetType.Yankee]: 11,
  [BetType.Lucky15]: 15,
  [BetType.SuperYankee]: 26,
  [BetType.Lucky31]: 31,
  [BetType.Heinz]: 57,
  [BetType.Lucky63]: 63,
  [BetType.SuperHeinz]: 120,
  [BetType.Goliath]: 247
};

export const fullCoverTypesWithSingles = [BetType.Patent, BetType.Lucky15, BetType.Lucky31, BetType.Lucky63];

export enum PriceChangeMode {
  SuperBet = -1,
  AlwaysAsk,
  AcceptHigherOdds,
  AcceptAnyOdds,
  CounterOffer
}

export enum PriceType {
  Fixed = "fp",
  Starting = "sp"
}

export enum BetslipContentType {
  Betslip,
  OpenBets,
  Suggested,
  Booking
}

export enum BetslipViewState {
  NONE = "NONE",
  QUICK = "QUICK",
  FULL = "FULL"
}

export interface IBetslipContentTab {
  type: BetslipContentType;
  title: string;
  loginRequired?: boolean;
}

export interface IBetslipEvent {
  id: number;
  gameId: number;
  type?: string;
  priceType: PriceType;
  price: number;
  base: number;
  singleOnly: boolean;
  team1Name: string;
  team2Name?: string;
  startTime: number;
  marketName: string;
  marketType: string;
  marketId: number;
  eventName: string;
  initialPrice: number;
  initialBase: number;
  expressId: number;
  isBlocked: boolean;
  isDeleted: boolean;
  isLive: number;
  sportAlias: string;
  regionAlias: string;
  competitionId: number;
  hasConflicts: boolean;
  eachWayAllowed: boolean;
  eachWayPrice: number;
  isPartial: boolean;
}

export type BetslipEventPartial = Pick<
  IBetslipEvent,
  | "id"
  | "gameId"
  | "type"
  | "price"
  | "priceType"
  | "base"
  | "team1Name"
  | "team2Name"
  | "marketType"
  | "marketId"
  | "eventName"
  | "expressId"
> & { isPartial: true };

export interface IFreebetOption {
  id: number;
  amount: number;
}

export interface ISelectedFreebetOption extends IFreebetOption {
  betType: BetType;
  eachWay: boolean;
  sysCount?: number;
}

export interface IBetslipFreebetState {
  options: Dictionary<IFreebetOption[]>;
  selectedOption: ISelectedFreebetOption | null;
}

export interface IBetslipStateMetadata {
  availableBetTypes: BetTypeConfig[];
  fullCoverBetTypesEnabled: boolean;
  eachWayAllowedOnAllEvents: boolean;
  freebet: IBetslipFreebetState;
  selectedSysOption: number;
  excludedSysEvents: Set<number>;
  contentType: BetslipContentType;
}

export interface IBetslipState {
  events: IBetslipEvent[];
  priceChangeMode: PriceChangeMode;
  selectedBetTypes: BetType[];
  metadata: IBetslipStateMetadata;
  eventsDataMap: BetslipEventsDataMap;
}

export type BetslipEventData = {
  stake: string;
  stakeMultiplier: number;
  counterOffer: string;
  eachWay: boolean;
};

export type BetTypeConfig = { value: BetType; name: string; disabled?: boolean; sysCount?: number };

export type BetslipEventsDataMap = Map<BetType, BetslipEventData[]>;

export type BetslipPossibleWinDataMap = Map<BetType, BetslipPossibleWinData[]>;

export type BetslipPossibleWinData = {
  possibleWin: number;
  finalPayout?: number;
  totalStake?: number;
  expressBonus?: number;
  taxOnProfitOrReturn?: number;
  taxOnStake?: number;
  totalOdds?: number;
};

export type FreebetPossibleWin = Pick<BetslipPossibleWinData, "possibleWin" | "taxOnProfitOrReturn">;

export type BetslipInputData = {
  index: number;
  inputType: keyof BetslipEventData;
  value: string;
};

export type BetslipEventsRelevantData = Dictionary<{ price: number; priceType: PriceType; isLive: number }>;

// Open bets
export interface IOpenBetEvent {
  outcome: number;
  startDate: number;
  eventId: number;
  sportId: number;
  gameId: number;
  team1Name: string;
  team2Name: string;
  marketName: string;
  eventName: string;
  isLive: boolean;
  coefficient: number;
  textInfo: string;
}

export interface IOpenBet {
  id: number;
  type: BetType;
  dateTime: number;
  cashOut: number | null;
  remainingStake: number;
  possibleWin: number;
  autoCashoutAmount: number | null;
  events: IOpenBetEvent[];
}

// Betslip messaging
export enum BetslipMessageType {
  ERROR,
  WARNING,
  SUCCESS
}

export enum BetslipGlobalMessageType {
  LOGGED_OUT = "loggedOut",
  INSUFFICIENT_BALANCE = "insufficientBalance",
  INSUFFICIENT_STAKE = "insufficientStake",
  EVENTS_CONFLICT = "eventsConflict",
  LOCKED_EVENT = "lockedEvent",
  DELETED_EVENT = "deletedEvent",
  BET_RESULT = "betResult"
}

export interface IBetslipMessage {
  type: BetslipMessageType;
  title: string;
  createdDate: number;
  lifetime: number;
  persistent: boolean;
  blocking: boolean;
}

export interface IBetslipMessagingState {
  events: Dictionary<IBetslipMessage>;
  global: Dictionary<IBetslipMessage>;
}

// Place bet
export type DoBetRequest = {
  Source: SessionSource;
  Type: BetType;
  Amount: number;
  AcceptTypeId: PriceChangeMode;
  EachWay: boolean;
  OddType: OddsFormat;
  Events: { SelectionId: number; Coeficient: number }[];
  SystemMinCount?: number;
  IsSuperBet?: boolean;
  ClientBonusId?: number;
  Tags?: string;
  AdditionalAmount?: number;
  OldBetId?: number;
  K?: number;
};

export type DoBetResponseMessage = {
  eventId?: number;
  type: BetslipMessageType;
  title: string;
};

export type DoBetSuccessResponse = {
  StatusCode: "0";
  Data: {
    bet_id: number;
    k: number;
    amount: number;
    type: number;
    is_superbet: boolean;
    FreeBetAmount: number;
    bonus_amount: number;
    IsLive: boolean;
    events: {
      selection_id: number;
      game_id: number;
      k: number;
      competition: string;
      home_team: string;
      away_team: string;
      start_date: number;
      market_name: string;
      event_name: string;
      IsLive: boolean;
    }[];
  }[];
};

export type DoBetErrorResponse = {
  StatusCode: string;
  Data:
    | string
    | {
        Key: string;
        Message: string;
        ErrorData: {
          SelectionId: string;
          OldPrice?: string;
          ActualPrice?: string;
        };
      };
  HasError: true;
};

export type DoBetResponse = DoBetSuccessResponse | DoBetErrorResponse;

// Cash out
export type CashOutSuccess = {
  result: "Ok";
  details: {
    price: number;
  };
};

export type CashOutError = {
  result: "Fail" | "NotAvailable";
  details: {
    new_price?: number;
    partial_price?: number;
  } | null;
};

export type CashOutResponse = CashOutSuccess | CashOutError;

// Freebet
export type FreebetRequest = Pick<DoBetRequest, "Events"> & { IsLive: boolean };

export type FreebetResponse = {
  StatusCode: "0";
  Data: { Id: number; Amount: number; AvailableBetTypes: number[] }[];
};
