import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";

import componentRouteMap from "constants/componentRouteMap";
import PublicRoute from "helpers/PublicRoute";
import PrivateRoute from "helpers/PrivateRoute";
import Help from "dev/pages/Help";
import NotFound from "./NotFound";
import lazyLoadComponent from "helpers/lazyLoadComponent";
import { NavigationContext } from "providers/NavigationProvider";

const Routes = () => {
  const {
    headerNavigation: { routes: headerRoutes },
    profileNavigation: { routes: profileRoutes }
  } = useContext(NavigationContext);

  return (
    <React.Fragment>
      <Switch>
        <Route path={"/help/:section?/:content?"} render={({ match }) => <Help {...match.params} />} />

        {[...headerRoutes, ...profileRoutes].map(data => {
          const { path, actions, forbiddenRoute } = data;

          let Component = null;
          if (componentRouteMap[path]) {
            const pageName = componentRouteMap[path].componentName;
            Component = lazyLoadComponent(`pages/${pageName}`);
          }

          return !forbiddenRoute ? (
            Component ? (
              <PublicRoute key={path} exact={path === "/"} component={Component} path={path} actions={actions} />
            ) : null
          ) : (
            <PrivateRoute key={path} exact={path === "/"} path={path} />
          );
        })}

        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default React.memo(Routes);
