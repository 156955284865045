import React, { memo, useContext, useMemo } from "react";
import { TranslationContext } from "providers/TranslationProvider";
import CountryCodes from "constants/countryCodes";

const InputTypeCountry: React.FunctionComponent<IFormControl> = ({
  error,
  touched,
  focused,
  label = "",
  customAttrs,
  className,
  onChange,
  placeholder,
  value,
  ...props
}) => {
  const inputValue = value as string;
  const invalid = touched && error;
  const classNames: string[] = [];

  const trans = useContext(TranslationContext);

  if (focused) {
    classNames.push("focused");
  }
  if (touched) {
    classNames.push(invalid ? "invalid" : "valid");
  }
  if (inputValue.length > 0) {
    classNames.push("filled");
  }

  const options = useMemo(() => {
    const options = [];
    for (let value in CountryCodes) {
      const { name } = (CountryCodes as ICountryCodes)[value];
      options.push({ value, label: name });
    }
    return options;
  }, []);

  const selectedOptionLabel = useMemo<string>(() => {
    const foundOption = options.find(option => option.value === inputValue);
    if (foundOption) {
      return foundOption.label;
    }
    return "";
  }, [inputValue, options]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.type, event.target.name, event.target.value);
  };

  return (
    <div className={`form-control-bc select has-icon ${classNames.join(" ")}`}>
      {/*has-icon, focused, filled, valid, invalid*/}
      <label className="form-control-label-bc inputs">
        {!props.readOnly ? (
          <>
            <select
              className="form-control-select-bc"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValue}
              {...props}
            >
              <option value="" />
              {options.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <span className="form-control-icon-holder-bc">
              <i className="form-control-icon-bc bc-i-arrow-drop-down-bottom" />
            </span>
          </>
        ) : (
          <input type="text" className="form-control-input-bc" defaultValue={selectedOptionLabel} {...props} />
        )}
        <i className="form-control-input-stroke-bc" />
        <span className="form-control-title-bc">{trans(label)}</span>
      </label>
      {invalid ? (
        <div className="form-control-message-holder-bc">
          <span className="form-control-message-bc">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default memo(InputTypeCountry);
