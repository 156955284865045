import React, { memo, Suspense, useEffect } from "react";

import lazyLoadComponent from "helpers/lazyLoadComponent";
import componentRouteMap from "../constants/componentRouteMap";
import componentPaths from "../constants/componentPaths";
import { getQueryParams } from "./utils";
import Loader from "dev/components/loader/Loader";
import NotFound from "../NotFound";
import { RouteComponentProps } from "react-router";

interface IPropTypes extends RouteComponentProps {
  navigationEnabled: boolean;
}

const Widget = ({ navigationEnabled = false, location, history }: IPropTypes) => {
  useEffect(() => {
    history.block((location, action) => {
      console.log(location, action);
      return false;
    });
  }, [history]);

  useEffect(() => {
    if (!navigationEnabled) {
      document.addEventListener("click", preventPageNavigation, true);
      return () => document.removeEventListener("click", preventPageNavigation);
    }
  }, [navigationEnabled]);

  const preventPageNavigation = (event: MouseEvent) => {
    //TODO: when target is necessary fix issue and GO ON!!!!
    /*let target: EventTarget = getClosestElement(event.target, "a");
    console.log(target);*/
    event.preventDefault();
  };

  try {
    const componentName = componentRouteMap[location.pathname].componentName;
    const Component = lazyLoadComponent(componentPaths[componentName].content);

    const { props: queryProps = "{}" } = getQueryParams(location.search);
    const decodedQueryProps = decodeURIComponent(queryProps);
    const props = JSON.parse(decodedQueryProps);

    return <Suspense fallback={<Loader size="small" />}>{Component ? <Component {...props} /> : null}</Suspense>;
  } catch (e) {
    return <NotFound />;
  }
};

export default memo(Widget);
